import React from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import Sidebar from '../../../../components/src/Sidebar.web';
import { validationerror } from '../../../cfcustomersalesreports3/src/assets';
import { EditUnitOfMeasureController , Props } from './EditUnitOfMeasureController.web';
import { ToastContainer } from 'react-toastify';
const configJSON1 = require('./config');
const configJSONUtils = require('../config');

export default class EditUnitOfMeasureView extends EditUnitOfMeasureController{
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes }: any = this.props;
        
        return (
            <Box className={classes.adduomMainLayout}>
      <Box className={classes.adduomsideBar}>
        <Sidebar />
      </Box>
      <Box className={classes.adduomcontent}>
                <ToastContainer/>
        <AppHeader pageTitle="Configuration" />
        <Box className={classes.parentBox}>
          <Box className={classes.adduomBlock}>
            <Grid container spacing={0}>
              <Grid item sm={12} className="left-center-align">
                <Typography className={classes.adduomHeading}>{configJSON1.addUomHeading}</Typography>
              </Grid>
            </Grid>
          </Box>

          {this.state.loading && (
            <Box className="loaderBox">
              <CircularProgress />
            </Box>
          )}

          <Box style={{ padding: '15px 0' }} className={this.state.loading ? 'display-none' : ''}>
            <Grid container spacing={0}>
              <Grid item sm={12}>
                <Grid item sm={6}>
                  <Box sx={{ padding: '0 15px' }}>
                    {!this.state.isEditing ? (
                      <Typography>
                        {configJSON1.uomCategory}
                        <span className={'required'}>*</span>
                        <span className={classes.separator}> | </span> {this.state.category}
                      </Typography>
                    ) : (
                      <>
                        <Typography className={`${!this.state.category ? 'error-text-pos' : ''}`}>
                          {configJSON1.uomCategory}
                          <span className={`${!this.state.category ? 'error-text-pos' : 'required'}`}>*</span>
                        </Typography>
                        <Box className="relative">
                          <TextField
                            data-test-id="uomCategoryInput"
                            placeholder="Enter Unit of measure"
                            className={`form-input width-100 ${!this.state.category ? 'error-focus' : ''}`}
                            value={this.state.category}
                            error={!this.state.category}
                            onChange={(e) => {this.setState({category:e.target.value})}}
                            name="category"
                            type="text"
                            disabled={!this.state.isEditing}
                          />
                          {!this.state.category && (
                            <Box className="flex relative">
                              <img className="error-text-img" src={validationerror} />
                              <Typography className="error-text">{configJSON1.categoryMessage}</Typography>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item sm={6}></Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="end-center-align" style={{ margin: '20px 0' }}>
          {!this.state.isEditing ? (
             <Link to="/AddUnitOfMeasure">
            <Button className={classes.createBtn} variant="outlined">
              {configJSONUtils.create}
            </Button>
            </Link>
          ) : (
            <Link to="/UnitOfMeasurelistview">
            <Button className={classes.discardBtn} variant="outlined">
              {configJSONUtils.discard}
            </Button>
            </Link>
          )}
          {!this.state.isEditing ? (
            <Button
              onClick={()=>{this.setState({isEditing:true})}}
              variant="contained"
              style={{ backgroundColor: '#026494', color: '#ffffff' }}
            >
              {configJSON1.edit}
            </Button>
          ) : (
            <Button
              onClick={this.saveUomCategories}
              variant="contained"
              style={{ backgroundColor: '#026494', color: '#ffffff' }}
            >
              {configJSONUtils.save}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
}

export const userlistwebStyle: any = (theme: any) => ({
  adduomMainLayout: {
    display: 'flex',
  },
  adduomsideBar: {
    display: 'flex',
    minHeight: '100%',
    flexGrow: 1,
    transition: 'all 0.3s',
  },
  adduomcontent: {
    flexGrow: 9,
    padding: '0 20px',
  },
  adduomBlock: {
    padding: '15px',
    borderBottom: '1px solid #e3e3e3',
  },
  parentBox: {
    border: '1px solid #e3e3e3',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  adduomHeading: {
    color: '#006395',
    fontSize: '30px',
    fontWeight: 'bold',
  },
  pb10: {
    paddingBottom: '10px !important',
  },
  discardBtn: {
    marginRight: '15px',
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
  },
  createBtn: {
    marginRight: '15px',
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
  },
  separator: {
    marginLeft: '10px',
    marginRight: '10px',
    color: '#BDBABA',
  },
});

export const EditUnitOfMeasure = withStyles(userlistwebStyle)(EditUnitOfMeasureView)
