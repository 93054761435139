import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ExpandMore } from '@material-ui/icons';
import StorageProvider from "../../../framework/src/StorageProvider";
import { defaultImg } from '../../cfcustomersalesreports3/src/assets';
import { Box } from '@material-ui/core';
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    category:string;
    parent_category:string;
    image:any;
    token:string|null;
    formError:boolean;
    uomCategory:string;
    measure_units:any[];
    uomDDL:any[];
    categoryId:string|null;
    categoryDetail:any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AddUnitOfMeasureController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    addCategoryApiCallId: any = "";
    addUomCategoryApiCallId!: string;
    apigetUomDDLlistCallId!: string;
    apigetCategoryCallId!: string;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
          ];
          
        this.state = {
            // Customizable Area Start
            categoryId:null,
            uomDDL:[],
            category:'',
            parent_category:'',
            image:null,
            token:'',
            formError:false,
            uomCategory:'',
            measure_units:[{uom:"",uom_type:"",ratio:"",rounding_precision:"",active:true,archive:true}],
            categoryDetail:null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start
    getPcToken = async() => {
      const categoryId = window.location.href.split('Categories/')[1]
      const getapiToken =  await StorageProvider.get("TOKEN");
      this.setState({token: getapiToken,categoryId})
    }

    async componentDidMount(){
      this.getPcToken()
    }

    // Customizable Area End
  
      
    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson?.errors) {
          let msg = configJSON.something_went_wrong;
          if (responseJson.errors[0]) {
            msg = responseJson.errors[0];
          }
          if (msg !== "Record not found") {
            this.showAlert(configJSON.errorTitle, msg);
          }
        } else if (apiRequestCallId === this.addCategoryApiCallId && responseJson.uom?.id) {
          this.addCategoryResponse();
        } 
        else if (apiRequestCallId === this.addUomCategoryApiCallId && responseJson.uom?.id) {
          this.discardUomCategory();
        } 
      }
      // Customizable Area End
    }

    // Customizable Area Start
    addCategoryResponse = () => {
      this.discardProductCategory();
    }

    addUomCategoryResponse = (responseJson:any) => {
      this.showAlert(
        configJSON.success,
        responseJson.message
      );
      this.discardUomCategory()
    }

    imageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    expandMore = () => {
        return <ExpandMore/>;
    }

    handleCategoryError = (value:number) => {
      if(value==1 && (!this.state.category.trim() && this.state.formError)){
        return 'error-text-pos'
      }else if(value==2 && (!this.state.category.trim() && this.state.formError)){
       return 'error-focus'
       }else{
         return ''
       }
   }


    hideCategoryError = () => {
      if((!this.state.category.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }

    hideParentCategoryError = () => {
      if((!this.state.parent_category.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }

    discardProductCategory = () => {
      this.setState({...this.state,category:'',parent_category:'',image:null,formError:false})
    }

    checkValidation = () => {
        if(!this.state.category.trim()){
           this.setState({...this.state,formError:true});
           return false;
        }
        return true;
    }

    hideUomCategoryError = () => {
      if((!this.state.uomCategory.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }
  
    handleUomCategoryError = (value:number) => {
        if(value==1 && (!this.state.uomCategory.trim() && this.state.formError)){
          return 'error-text-pos'
        }else if(value==2 && (!this.state.uomCategory.trim() && this.state.formError)){
         return 'error-focus'
         }else{
           return ''
         }
     }
  
    discardUomCategory = () => {
      this.setState({...this.state,uomCategory:'',formError:false})
      this.props.navigation.navigate("UnitOfMeasurelistview");
    }
  
    checkUomValidation = () => {
      if(!this.state.uomCategory.trim()){
        this.setState({...this.state,formError:true});
        return false;
      }else
      return true;
    }
  
    addUomCategories = () => {
      if(!this.checkUomValidation()){
         return false;
      }
      let formdata:any = new FormData();
      formdata.append("uom", this.state.uomCategory);

      const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.addUomCategoryApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addUnitOfMeasureUrl}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}