// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import CfcustomersaleskanbanviewController, { Props } from './CfcustomersaleskanbanviewController.web'

import '../assets/style.css'
import { configJSON } from './Cfcustomersalesreports3Controller';
import { add, backwardArrowDisable, backwardArrowEnable, backwardIconDisable, backwardIconEnable, downarrow, filter, forwardArrowDisable, forwardArrowEnable, forwardIconDisable, forwardIconEnable, groupby, img, list, listBlack, more, search, star, whiteGridicon, whitefiltericon, whitegroupbyicon } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import ClearIcon from "@material-ui/icons/Clear";
import Sidebar from '../../../components/src/Sidebar.web';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
import { ToastContainer } from 'react-toastify'
import { paginationarrow } from '../../settings2/src/assets';
import { canban, settingsGrey } from '../../itemavailability/src/assets';

// Customizable Area End

export const DropdownCmp = () => {
    return <><img style={{ width: "100%" }} src={downarrow} /></>
}

export default class Cfcustomersaleslistview extends CfcustomersaleskanbanviewController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    groupBySalesPerson(classes: { [key: string]: string }) {
        return this.state.groupByState &&
            <>
                <TableContainer className={`${this.loaderBoxCustomer()=='loaderBox' && 'display-none'}`}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.listviewTablecellGroup}><Checkbox
                                    checked={this.state.selectAll}
                                    data-testid="listviewSelectall2"
                                    onClick={() => { this.toggleGroupSelectAll() }} className={classes.listviewCheck} />
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.name}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.phone}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.email}</Typography>
                                </TableCell>
                                <TableCell className={classes.emailTableCell}>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.sales_person}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.city}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.country}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}> {configJSON.company}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.listviewTableheadingGroup}><img data-testid="listviewColumn" onClick={this.handleColumn} className={classes.listviewColumn} src={more} /></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {this.state.showCustomerdata.map((data,index) => {
                            return (
                                <>
                                    <TableBody>
                                        <TableRow className={classes.accordion} onClick={()=>{this.handletoggle(index)}} data-testid="accordionDetails">
                                            <TableCell>
                                                {this.dropdownImg(index,classes)}
                                            </TableCell>
                                            
                                            <TableCell colSpan={9}>
                                          <Typography className='bold-text'>{this.groupName(data)}</Typography>
                                            </TableCell>
                                            
                                        </TableRow>
                                        {this.state.activeIndex.includes(index) && <>
                                            {data?.customer?.data?.map((value: {
                                                
                                                attributes: any; id: number; first_name: {} | null | undefined; phone_number: boolean ; company_name: boolean ;
                                            }) => {
                                                return (

                                                    <TableRow key={value.id}>
                                                        <TableCell className={classes.listviewTablecellGroup}>
                                                        <Checkbox 
                                                            data-test-id="handleGroupCheckboxChange"
                                                            checked={this.state.checkedArr.includes(value.id)}
                                                            onChange={() => this.handleGroupCheckboxChange(value.id)}
                                                            className={classes.listviewCheck}
                                                        />
                                                        
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}> {value.attributes.first_name}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}> {value.attributes.phone_number}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}>{value.attributes.email}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        <Box className="left-center-align">
                                                                            <img src={img} height="30" width="30" className={classes.listviewTableavatar} />
                                                                            {value.attributes.account_sale_purchases?.saler_name}
                                                                        </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}> {value.attributes.city.split('@')[0]}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}>{value.attributes.country}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}> {value.attributes.company_name}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.listviewTabletextGroup}> </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </>
                                        }
                                    </TableBody>
                                </>
                            )
                        })}
                    </Table>
                </TableContainer>
            </>
    }
    handlePagination=(classes: { [key: string]: string })=>{
        return <Grid className={`${classes.listviewPaginationcontainer} ${(this.loaderBoxCustomer()=='loaderBox' || this.state.groupByState || this.state.kanbanView) && 'display-none'}`}>
        <Box className={classes.listviewpaginationBox}>
            <Box className={classes.listviewitemPerPage} >
                <span className={classes.pageText}>{configJSON.itemsPerpage}&nbsp;</span>
                <Select
                    onChange={this.handleChangeper_page}
                    data-testid="selectNumberperPage"
                    value={this.state.per_page}
                    className={classes.listviewselectNumber}
                    IconComponent={() => <img
                        className={classes.paginationImg} src={paginationarrow} />}
                >
                    <MenuItem value={9}>{configJSON.pageOne}</MenuItem>
                    <MenuItem value={27}>{configJSON.pageTwo}</MenuItem>
                    <MenuItem value={54}>{configJSON.pageThree}</MenuItem>
                    <MenuItem value={99}>{configJSON.pageFour}</MenuItem>
                </Select>
            </Box>
            <Box>
                <span className={classes.listviewitemNumber} >
                    {(this.state.page - 1) * this.state.per_page + 1}
                    &nbsp;-&nbsp;
                    {(this.state.page - 1) * this.state.per_page +
                        this.state.showCustomerdata.length
                    }
                </span>
                <span>
                    &nbsp;of &nbsp;
                    {this.state.totalCount.total_counts}
                </span>
                <Button
                    disabled={this.isCurrentPageOne()}
                    className={classes.paginationButton}
                    data-testid="listviewBackwardicon"
                    onClick={() => this.handlePageNumber(0)}
                >
                    <img
                        src={
                            this.isCurrentPageOne()
                                ? backwardIconDisable
                                : backwardIconEnable
                        }
                    />
                </Button>
                <Button
                    disabled={this.isCurrentPageOne()}
                    data-testid="listviewBackArrow"
                    className={classes.paginationButton}
                    onClick={() => this.handlePageNumber(this.state.page - 2)}
                >
                    <img
                        src={
                            this.isCurrentPageOne()
                                ? backwardArrowDisable
                                : backwardArrowEnable
                        }
                    />
                </Button>
                <Button
                    disabled={this.isCurrentPageLast()}
                    data-testid="listviewForwardArrow"
                    className={classes.paginationButton}
                    onClick={() => this.handlePageNumber(this.state.page)}
                >
                    <img
                        src={
                            this.isCurrentPageLast()
                                ? forwardArrowDisable
                                : forwardArrowEnable
                        }
                    />
                </Button>
                <Button
                    disabled={this.isCurrentPageLast()}
                    data-testid="listviewForwardIcon"
                    className={classes.paginationButton}
                    onClick={() => this.handlePageNumber(this.state.totalCount.total_pages - 1)}
                >
                    <img
                        src={
                            this.isCurrentPageLast()
                                ? forwardIconDisable
                                : forwardIconEnable
                        }
                    />
                </Button>
            </Box>

        </Box>
    </Grid>
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showFav, showSettings, showColumn } = this.state;
        const { classes }: any = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.listviewMainLayout}>
                <ToastContainer />
                <Box className={classes.listviewsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.listviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Customer" />
                    <Box className={classes.listviewMain}>
                        <Box>
                            <Box className={classes.listviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item lg={6} md={6} sm={12} xs={12}  className='left-center-align'>
                                        <Typography className={classes.listviewHeading}>{configJSON.mainHeading}</Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}  className={`${classes.listviewSearchbox}`}>
                                        <TextField
                                            className={`${classes.listviewSearchtextfield} ${!this.checkGroupFilter() && 'customer-list-searchinput'}`}
                                            id={this.searchInputId()}
                                            variant="outlined"
                                            placeholder={this.searchInputPlaceholder()}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            InputProps={{
                                                readOnly:this.searchInputReadonly(),
                                                startAdornment: (
                                                    <div className={classes.chipBox} >
                                                        {this.state.selectedProducts?.map((product) => (
                                                            <div key={product} className={classes.chipParent}>
                                                                <Chip avatar={<img className={classes.chipImg} src={whitefiltericon} />}
                                                                    label={this.filterLabel(product)}
                                                                    deleteIcon={<ClearIcon className={classes.icon} />}
                                                                    className={`${classes.chipText} searchChip`}
                                                                    data-testid="delete-filters"
                                                                    onDelete={() => { this.handleProductSelect(product, "no") }}  />
                                                            </div>
                                                        ))}
                                                        {this.state.groupbyProducts?.map((product) => (
                                                            <div key={product} className={classes.chipParent}>
                                                                <Chip avatar={<img className={classes.chipImg} src={whitegroupbyicon} />}
                                                                    label={this.filterLabel(product)}
                                                                    deleteIcon={<ClearIcon className={classes.icon} />}
                                                                    className={`${classes.chipText} searchChip`}
                                                                    onDelete={() => { this.handlegroupByProductSelect(product, "no") }} data-testid="delete-groupby" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ),
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSearchApi(e)}
                                        />
                                        <img src={search} className={`${classes.userlistviewImg} ${this.checkGroupFilter() && 'display-none'}`} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.listviewFilterbox} >
                                <Grid container spacing={0}  >
                                    <Grid item lg={6} md={6} sm={12} xs={12} className='left-center-align'>
                                        <Box className={classes.listviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={() => { this.handleFilter() }}
                                            ><img src={filter} className={classes.listviewFilterImg} />{configJSON.Filters}</Button>
                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                    data-test-id="clickHandleFavorites"
                                                >
                                                <Box className="filterBox">
                                                <List>
                                                    <ListItem button className={this.listItemClass('individual',classes)} data-testid="list-company" onClick={() => { this.filterOnClickFunction('individual') }}>
                                                        <ListItemText primary="Individual" />
                                                    </ListItem>
                                                    <ListItem button className={this.listItemClass('companies',classes)} data-testid="list-individual" onClick={() => { this.filterOnClickFunction('companies') }}>
                                                        <ListItemText primary="Companies" />
                                                    </ListItem>
                                                    <ListItem button className={`${this.listItemClass('archived',classes)}`} data-testid="list-archived" onClick={() => { this.filterOnClickFunction('archived') }}>
                                                        <ListItemText primary="Archived" />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.optionsText} ${classes.borderBottom0}`} data-testid="customerInvoices" >
                                                        <ListItemText primary="Customer Invoices" />
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                        <Box className={classes.listviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Groupby"
                                                onClick={this.handleGroup}
                                            ><img src={groupby} className={classes.listviewFilterImg} />{configJSON.Groupby}</Button>
                                            {
                                                showGroup &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleGroup}
                                                    data-test-id="clickHandleFavorites"
                                                >
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem button className={this.grouplistItemClass('saler_name',classes)} data-testid="list-salesPerson" onClick={() => { this.handlegroupByProductSelect("saler_name", "yes") }}>
                                                            <ListItemText primary="Sales Person" />
                                                        </ListItem>
                                                        <ListItem button className={this.grouplistItemClass('company_name',classes)} data-testid="list-companyName" onClick={() => { this.handlegroupByProductSelect("company_name", "yes") }}>
                                                            <ListItemText primary="Company Name" />
                                                        </ListItem>
                                                        <ListItem button className={`${this.grouplistItemClass('activated',classes)} ${classes.borderBottom0}`} data-testid="list-activeStatus" onClick={() => { this.handlegroupByProductSelect("activated", "yes") }} >
                                                            <ListItemText primary="Active Status" />
                                                        </ListItem>
                                                    </List>
                                                </Box>    
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                        <Box className={classes.listviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleFavorites} ><img src={star} className={classes.listviewFilterImg} />{configJSON.Favorites}</Button>
                                            {
                                                showFav &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFavorites}
                                                    data-test-id="clickHandleFavorites"
                                                >
                                                <Box className="filterBox">
                                                    <List >
                                                        <ListItem button data-testid="list-currentSearch" className='listItem' onClick={this.handleCurrentSearch}>
                                                            <ListItemText primary="Save Current Search" />
                                                            &gt;
                                                        </ListItem>
                                                        <ListItem button className='listItem' data-testid="list-importRecords">
                                                            <ListItemText primary="Import Records" />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary="Add to my Dashboard" data-testid="list-addDashboard" onClick={this.handleAddToDashboard} />
                                                            &gt;
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}  className={classes.listviewCreatebox} >
                                        <Box className={classes.listviewColumnbox}>
                                            {this.state.checkedArr.length>0 && <>
                                                <Button className='filters-left1 filters-not-active' data-testid="list-settings" onClick={this.handleSettings}><img src={settingsGrey} /></Button>
                                            </>}
                                            {
                                                showSettings &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleSettings}
                                                    data-test-id="clickHandleSettings"
                                                >
                                                <Box className="settingsBox settingsBoxCustomer">
                                                    <List>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="Unarchive" />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary="Delete" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                        <Button className={`filters-left1${!this.state.kanbanView ? '-active':' filters-not-active'}`} onClick={()=>!this.state.groupByState && this.navigatetoListview()}><img src={this.state.kanbanView ? listBlack : list} /></Button>
                                        <Button className={`filters-left1${this.state.kanbanView ? '-active' : ' filters-not-active'}`} data-testid="navigatetoGridview" onClick={this.navigatetoGridview}><img className={this.state.kanbanView && classes.whiteGridicon} src={this.state.kanbanView ? whiteGridicon : canban} /></Button>
                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoCreateCustomer}>{configJSON.Createcustomer}<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={classes.listviewColumnbox} >
                                    {
                                        showColumn &&
                                        <Box className="columnBox">
                                            <List className={classes.listviewAddcustomerbox}>
                                                {
                                                    this.state.columns.length > 0 &&
                                                    this.state.columns.map((text) => (
                                                        <ListItem key={text.length + 1} button className='listItem' >
                                                            <ListItemIcon className={classes.listviewIcon} >
                                                                <Checkbox className={classes.listviewCheckbox} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={text} />
                                                        </ListItem>
                                                    ))
                                                }
                                                <ListItem button className={classes.listviewButton}  >
                                                    <Box>
                                                        {configJSON.addField}
                                                    </Box>
                                                </ListItem>
                                            </List>
                                        </Box>
                                    }
                                    <Box className={`${this.loaderBoxCustomer()}`}>
                                    {
                                    this.loaderBoxContentCustomer()
                                    }
                                    </Box>
                                    {this.state.listviewState &&
                                        <>
                                            <TableContainer className={`${this.loaderBoxCustomer()=='loaderBox' && 'display-none'}`}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><Checkbox
                                                                checked={this.state.selectAll}
                                                                data-testid="listviewSelectall"
                                                                onClick={() => { this.toggleSelectAll() }} className={classes.listviewCheck} /></TableCell>
                                                            <TableCell className={classes.listviewTableheading}>{configJSON.name}</TableCell>
                                                            <TableCell className={classes.listviewTableheading}>{configJSON.phone}</TableCell>
                                                            <TableCell className={`${classes.listviewTableheading}`}>{configJSON.email}</TableCell>
                                                            <TableCell className={`${classes.listviewTableheading} ${classes.emailTableCell}`}>{configJSON.sales_person}</TableCell>
                                                            <TableCell className={classes.listviewTableheading}>{configJSON.city}</TableCell>
                                                            <TableCell className={classes.listviewTableheading}>{configJSON.country}</TableCell>
                                                            <TableCell className={classes.listviewTableheading}>{configJSON.company}</TableCell>
                                                            <TableCell className={classes.listviewTableheading}><img data-testid="listviewColumn" onClick={this.handleColumn} className={classes.listviewColumn} src={more} /></TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    {this.state.showCustomerdata?.map((value: {
                                                        checked: boolean | undefined; id: number,
                                                        attributes: {
                                                            [x: string]: any; first_name: string, company_name: string, city: string, email: string, country: string, full_phone_number: string
                                                        }
                                                    }) => {
                                                        return (
                                                            <TableBody key={value.id} >
                                                                <TableRow>
                                                                    <TableCell><Checkbox
                                                                        data-testid="listviewSelectone"
                                                                        className={classes.listviewCheck}
                                                                        checked={this.state.checkedArr.includes(value.id)}
                                                                        onChange={() => this.handleCheckboxChange(value.id)}
                                                                    /></TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.first_name}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.full_phone_number}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.email}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext} >
                                                                        <Box className="left-center-align">
                                                                            <img src={img} height="30" width="30" className={classes.listviewTableavatar} />
                                                                            {value.attributes?.account_sale_purchases?.saler_name}
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.city.split('@')[0]}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.country}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext}>{value.attributes?.company_name}</TableCell>
                                                                    <TableCell className={classes.listviewTabletext}></TableCell>

                                                                </TableRow>
                                                            </TableBody>
                                                        )
                                                    })}
                                                </Table>
                                            </TableContainer>
                                        </>
                                    }
                                    {this.groupBySalesPerson(classes)}
                                </Box>
                               {this.handlePagination(classes)}
                               {
                                this.state.kanbanView && 
                                <>
                                <Box className={`${this.loaderBoxCustomer()=='loaderBox' && 'display-none'} ${classes.gridviewbox}`} >
                                    <Grid container spacing={3} >
                                        {this.state.kanbanView &&
                                            <>
                                                {
                                                    this.state.showCustomerdata.map((value: any
                                                    ) => {
                                                        return this.kanbanData(value,classes)
                                                    })
                                                }
                                            </>}
                                    </Grid>
                                    
                                </Box>
                                <Grid className={classes.gridviewPaginationcontainer1}>
                                    <Box className={`${this.hideLoadMore()}`}>
                                    <Button  data-testid="loadMore" className={classes.gridviewLoadmoreButton1} onClick={this.handleLoadMore}>{configJSON.loadMore}</Button>
                                    </Box>
                                </Grid>
                                </>
                               }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const webStyle: any = (theme: any) => ({
    listviewMainLayout: {
        display: "flex",
    },
    listviewMain: {
        backgroundColor: "white",
        borderRadius:"5px",
        border: "1px solid #e3e3e3"
    },
    optionsText: {
        borderBottom: "1px solid #e3e3e3 !important",
        color: "black",
        fontSize: "30px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    accordionDetailsbox: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        padding: "0%"
    },
    accordionSumarry: {
        backgroundColor: "lightgrey"
    },
    accordion: {
        marginBottom: "1%",
        backgroundColor: "#f3f3f3 !important"

    },
    accordionDetails: {
        display: "block",
        padding: "0%"
    },
    optionsTextactive: {
        borderBottom: "1px solid #e3e3e3 !important",
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    accordiontext: {
        fontSize: "16px",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    activeAccordiontext: {
        fontSize: "16px",
        fontStretch: "normal",
    },
    companyAccordiontext: {
        fontSize: "16px",
    },
    listviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    listviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    listviewBlock: {
        padding: "15px",
    },
    listviewFilterblock: {
        position: "relative"
    },
    listviewHeading: {
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    listviewImg: {
        position: "absolute",
        left: "15px",
        top: "20px",
        height: "20px"
    },
    listviewFilterbox: {
        borderTop: "1px solid #e3e3e3"
    },
    listviewFilterImg: {
        paddingRight: "8px"
    },
    listviewColumnbox: {
        position: "relative"
    },
    listviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    listviewAddcustomerbox: {
        width: "250px"
    },
    listviewButton: {
        justifyContent: "end"
    },
    listviewIcon: {
        minWidth: "35px"
    },
    listviewCheckbox: {
        paddingLeft: 0
    },
    listviewCheck: {
        
    },
    listviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textTransform: "uppercase"
    },
    listviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    listviewTablegroupbytext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        textAlign: "center",
        letterSpacing: "normal",
    },
    listviewTableavatar: {
        borderRadius: "50%",
        paddingRight: "10px"
    },
    listviewItems: {
        padding: "15px"
    },
    listviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
          }
    },
    listviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative",
        width: "100%"
    },
    listviewSearchtextfield: {
        width: "100%"
    },
    listviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    listviewItemsText: {
        paddingRight: "10px",
        fontSize: "20px",
        marginTop: "11px"
    },
    listviewText: {
        marginRight: "50px"
    },
    listviewPaginationcontainer: {
        marginTop: "2%",
        marginBottom: "1%"
    },
    listviewpaginationBox: {
        display: "flex",
        alignItems: "center",
        padding: "15px 5px",
        justifyContent: "space-between",
        marginRight: "52px",
        height: "50px",
    },
    listviewitemPerPage: {
        display: "flex",
        alignItems: "center",
        padding: "3% 3% 3% 2%",
        justifyContent: "space-between",
    },
    listviewitemPerPagetext: {
        fontSize: "16px",
        fontStretch: "normal",
    },
    listviewTabletextGroup: {
        padding: "0% 4%",
        fontSize: "14px",
    },
    listviewselectNumber: {
        borderRadius: "4px",
        border: "1px solid grey",
        width: "100px",
        fontSize: "16px",
        fontStretch: "normal",
        fontWeight: "bold"
    },
    listviewitemNumber: {
        fontSize: "16px",
        fontStretch: "normal",
        fontWeight: "bold"
    },
    paginationImg: {
        padding: "10%",
        height: "10px",
        position: "absolute",
        right: 0,
        pointerEvents: "none"
    },
    chipParent: {
        padding: "8px 0"
    },
    chipBox: {
        display: "flex",
        flexWrap: "wrap"
    },
    chipText: {
        color: "white",
        backgroundColor: "#006395",
        fontSize: "13px",
        fontStretch: "normal",
        padding:"8px",
        marginRight:"5px"
    },
    icon: {
        height: "18px",
        color: "#fff",
        width: "18px",
        "& :hover": {
            color: "#fff",
        },
    },
    chipImg: {
        width: "15px !important",
        height: "17px !important",
        paddingRight: "3px"
        
    },
    accordionImg: {
        width: "8px",
        marginRight: "3%",
        paddingLeft: "17px"
    },
    accordionimg: {
        marginRight: "2%",
        paddingLeft: "15px"
    },
    listviewTablecellGroup: {
        
    },
    listviewTableheadingGroup: {
        padding: "0% 4%",
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    userlistviewImg: {
        position: "absolute",
        left: "15px",
        top: "17px",
        height: "20px"
    },
    borderBottom0: {
        borderBottom: "0 !important"
    },
    pageText: {
        color: "#47464a",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    gridviewbox: {
        padding: "15px 15px 0px 15px",
    },
    gridViewBoxstyle1: {
        padding: "1% !important",
    },
    gridviewFilterbox1: {
        border: "1px solid #e3e3e3",
        borderTopLeftRadius:"5px",
        borderTopRightRadius:"5px",
        height: "72%",
    },
    gridviewlistimg1: {
        padding: "10px",
        paddingLeft:"5px !important"
    },
    gridviewlistimg2: {
        padding: "10px"
    },
    gridListimg1: {
        margin: "-5px -10px 0 0",
        float: "right"
    },
    gridListtext1: {
        fontSize: "14px",
        fontWeight: "600",
        color:"#3b3b3b",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    gridListinputtext1: {
        fontSize: "10px",
        fontWeight: "500",
        color:"#4d4d4d",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
    },
    gridListsubtext1: {
        fontSize: "12px",
        fontWeight: "500",
        color:"#3b3b3b",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    gridListsubtext2: {
        color: "#4d4d4d",
        fontSize: "10px",
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    gridAvatar1: {
        height: "80px",
        width: "80px",
        borderRadius:"3px"
    },
    gridviewMainblock1: {
        padding: "10px 15px",
        border: "1px solid #e3e3e3",
        borderTop:"0 !important",
        borderBottomLeftRadius:"5px",
        borderBottomRightRadius:"5px",
    },
    gridviewContainer1: {
        padding: "5px 0"
    },
    gridViewtext1: {
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid #e3e3e3"
    },
    gridViewtext1img: {
        height:"25px",
        width:"25px"
    },
    gridviewSalestext1: {
        paddingLeft: "5px",
        color:"#666666",
        fontSize:"15px"
    },
    gridviewPaginationcontainer1: {
        marginTop: "2%",
        marginBottom: "2%",
        display:"flex",
        justifyContent:"center"
    },
    gridviewLoadmoreButton1:{
        backgroundColor:"#f3f3f3",
        color:"#3d3d3d",
        margin: "15px 10px",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: "500"  
    },
    whiteGridicon: {
        height: "20px"
    },
    emailTableCell: {
        minWidth:"155px"
    }
})
export const CfcustomersaleslistviewStyles = withStyles(webStyle)(Cfcustomersaleslistview)
  // Customizable Area End
