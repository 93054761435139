import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  TextField,
  Divider,
  Tabs,
  Tab,
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  FormControlLabel,
  Radio,
  Paper,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
const { externalLink ,rightArrow} = require("./assets");
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A6395",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface CheckBoxProps {
  name: string;
  value: boolean;
  testid: string;
  heading: string;
  subheading: string;
  handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SelectFieldProps {
  name: string;
  options: { id: string; value: string; title: string }[];
  value: string;
  label: string;
  isExpandable: boolean;
  placeholderValue: string;
  handleCheckField: ((event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>, child: React.ReactNode) => void)
}


const productTypeList = [
  {
    id: "1",
    title: "Storable product",
    value: "storable_product"
  },
  {
    id: "2",
    title: "Consumable",
    value: "consumable"
  },
  {
    id: "3",
    title: "Service",
    value: "service"
  },
];

const autocompleteList: string[] = [
  "Option 1" ,
  "Option 2",
  "Option 3",
];


// Customizable Area End

import PosCreationController, {
  Props,
  configJSON,
} from "./PosCreationController";

export default class PosCreation extends PosCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  checkBoxField = ({ name, value, testid, heading, subheading, handleCheckBoxChange }: CheckBoxProps) => {
    return (
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Checkbox
          checked={value}
          edge="start"
          tabIndex={-1}
          size="medium"
          data-test-id={testid}
          className={name}
          name={name}
          onChange={handleCheckBoxChange}
        />
        <Box style={{ marginLeft: "10px", paddingLeft: "20px", borderLeft: "1px solid lightgray" }}>
          <Typography style={{ fontWeight: "bold" }}>{heading}</Typography>
          <Typography style={{ fontSize: "12px" }}>{subheading}</Typography>
        </Box>
      </Box>
    );
  }

  selectField = ({ name, options, value, label, isExpandable, placeholderValue, handleCheckField }: SelectFieldProps) => {
    return (
      <div className="form-group form-check" style={webStyle.formGroupContainer}>
        <Typography style={webStyle.pageHeading as React.CSSProperties}>
          {label}
        </Typography>
        <div style={webStyle.errorContainer as React.CSSProperties}>
          <FormControl fullWidth variant="outlined">
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Select
                style={{ width: "90%" }}
                name={name}
                displayEmpty
                disableUnderline
                value={value || ""}
                data-test-id={name}
                onChange={handleCheckField}
                className={`form-check-input ${!value && 'select-default-color'}`}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 55,
                      paddingTop: 0,
                      paddingBottom: 0,
                      width: "fit-content"
                    },
                  },
                }}
              >
                <MenuItem value={""} disabled style={{ display: "none", color: "#E5E5E5" }}>
                  {placeholderValue}
                </MenuItem>
                {options?.length ? options.map((item: { id: string; value: string; title: string;}) => (
                  <MenuItem key={item.id} value={item.value} style={webStyle.menuItem as React.CSSProperties}>{item.title}</MenuItem>
                )) : null}
              </Select>
              {isExpandable && <img src={externalLink} style={{ width: "20px", paddingLeft: "10px" }} />}
            </Box>
          </FormControl>
        </div>
      </div>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.gridMainLayout}>
          <Box style={webStyle.gridsideBar}>
            <Sidebar />
          </Box>
          <Box className="gridcontent" style={webStyle.gridcontent}>
            <AppHeader pageTitle={"Point of Sale"} />

            <Box className="parent-div" >
              <style>
                {`
                  .MuiOutlinedInput-input {
                      padding: 0px 10px !important;
                  }
                  .MuiInput-underline:after {
                    border-bottom: 0px !important;
                  }
                  .MuiSelect-selectMenu{
                    border: 1px solid #e3e3e3;
                  }
                  .MuiInputBase-root{
         
                    border-radius: 5px;
                  }
                  .MuiInputBase-input{
                    border: 0px !important;
                  }
                  .MuiInputAdornment-positionEnd{
                    padding : 10px;
                  }
                  .MuiFormControl-fullWidth{
                    width: 90%;
                  }
                  .MuiChip-root{
                    background-color:#e0e0e0 !important;
                    color:#000 !important;
                  }
                  .MuiChip-deleteIcon{
                    color:#000 !important;
                  }
                  .MuiIconButton-root:hover{
                    background-color: transparent !important;
                  }
                  .productnameCell {
                      & .MuiInputBase-root {
                        outline: none;
                      }
                  }
                  .MuiPaper-elevation1 {
                    box-shadow: none;
                  }
                  .MuiGrid-spacing-xs-3{
                    width: calc(100% + 50px);
                  }
                  .MuiSelect-select.MuiSelect-select{
                    padding-right : 40px;
                  }
                  .MuiTableCell-alignLeft{
                    padding:8px;
                  }
                  .MuiTab-root {
                    min-width: fit-content !important;
                    font-size: 15px !important;
                  }
                  .tablecontainer::-webkit-scrollbar {
                      height:5px;
                      width:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb {
                      background: lightgrey;
                      border-radius:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb:hover {
                      background: grey;
                    }
                  }
                `}
              </style>
              <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Grid item sm={3} lg={12} md={12}>
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} style={{ paddingRight: "25px" }} >
                    <Typography className="customer bold-text" >Point of Sale </Typography>
                  </Box>
                </Grid>
                <Divider style={webStyle.divider} />
                <Box style={{ padding: "0px !important", width: "100%" }}>
                  <Box className="form-div" style={{ width: "95%" }}>
                    <Box>
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ marginLeft: "2%" }}>
                        <Typography className={`${this.errorTextClass('programName', this.state.programName)}`} style={{ marginRight: "40px" }}>Program Name<span style={{ color: "red" }}>*</span></Typography>
                        <Box display={"flex"} flexDirection={"column"} style={{ width: "40%" }}>
                          <TextField
                            data-test-id="programName"
                            name="programName"
                            type="text"
                            className={`${this.errorFocusClass('programName', this.state.programName)}`}
                            style={{ width: "100%", border: "1px solid #e3e3e3", marginRight: "40px" }}
                            value={this.state.programName}
                            onChange={this.handleProgramNameChange}
                            placeholder="Enter Program Name"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                                <Typography style={{ color: "black" }}>EN</Typography>
                              </InputAdornment>,
                            }}
                          />
                          <div>
                            {this.handleErrorMessage('programName') || null}
                          </div>
                        </Box>
                        <Box style={{ marginLeft: "40px", borderLeft: "1px solid lightgrey", paddingLeft: "40px" }}>
                          <Typography>Company</Typography>
                          <Typography>My Company (San Francisco)</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "2.5%" }}>
                        <Checkbox
                          checked={this.state.authorizedEmployee}
                          edge="start"
                          tabIndex={-1}
                          size="medium"
                          name="authorizedEmployee"
                          data-test-id="authorizedEmployee"
                          className="individual"
                          onChange={this.handleAuthorizedEmployeeChange}
                        />
                        <Divider orientation="vertical" flexItem />
                        <Box style={{ marginLeft: "40px" }}>
                          <Typography style={{ fontWeight: "bold" }}>Authorized Employee</Typography>
                          <Typography style={{ fontSize: "12px" }}>Use employee credentials to log in to the</Typography>
                          <Typography style={{ fontSize: "12px" }}>PoS session and switch cashier</Typography>
                        </Box>
                      </Box>
                      <div>
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange} data-test-id="tabs">
                          <Tab label={configJSON.posInterface} />
                          <Tab label={configJSON.connectedDevices} />
                          <Tab label={configJSON.taxes} />
                          <Tab label={configJSON.pricing} />
                          <Tab label={configJSON.payments} />
                          <Tab label={configJSON.billsReceipts} />
                          <Tab label={configJSON.inventory} />
                          <Tab label={configJSON.accounting} />
                          <Tab label={configJSON.sales} />
                        </Tabs>

                        <this.TabPanel value={this.state.tabValue} index={0}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "productConfigurator", value: this.state.posInterface.productConfigurator, testid: "productConfigurator", heading: "Product Configurator", subheading: "Select product attributes", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "customerNotes", value: this.state.posInterface.customerNotes, testid: "customerNotes", heading: "Customer Notes", subheading: "Add notes on order lines to be printed on receipt and invoice", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                              </Grid>

                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5}>
                                  {this.checkBoxField({ name: "floorsAndTables", value: this.state.posInterface.floorsAndTables, testid: "floorsAndTables", heading: "Floors & Tables", subheading: "Design floors and assign orders to tables", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5}>
                                  {this.checkBoxField({ name: "internalNotes", value: this.state.posInterface.internalNotes, testid: "internalNotes", heading: "Internal Notes", subheading: "Add internal notes on order lines", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                              </Grid>

                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "categoryPictures", value: this.state.posInterface.categoryPictures, testid: "categoryPictures", heading: "Category Pictures", subheading: "Display pictures of product categories", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "restrictProductCategories", value: this.state.posInterface.restrictProductCategories, testid: "restrictProductCategories", heading: "Restrict Product Categories", subheading: "Pick which product categories are available", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                              </Grid>

                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5}>
                                  {this.checkBoxField({ name: "startCategory", value: this.state.posInterface.startCategory, testid: "startCategory", heading: "Start Category", subheading: "Start selling from a default product category", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5}>
                                  {this.checkBoxField({ name: "largeScrollbars", value: this.state.posInterface.largeScrollbars, testid: "largeScrollbars", heading: "Large Scrollbars", subheading: "Improve navigation for imprecise industrial touchscreens", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                              </Grid>

                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "openProductInfo", value: this.state.posInterface.openProductInfo, testid: "openProductInfo", heading: "Open Product Info", subheading: "Display the 'Product Info' page when a product with optional products are added in customer cart", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "limitedPartnersLoading", value: this.state.posInterface.limitedPartnersLoading, testid: "limitedPartnersLoading", heading: "Limited Partners Loading", subheading: "Only load a limited number of customers at athe opening of the Pos. ", handleCheckBoxChange: this.handleCheckboxChangePosInterface })}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>

                        <this.TabPanel value={this.state.tabValue} index={1}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "iotBox", value: this.state.connectedDevices.iotBox, testid: "iotBox", heading: "IoT Box", subheading: "Connect devices using an IoT Box", handleCheckBoxChange: this.handleCheckboxChangeConnectedDevices })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "directDevices", value: this.state.connectedDevices.directDevices, testid: "directDevices", heading: "Direct Devices", subheading: "Connect devices to you PoS directly without an IoT Box", handleCheckBoxChange: this.handleCheckboxChangeConnectedDevices })}
                                </Grid>
                              </Grid>

                              <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: "1%" }}>
                                <Grid item sm={5} md={5} lg={5}>
                                  <Box>
                                    {this.checkBoxField({ name: "customerDisplay", value: this.state.connectedDevices.customerDisplay, testid: "customerDisplay", heading: "Customer Display", subheading: "Show customers checkout in a pop-up window. Can be moved to a second screen.", handleCheckBoxChange: this.handleCheckboxChangeConnectedDevices })}
                                  </Box>
                                  <Box style={{ marginTop: "4%" }}>
                                    {this.checkBoxField({ name: "orderPrinter", value: this.state.connectedDevices.orderPrinter, testid: "orderPrinter", heading: "Order Printer", subheading: "Print orders at the kitchen, at the bar, etc.", handleCheckBoxChange: this.handleCheckboxChangeConnectedDevices })}
                                  </Box>
                                </Grid>
                                <Grid item sm={5} md={5} lg={5}>
                                  <Box style={{ marginLeft: "6%", borderLeft: "1px solid lightgrey", paddingLeft: "7%" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Barcodes</Typography>
                                      <Typography style={{ fontSize: "12px" }}>Use barcodes to scan products, customer cards etc.</Typography>
                                    </Box>
                                    <Box style={{ marginTop: "2%" }}>
                                      {this.selectField({
                                        name: "barcodeNomenclature",
                                        options: productTypeList,
                                        value: this.state.connectedDevices.barcodeNomenclature,
                                        label: "Barcode Nomenclature",
                                        isExpandable: true,
                                        placeholderValue: "Default Nomenclature",
                                        handleCheckField: this.handleSelectChange
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>

                        <this.TabPanel value={this.state.tabValue} index={2}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "fiscalPositionPerOrder", value: this.state.taxes.fiscalPositionPerOrder, testid: "fiscalPositionPerOrder", heading: "Fiscal Position Per Order", subheading: "Choose among fiscal positions when processing an order", handleCheckBoxChange: this.handleCheckboxChangeTaxes })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "fiscalPosition", value: this.state.taxes.fiscalPosition, testid: "fiscalPosition", heading: "Fiscal Position", subheading: "Use a default specific tax regime", handleCheckBoxChange: this.handleCheckboxChangeTaxes })}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>

                        <this.TabPanel value={this.state.tabValue} index={3}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  <Box>
                                    <Box style={{ borderLeft: "1px solid lightgray", paddingLeft: "20px" }}>
                                      {this.selectField({
                                        name: "default_pricelist",
                                        options: productTypeList,
                                        value: this.state.pricing.default_pricelist,
                                        label: "Default Pricelist",
                                        isExpandable: true,
                                        placeholderValue: "Benelux (USD)",
                                        handleCheckField: this.handleSelectChangePricing
                                      })}
                                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                        <img src={rightArrow} style={{ width: "25px", paddingLeft: "10px" }} />
                                        <Typography style={{ fontWeight: "bold" }}>Pricelist</Typography>
                                      </Box>
                                    </Box>
                                    <Box style={{ borderLeft: "1px solid lightgray", paddingLeft: "20px" }}>
                                      <Box style={{ marginTop: "4%" }}>
                                        <Typography style={{ fontWeight: "bold" }}>Product Prices</Typography>
                                        <Typography style={{ fontSize: "12px" }}>Product prices for receipts</Typography>
                                      </Box>
                                      <Box display={"flex"} flexDirection={"column"} >
                                        <FormControlLabel
                                          value={this.state.pricing.tax_excluded_price}
                                          data-test-id="taxExcludedPriceLabel"
                                          control={
                                            <Radio
                                              className="radioBtn"
                                              disableRipple
                                              disableFocusRipple
                                              disableTouchRipple
                                              color="secondary"
                                              size="small"
                                              data-test-id="taxExcludedPrice"
                                              checked={this.state.pricing.tax_excluded_price}
                                              onChange={this.handleTaxExcludedPriceChange}
                                            />
                                          }
                                          label={
                                            <Typography style={this.state.pricing.tax_excluded_price ? webStyle.radioChecked as React.CSSProperties : webStyle.radioUnchecked as React.CSSProperties}>
                                              Tax-Excluded Price
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          data-test-id="taxIncludedPriceLabel"
                                          value={this.state.pricing.tax_included_price}
                                          control={
                                            <Radio
                                              className="radioBtn"
                                              disableRipple
                                              disableFocusRipple
                                              disableTouchRipple
                                              color="secondary"
                                              size="small"
                                              data-test-id="taxIncludedPrice"
                                              checked={this.state.pricing.tax_included_price}
                                              onChange={this.handleTaxIncludedPriceChange}
                                            />
                                          }
                                          label={
                                            <Typography style={this.state.pricing.tax_included_price ? webStyle.radioChecked as React.CSSProperties: webStyle.radioUnchecked as React.CSSProperties}>
                                              Tax-Included Price
                                            </Typography>
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Box>

                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "advanced_pricelists", value: this.state.pricing.advanced_pricelists, testid: "advanced_pricelists", heading: "Advanced Pricelists", subheading: "Set shop-specific prices,seasonal discounts, etc.", handleCheckBoxChange: this.handleCheckboxChangePricing })}

                                  <Box style={{ marginTop: "15%" }}>
                                    {this.checkBoxField({ name: "global_discounts", value: this.state.pricing.global_discounts, testid: "global_discounts", heading: "Global Discounts", subheading: "Allow global discounts on orders", handleCheckBoxChange: this.handleCheckboxChangePricing })}
                                    <Box style={{ marginLeft: "41px", paddingLeft: "20px", borderLeft: "1px solid lightgray", paddingTop: "3%" }}>
                                      {this.selectField({
                                        name: "discount_product",
                                        options: productTypeList,
                                        value: this.state.pricing.discount_product,
                                        label: "Discount Product",
                                        isExpandable: false,
                                        placeholderValue: "Benelux (USD)",
                                        handleCheckField: this.handleSelectChangePricing
                                      })}
                                      {this.selectField({
                                        name: "discount_percentage",
                                        options: productTypeList,
                                        value: this.state.pricing.discount_percentage,
                                        label: "Discount Percentage",
                                        isExpandable: false,
                                        placeholderValue: "Benelux (USD)",
                                        handleCheckField: this.handleSelectChangePricing
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "manual_discounts", value: this.state.pricing.manual_discounts, testid: "manual_discounts", heading: "Manual Discounts", subheading: "Allow discounts per line", handleCheckBoxChange: this.handleCheckboxChangePricing })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "gift_card", value: this.state.pricing.gift_card, testid: "gift_card", heading: "Gift Card", subheading: "Allow access to Gifts", handleCheckBoxChange: this.handleCheckboxChangePricing })}
                                </Grid>
                              </Grid>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Loyalty Program</Typography>
                                      <Typography style={{ fontSize: "12px" }}>Use barcodes to scan products, customer cards etc.</Typography>
                                    </Box>
                                    <Box style={{ marginTop: "2%" }}>
                                      {this.selectField({
                                        name: "loyalty_program",
                                        options: productTypeList,
                                        value: this.state.pricing.loyalty_program,
                                        label: "Loyalty Program",
                                        isExpandable: true,
                                        placeholderValue: "Loyalty Program",
                                        handleCheckField: this.handleSelectChangePricing
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                </Grid>
                              </Grid>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "coupons_and_promotions", value: this.state.pricing.coupons_and_promotions, testid: "coupons_and_promotions", heading: "Coupons & Promotions", subheading: "Define the coupon and promotion programs you can use in this PoS.", handleCheckBoxChange: this.handleCheckboxChangePricing })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "price_control", value: this.state.pricing.price_control, testid: "price_control", heading: "Price Control", subheading: "Restrict price modification to managers", handleCheckBoxChange: this.handleCheckboxChangePricing })}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>

                        <this.TabPanel value={this.state.tabValue} index={4}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6} >
                                  <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Payment Methods</Typography>
                                      <Typography style={{ fontSize: "12px" }}>Payment methods available</Typography>
                                    </Box>
                                    <Box>
                                      <Autocomplete
                                        data-test-id={"autocompleteChip"}
                                        style={{ width: "90%" }}
                                        disableClearable
                                        multiple
                                        id="tags-outlined"
                                        options={autocompleteList}
                                        value={this.state.payment.payment_methods}
                                        renderOption={(option) => (
                                          <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option}</Typography>
                                        )}
                                        filterSelectedOptions
                                        getOptionLabel={(option) => option}
                                        PaperComponent={({ children }) => (
                                          <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                                        renderTags={(tagValue, getTagProps) =>
                                          tagValue.map((option, index) => (<Chip
                                            deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                            label={option}
                                            {...getTagProps({ index })} />
                                          ))
                                        }
                                        onChange={this.handleAutocompleteChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            className={
                                              'form-check-input'
                                            }
                                            placeholder={this.state.payment.payment_methods.length > 0 ? "" : "Payment Methods"}
                                            name={"payment_methods"}
                                          />
                                        )}
                                      />
                                    </Box>
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                      <img src={rightArrow} style={{ width: "25px", paddingRight: "10px" }} />
                                      <Typography style={{ fontWeight: "bold" }}>Payment Methods</Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item sm={6} md={6} lg={6} >
                                  {this.checkBoxField({ name: "set_maximum_diffrence", value: this.state.payment.set_maximum_diffrence, testid: "set_maximum_diffrence", heading: "Set Maximum Diffrence", subheading: "Set a maximum difference allowed between the expected and counted money during the closing of the session", handleCheckBoxChange: this.handleCheckboxChangePayments })}
                                </Grid>
                              </Grid>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6} >
                                  {this.checkBoxField({ name: "tips", value: this.state.payment.tips, testid: "tips", heading: "Tips", subheading: "Accept customer tips or convert their change to a tip", handleCheckBoxChange: this.handleCheckboxChangePayments })}
                                </Grid>
                                <Grid item sm={6} md={6} lg={6} >
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>

                        <this.TabPanel value={this.state.tabValue} index={5}>
                          <Box className="p-15">
                            <Grid container spacing={3} justifyContent="space-between">
                              <Grid item sm={5} md={5} lg={5} >
                                {this.checkBoxField({ name: "header_and_footer", value: this.state.bill_and_receipt.header_and_footer, testid: "header_and_footer", heading: "Headers & Footers", subheading: "Add a custom message to header and footer", handleCheckBoxChange: this.handleCheckboxChangeBills })}
                              </Grid>
                              <Grid item sm={5} md={5} lg={5} >
                                {this.checkBoxField({ name: "automatic_receipt_printing", value: this.state.bill_and_receipt.automatic_receipt_printing, testid: "automatic_receipt_printing", heading: "Automatic Receipt Printing", subheading: "Prints receipts automatically once payment registered", handleCheckBoxChange: this.handleCheckboxChangeBills })}
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between">
                              <Grid item sm={5} md={5} lg={5} >
                                {this.checkBoxField({ name: "bill_printing", value: this.state.bill_and_receipt.bill_printing, testid: "bill_printing", heading: "Bill Printing", subheading: "Allow to print before payment", handleCheckBoxChange: this.handleCheckboxChangeBills })}
                              </Grid>
                              <Grid item sm={5} md={5} lg={5} >
                                {this.checkBoxField({ name: "bill_spitting", value: this.state.bill_and_receipt.bill_spitting, testid: "bill_spitting", heading: "Bill Splitting", subheading: "Split payment or order lines", handleCheckBoxChange: this.handleCheckboxChangeBills })}
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between">
                              <Grid item sm={5} md={5} lg={5} >
                                <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                  <Box>
                                    <Typography style={{ fontWeight: "bold" }}>Invoicing</Typography>
                                    <Typography style={{ fontSize: "12px", fontWeight: "lighter" }}>Print invoices on customer request</Typography>
                                  </Box>
                                  <Box style={{ marginTop: "2%" }}>
                                    {this.selectField({
                                      name: "invoice_journal",
                                      options: productTypeList,
                                      value: this.state.bill_and_receipt.invoice_journal,
                                      label: "Invoice Journal",
                                      isExpandable: true,
                                      placeholderValue: "Customer Invoicing",
                                      handleCheckField: this.handleSelectChangeBills
                                    })}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={5} md={5} lg={5} >
                              </Grid>
                            </Grid>
                          </Box>
                        </this.TabPanel>
                        
                        <this.TabPanel value={this.state.tabValue} index={6}>
                          <Box className="p-15">
                          <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6} >
                                  <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Operation Type</Typography>
                                      <Typography style={{ fontSize: "12px" }}>Operation type used to record product pickings Products will be taken from the default source location of this operation type</Typography>
                                    </Box>
                                    <Box>
                                      {this.selectField({
                                        name: "operation_type",
                                        options: productTypeList,
                                        value: this.state.inventory.operation_type,
                                        label: "",
                                        isExpandable: true,
                                        placeholderValue: "San Francisco : Pos Orders",
                                        handleCheckField: this.handleSelectChangeInventory
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "ship_later", value: this.state.inventory.ship_later, testid: "ship_later", heading: "Ship Later", subheading: "Sell products and deliver them later.", handleCheckBoxChange: this.handleCheckboxChangeInventory })}
                                </Grid>
                              </Grid>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  {this.checkBoxField({ name: "limited_products_loading", value: this.state.inventory.limited_products_loading, testid: "limited_products_loading", heading: "Limited Products Loading", subheading: "Only load most common products at the opening of the PoS.", handleCheckBoxChange: this.handleCheckboxChangeInventory })}
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>
                        <this.TabPanel value={this.state.tabValue} index={7}>
                          <Box className="p-15">
                            <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Journal Entries</Typography>
                                      <Typography style={{ fontSize: "12px", fontWeight: "lighter" }}>Configuration for journal entries of PoS orders</Typography>
                                    </Box>
                                    <Box style={{ marginTop: "2%" }}>
                                      {this.selectField({
                                        name: "sales_journal",
                                        options: productTypeList,
                                        value: this.state.accounting.sales_journal,
                                        label: "Sales Journal",
                                        isExpandable: true,
                                        placeholderValue: "Point of Sale",
                                        handleCheckField: this.handleSelectChangeAccounting
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>
                        <this.TabPanel value={this.state.tabValue} index={8}>
                          <Box className="p-15">
                          <Box className="form-div" style={{ width: "95%" }}>
                              <Grid container spacing={3} justifyContent="space-between">
                                <Grid item sm={5} md={5} lg={5} >
                                  <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Sales Team</Typography>
                                      <Typography style={{ fontSize: "12px", fontWeight: "lighter" }}>Sales are reported to the following sales team</Typography>
                                    </Box>
                                    <Box style={{ marginTop: "2%" }}>
                                      {this.selectField({
                                        name: "sales_team",
                                        options: productTypeList,
                                        value: this.state.sale.sales_team,
                                        label: "",
                                        isExpandable: true,
                                        placeholderValue: "Point of Sale",
                                        handleCheckField: this.handleSelectChangeSales
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item sm={5} md={5} lg={5} >
                                <Box style={{ borderLeft: "1px solid lightgrey", paddingLeft: "20px" }}>
                                    <Box>
                                      <Typography style={{ fontWeight: "bold" }}>Invoicing</Typography>
                                      <Typography style={{ fontSize: "12px", fontWeight: "lighter" }}>This product will be applied when down payment is made</Typography>
                                    </Box>
                                    <Box style={{ marginTop: "2%" }}>
                                      {this.selectField({
                                        name: "down_payment_product",
                                        options: productTypeList,
                                        value: this.state.sale.down_payment_product,
                                        label: "",
                                        isExpandable: true,
                                        placeholderValue: "[DAILY] Daily Allowance",
                                        handleCheckField: this.handleSelectChangeSales
                                      })}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </this.TabPanel>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Box sx={webStyle.actionBtnStyle}>
              <Button variant="contained" style={webStyle.discardBtn} data-test-id="discardBtn" onClick={this.handleResetOrder}>Discard</Button>
              <Button
                type="submit"
                data-test-id="saveBtn"
                style={webStyle.saveBtn}
                onClick={this.handleSave}
              >
               {this.getSaveBtnText()}
              </Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    flexDirection: "column",
    height: "100px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  mainWrapper: {
    paddingBottom: "30px",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    background: "#fff",
  },
  gridMainLayout: {
    display: "flex",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
  },
  gridsideBar: {
    transition: "all 0.3s",
    display: "flex",
    minHheight: "100%",
  },
  gridcontent: {
    width: "77%",
    flexGrow: 9,
    padding: "0 20px",
  },
  actionBtnStyle: {
    gap: 8,
    marginBlock: "40px",
    display: "flex",
    float: "right",
  },
  saveBtn: {
    backgroundColor: "rgba(9, 65, 115, 0.77)",
    color: "#ffffff"
  },
  discardBtn: {
    backgroundColor: "white"
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#E3E3E3"
  },
  buttonComponentSettings: {
    height: "100%",
    padding: "8px 8px",
    fontSize: "12px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#e1e1e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  availabilityPopper: {
    border: "1px solid #ccc",
    borderRadius: 4,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    marginTop: "4px",
    backgroundColor: "#ffffff",
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  popperItemBlue: {
    background: "#2A6395",
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "18px",
  },
  productCardBox: {
    display: "flex",
    gap: 40
  },
  activityIconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  graphpopper: {
    margin: "10",
    minWidth: "30px",
    height: "30px",
  },
  boldFont: {
    fontSize: "22px",
    fontWeight: 500,
    marginLeft: "5%",
  },
  errorContainer: {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formGroupContainer: {
    alignItems: "baseline",
    width: "100%",
  },
  pageHeading: {
    textWrap: "nowrap !important",
    fontWeight: "bold"
  },
  menuItem: {
    borderBottom: "1px solid #E3E3E3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  radioUnchecked: {
    color: "#000",
  },
  radioChecked: {
    fontWeight: "bold",
    fontSize: "15px",
  },
};
// Customizable Area End
