//@ts-ignore
import { FilterOptions } from "./types";

export const FILTER_FIELDS: FilterOptions[] = [
  { name: "name", label: "Name",disable:true },
  { name: "responsible", label: "Responsible", disable:false },
  { name: "productCode", label: "Product Code", disable:false },
  { name: "salesPrice", label: "Sales Price", disable:false },
  { name: "cost", label: "Cost", disable:false },
  { name: "category", label: "Category", disable:false },
  { name: "type", label: "Type", disable:false },
  { name: "qtyOnHand", label: "QTY on Hand", disable:false },
  { name: "forecastedQty", label: "Forecasted QTY", disable:false },
  { name: "umo", label: "UOM", disable:false },
];
