import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { backArrowEnable, backarrow, filtericon, forwardArrowDisable, forwardarrow, griddownarrow, gridleftarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, paginationarrow } from '../../settings2/src/assets';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Checkbox, CircularProgress, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { configJSON1 } from './BanksListView.web';
import { groupWhite } from '../../advancedsearch/src/assets';
export const configJSON = require("../../settings2/src/config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  defaultView:boolean;
  loadMoreRecords:boolean;
  page: number;
  per_page: number;
  token:string|null;
  showBankdata:any[];
  loading: boolean;
  searchKeyword: string;
  totalCounts:number;
  totalPage:number;
  groupbyResponse:any[];
  activeGroup: string; 
  activeFilter: number;
  activeFilterArr: any[];
  globalCheck:boolean;
  checkedArr:any[];
  showFav: boolean;
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  searchArr: any[];
  active:any[];
  showFilter: boolean;
  showGroup: boolean;
  
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BanksListViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetBanklistCallId!: string;
  apigetBankGroupbylistCallId!: string;
  apigetBankFilterlistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      defaultView:true,
      loadMoreRecords:false,
      page:1,
      per_page:9,
      token:"",
      globalCheck:false,
      checkedArr:[],
      loading:false,
      searchKeyword: '',
      totalCounts: 0,
      totalPage:0,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettings: false,
      searchArr:[],
      active:[],
      activeGroup: '',
      activeFilter: -1,
      activeFilterArr: [],
      showFilter: false,
      showGroup: false,
      showBankdata: [],
      groupbyResponse:[],
      
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, mesg: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === mesg.id) {
      const apiRequestCallId = mesg.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = mesg.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apigetBanklistCallId) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.apigetBankFilterlistCallId) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.apigetBankGroupbylistCallId) {
          this.apiResponse(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken
    })
   await this.getBanklistApi(this.state.per_page,this.state.page)
  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
    
    if ((prevState.searchKeyword !== this.state.searchKeyword)) {
      if(this.state.searchKeyword.length>=3){
        await this.getBanklistApi(this.state.per_page,1);
      }
      if(this.state.searchKeyword.length==0){
        await this.getBanklistApi(this.state.per_page,1);
      }
    }
    if ((prevState.activeFilterArr.length !== this.state.activeFilterArr.length)) {
      await this.getBankFilterlistApi('','');
    }
    if (prevState.activeGroup !== this.state.activeGroup) {
      this.state.activeGroup == 'Country' ? await this.getBankGroupbylistApi('user_types') : await this.getBankFilterlistApi('','');
    }
    if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
      this.state.token && await this.getBanklistApi(this.state.per_page,this.state.page)
    }
    
    
  }

  bankGroupListItem = (value:any) => {
    if(this.state.activeGroup == value){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  showAddToDashboardItem = () => {
    if(this.state.showAddToDashboard){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  showCurrentSearchItem = () => {
    if(this.state.showCurrentSearch){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  selectedListItems = (value:any) => {
    if(this.state.activeFilterArr.includes(value)){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  searchPlaceholderFnc = () => {
    return !(this.state.searchArr.length) ? 'Search here...' : '';
  }

  searchIconDisplayFnc = () => {
    if(this.state.activeFilterArr.length && this.state.activeGroup){
        return 'display-none'
    }else{
        return ''
    }
  }

  searchTextItems = () => {
    if(!this.state.searchArr.length){
        return 'display-none'
    }else{
        return ''
    }
  }

  checkboxItems = () => {
    if(!this.state.checkedArr.length){
        return 'display-none'
    }else{
        return ''
    }
  }

  loaderBoxFnc = () => {
    if(this.state.loading || this.state.showBankdata.length==0){
      return 'loaderBox'
    }else{
      return 'display-none'
    }
  }

  loaderBoxContentFnc = () => {
    if(this.state.loading){
      return <CircularProgress />
    }else if(this.state.showBankdata.length==0){
      return <Typography className='bold-text'>No Records Found!</Typography>
    }else{
      return <></>
    }
  }

  listViewFnc = () => {
    if(!(this.state.defaultView && this.state.showBankdata.length>0 && (!this.state.loading))){ 
      return 'display-none'
    }else{
      return ''
    }
  }

  handleGlobalCheckBank = () => {
    let arr:any = [];
    this.state.showBankdata?.forEach((user:any) => {
      arr.push(user.id)
    })
    this.setState({...this.state,checkedArr:!this.state.globalCheck?arr:[],globalCheck:!this.state.globalCheck})
  }

  handleUserCheckboxBank = (id:any) => {
    if(this.state.checkedArr.includes(id)){
      this.setState({...this.state,globalCheck:false,checkedArr:this.state.checkedArr.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,globalCheck:(this.state.showBankdata.length==(this.state.checkedArr.length+1))?true:false,checkedArr:[...this.state.checkedArr,id]})
    }
  }

  chipLabels = (value:any) => {
    return value==0 ? 'Archived' : value;
  }
  
  handleBankActive = (id:any) => {
    if(this.state.active.includes(id)){
      this.setState({...this.state,active:this.state.active.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,active:[...this.state.active,id]})
    }
  }

  filterIcon = (type:any) => {
    return type=='filter'?'handleFilterIcon':'handleGroupIcon'
  }

  filterImage = (type:any) => {
    return type=='filter'?filtericon:groupWhite
  }

  filterRemove = (type:any,value:any) => {
    return type=='filter'?this.removeBankActiveFilter(value):this.handleActiveBankGroup('')
  }

  backwardArrow1 = () => {
    return this.state.page==1 ? movebackward : moveBackwardArrowEnable
  }

  backwardArrow2 = () => {
    return this.state.page==1 ? backarrow : backArrowEnable
  }

  forwardArrow1 = () => {
    return this.isThisLastBankPage()? forwardArrowDisable:forwardarrow
  }

  forwardArrow2 = () => {
    return this.isThisLastBankPage()? moveForwardArrowDisable:moveforward
  }

  groupView = () => {
    if(this.state.activeGroup){
        return 'display-none'
    }else{
        return ''
    }
    
  }
  
  handleActiveBankFilter = (value:any) => {
    !(this.state.searchArr.filter((i)=>i.value==value).length) && this.setState({...this.state,searchArr:[...this.state.searchArr,{type:'filter',value:value}],activeFilter:value,activeFilterArr:[...this.state.activeFilterArr,value],showFilter:false});
  }

  removeBankActiveFilter = (value:any) => {
    const filteredData = this.state.activeFilterArr.filter(number => number !== value);
    const sarr = this.state.searchArr.filter(i=>i.value!=value);
    this.setState({...this.state,searchArr:sarr,activeFilterArr:filteredData});
  }

  handleActiveBankGroup = (value:any) => {
    if(!value){
      const sarr = this.state.searchArr.filter(i=>i.type!='group');
      this.setState({...this.state,searchArr:sarr,activeGroup:'',showGroup:false})
    }
    else{
      !(this.state.searchArr.filter((i)=>i.value==value).length) && this.setState({...this.state,searchArr:[...this.state.searchArr,{type:'group',value:value}],activeGroup:value,showGroup:false});
    }
    
  }

  loadMoreBankRecords = async(action:number,type:string='',lmore:boolean=false) => {
    this.setState({
      page:action==0?1:this.state.page+action,
      loadMoreRecords:lmore
    })
    
  }

  handleBankPagination = async(action:number) => {
    this.setState({
      per_page:action,page:1
    })
    
  }

  isThisLastBankPage(): boolean {
    if (!this.state.showBankdata.length) {
      return true;
    }
    if (!this.state.activeGroup) {
      return this.state.page === this.state.totalPage;
    }
    return true;
  }
  
  navigatetoCreateBank = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddBank'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

  }

  navigatetoBankViewMode = (id:string) => {
    this.props.navigation.navigate("BankViewMode", { id: id })

  }
  
  apiResponse=(responseJson:any)=>{
    if(responseJson.data[0]?.banks?.data?.length>0){
      let res:any = [];
      responseJson.data.forEach((data:any)=>{
        data.banks.data.length>0 && res.push(...data.banks.data)
      })
      this.setState({loadMoreRecords:false,groupbyResponse:responseJson.data,showBankdata:res,totalCounts:res.length,totalPage:1});
    }else if(responseJson.data){
      (this.state.loadMoreRecords && this.state.showBankdata?.length>0) ? this.setState({loadMoreRecords:false,showBankdata:[...this.state.showBankdata,...responseJson.data],totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages}) : this.setState({loadMoreRecords:false,showBankdata:responseJson.data,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }else if(responseJson.errors){
    window.alert("Invalid Token")
    }
  }
  
  handleFilter = () => {
    this.setState({ ...this.state, showFilter: !this.state.showFilter });
  };

  handleGroup = () => {
    this.setState({ ...this.state, showGroup: !this.state.showGroup });
  };

  handleBankFavorites = () => {
    this.setState({ ...this.state, showFav: !this.state.showFav });
  };

  handleBankCurrentSearch = () => {
    this.setState({ ...this.state,showCurrentSearch: !this.state.showCurrentSearch });
  };

  handleBankAddToDashboard = () => {
    this.setState({ ...this.state,showAddToDashboard: !this.state.showAddToDashboard });
  };
  
  handleSettings = () => {
    this.setState({ ...this.state, showSettings: !this.state.showSettings });
  };

  getBanklistApi = async (per_page:number,page1:number) => {
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:page1});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    const searchkyword = (this.state.searchKeyword && this.state.searchKeyword.length>2) ? `&search=${this.state.searchKeyword}` : ''

    this.apigetBanklistCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.bankApiUrl}?per_page=${per_page}&page=${page1}${searchkyword}${this.state.activeFilterArr.includes(0)?'&archived=yes':''}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBankGroupbylistApi = async (value:string) => {

    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const urlFilter = `${this.state.activeFilterArr.includes(0)?'&archived=yes':''}`; 
    const urlParams = `?country=yes${urlFilter}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetBankGroupbylistCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.bankApiUrl}${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBankFilterlistApi = async (key:string,value:string) => {
     
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const perPageString = !this.state.activeGroup?`?per_page=`+this.state.per_page+`&page=1`:'?'
    const urlParams = `${perPageString}${this.state.activeFilterArr.includes(0)?'&archived=yes':''}${this.state.activeGroup == 'Country' ? '&country=yes' : ''}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };
    this.apigetBankFilterlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.bankApiUrl}${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  expandMoreIcon = () => {
    return <img className='expandMoreImg' height={7} src={paginationarrow}/>;
  }

  handleGroupHeading = (item:any,index1:number,index:number) => {
    if(index1 == 0){
      return <TableRow className='table-custom-row' data-test-id="table-custom-row" onClick={() => this.handleBankActive(index)} >
                  <TableCell >
                  <IconButton
                      size="small"
                      className='arrow-icon-button'
                      aria-label="expand row"
                  >
                      <img src={this.state.active.includes(index) ? griddownarrow : gridleftarrow} />
                  </IconButton>
                  </TableCell>
                  <TableCell component="th" className='table-column-bold' scope="row" colSpan={7}>
                  { item.key.split("@")[0] }
                  { ` (${item.banks.data.length})` }
                  </TableCell>
                  
              </TableRow>
    }else{
      return ''
    }
    
  }

  expandTableRow = (user:any,classes:any) => {
    return <TableRow onClick={()=>this.navigatetoBankViewMode(user?.id)}>
                <TableCell style={{width:"60px"}} >
                  <Checkbox 
                  checked={this.state.checkedArr.includes(user?.id) ? true : false} 
                  className={classes.userlistviewCheck} 
                  onClick={(e)=>{
                    e.stopPropagation();
                    this.handleUserCheckboxBank(user?.id);
                  }}
                  data-test-id="usercheckboxBank" 
                  /></TableCell>
                <TableCell>{user.attributes?.bank_name}</TableCell>
                <TableCell>{user.attributes?.bank_identifier_code}</TableCell>
                <TableCell>{user.attributes?.country.split("@")[0]}</TableCell>
            </TableRow>
  }

  // Customizable Area End
}
