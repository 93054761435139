import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExpandMore } from '@material-ui/icons';
import StorageProvider from "../../../framework/src/StorageProvider";
import { CustomMultiSelect } from '../../../components/src/CustomMultiSelect.web';
import { Box, Typography } from '@material-ui/core';
export const configJSON = require("./config");


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface Props {
    navigation: any;
    id: string;
    
    
    // Customizable Area Start
    
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    userType:string;
    notificationType:string;
    value: number;
    open: boolean;
    user_name:string;
    relatedPartner:string;
    defaultCompany:string;
    language:string;
    timezone:string;
    email:string;
    errorAlert:boolean;
    popupAlert:string;
    noteAlert:boolean;
    allowedCompanies:any[];
    sales:any[];
    purchase:any[];
    accounting:any[];
    humanResources:any[];
    settings:any[];
    pointOfSale:any[];
    inventory:any[];
    contacts:any[];
    sign:any[];
    approval:any[];
    tag:any[];
    showSettings: boolean;
    user_login:string;
    new_password:string;
    token: string | null;
    timezones: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export interface Option {
    value: string;
    label: string;
    
}

export default class CfcustomersalescreatecustomerController extends BlockComponent<
    Props,
    S,
    SS
> {
    addUserApiCallId: any = "";
    stateObj = {
                userType:'internal_user',
                notificationType:'handle_by_emails',
                value: 0,
                open: false,
                user_name:'',
                relatedPartner:'',
                defaultCompany:'',
                language:'',
                timezone:'',
                email:'',
                errorAlert:false,
                popupAlert:'',
                noteAlert:false,
                allowedCompanies:[],
                sales:[],
                purchase:[],
                accounting:[],
                humanResources:[],
                settings:[],
                pointOfSale:[],
                inventory:[],
                contacts:[],
                sign:[],
                approval:[],
                tag:[],
                showSettings: false,
                user_login:'',
                new_password:'',
                token: '',
                timezones: [],
              };
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
          ];
        // Customizable Area Start  
        this.state = this.stateObj;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        
        // Customizable Area End
    }

    handleOutsideClick = (event: MouseEvent) => {
      if (this.state.showSettings && this.divRef && !this.divRef.contains(event.target as Node)) {
        this.setState({...this.state,showSettings:false});
      }
    }

    async componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick);
      //const timezones:any = moment.tz.names();
      const timezones:any = configJSON.timezones;
      this.setState({ ...this.state,timezones });
      const getToken =  await StorageProvider.get("TOKEN")
       this.setState({
         token: getToken
       })
       
     }
    
    componentDidUpdate(prevProps: {}, prevState: S) {
        this.state.errorAlert && setTimeout(() => {this.setState({...this.state,errorAlert:false})}, 5000);
        
        (prevState.popupAlert !== this.state.popupAlert) && setTimeout(() => {this.setState({...this.state,popupAlert:''})}, 5000);
          
    }
    
    
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          console.log({errorReponse});
          runEngine.debugLog("API Message Recived", message);
          console.log('before',responseJson);
          
          if (responseJson) {
            if (responseJson.errors) {
              this.showAlert(configJSON.errorTitle, configJSON.something_went_wrong);
            }
            
            if (apiRequestCallId === this.addUserApiCallId) {
              this.handleSaveSettingResponse(responseJson)             
            }
          } 
          
        }
        // Customizable Area End
      }

    isStringNullOrBlank(str: string) {
    return str === null || str?.length === 0;
    }

    discardUserData = () => {
      this.props.navigation.navigate(configJSON.navigateToSetting);
      this.setState(this.stateObj);
    }
    
    expandMore = () => {
      return <ExpandMore/>;
    }

    isValidEmail = (Email: string,field:string) => {
        let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (reg.test(Email) === false && Email) {
          this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
          return false;
        } else {
          return true;
        }
    };
    
    handleChangePassword=()=>{
        
        
    }

    TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
        return <div hidden={value !== index}>{children}</div>;
    };
    
    handleUserType = (event: any) => {
        this.setState({ ...this.state,userType: event.target.value });
    };

    handleNotificationType = (event: any) => {
      this.setState({ ...this.state,notificationType: event.target.value });
    };
    
    companies_options: Option[] = [
      { value: 'Company A', label: 'Company A' },
      { value: 'Company B', label: 'Company B' }
      // Add more options as needed
    ];

    contacts_options: Option[] = [
      { value: 'Contacts 1', label: 'Contacts 1' },
      
      // Add more options as needed
    ];

    sales_options: Option[] = [
        { value: 'View Sales', label: 'View Sales' },
        { value: 'Edit Sales', label: 'Edit Sales' }
        // Add more options as needed
    ];

    tag_options: Option[] = [
        { value: 'ERAM Team', label: 'ERAM Team' },
        { value: 'Purchase Team', label: 'Purchase Team' },
        { value: 'Sales Team', label: 'Sales Team' }
        // Add more options as needed
    ];

    point_of_sale_options: Option[] = [
      { value: 'View POS', label: 'View POS' },
      
      // Add more options as needed
    ];

    purchase_options: Option[] = [
      { value: 'View Purchase', label: 'View Purchase' },
      
      // Add more options as needed
    ];

    inventory_options: Option[] = [
      { value: 'Inventory 1', label: 'Inventory 1' },
      { value: 'Inventory 2', label: 'Inventory 2' }
      // Add more options as needed
    ];

    accounting_options: Option[] = [
      { value: 'View Accounting', label: 'View Accounting' },
      { value: 'Edit Accounting', label: 'Edit Accounting' }
      // Add more options as needed
    ];

    human_resources_options: Option[] = [
      { value: 'View Employees', label: 'View Employees' },
      
      // Add more options as needed
    ];

    sign_options: Option[] = [
      { value: 'View Sign', label: 'View Sign' },
      
      // Add more options as needed
    ];

    approval_options: Option[] = [
      { value: 'View Approval', label: 'View Approval' },
      
      // Add more options as needed
    ];

    settings_options: Option[] = [
      { value: 'Edit Settings', label: 'Edit Settings' },
      
      // Add more options as needed
    ];

    divRef: HTMLDivElement | null = null;

    customSelect = (options: Option[],selectState:any[],type:string,label:string) => {
      const selecttxt = `Select ${label}`;
      return <Box className={`main-color relative ${(type=='allowedCompanies' || type=='tag') ? '' : 'bold-text'}`}>
        <CustomMultiSelect
      classes={{}}
      options={options}
      required={false}
      label={label}
      testID=""
      width="100%"
      value={selectState}
      onChangeValue={(value: any) =>
      this.handleCustomMultiSelect(value, type)
      }
      /> 
       {(!(selectState.length>0)) && <Typography className='select-companies'>{selecttxt}</Typography>}
        </Box>
    }

    handleCustomMultiSelect = (value: any, name: any) => {
      switch (name) {
      case 'allowedCompanies':
        this.setState({ ...this.state,allowedCompanies: value });
        break;
      case 'sales':
        this.setState({ ...this.state,sales: value });
        break;
      case 'purchase':
        this.setState({ ...this.state,purchase: value });
        break;
        case 'accounting':
          this.setState({ ...this.state,accounting: value });
        break;
      case 'humanResources':
        this.setState({ ...this.state,humanResources: value });
        break;
        case 'settings':
          this.setState({ ...this.state,settings: value });
        break;
      case 'pointOfSale':
        this.setState({ ...this.state,pointOfSale: value });
        break;
        case 'inventory':
          this.setState({ ...this.state,inventory: value });
        break;
        case 'contacts':
        this.setState({ ...this.state,contacts: value });
        break;
      case 'sign':
        this.setState({ ...this.state,sign: value });
        break;
        case 'approval':
        this.setState({ ...this.state,approval: value });
        break;
        case 'tag':
        this.setState({ ...this.state,tag: value });
        break;
      default:
        
    }

  
      
    }
    
    foptions:any[] = [];

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ ...this.state,value: newValue });
    };
    handleOpen = () => {
        this.setState({ ...this.state,open: true });
    };
    
    handleClose = () => {
        this.setState({ ...this.state,open: false });
    };

    addUserData = () => {
      let formdata:any = new FormData();
      formdata.append("data[first_name]",this.state.user_name);
      formdata.append("data[email]", this.state.email);
      formdata.append("data[user_type]", 'admin');
      formdata.append("data[related_partner]", this.state.relatedPartner);
      formdata.append("data[preference_attributes][language]", this.state.language);
      formdata.append("data[preference_attributes][timezone]", this.state.timezone);
      formdata.append("data[preference_attributes][notification]",this.state.notificationType);
      formdata.append("data[access_right_attributes][default_company]", this.state.defaultCompany);
      formdata.append("data[access_right_attributes][user_types]", this.state.userType);
      formdata.append("data[access_right_attributes][allowed_companies][]", this.state.allowedCompanies.join(','));
      formdata.append("data[access_right_attributes][sales][]", this.state.sales.join(','));
      formdata.append("data[access_right_attributes][purchase][]", this.state.purchase.join(','));
      formdata.append("data[access_right_attributes][accounting][]", this.state.accounting.join(','));
      formdata.append("data[access_right_attributes][human_resources][]", this.state.humanResources.join(','));
      formdata.append("data[access_right_attributes][settings][]", this.state.settings.join(','));
      formdata.append("data[access_right_attributes][point_of_sale][]", this.state.pointOfSale.join(','));
      formdata.append("data[access_right_attributes][inventory][]", this.state.inventory.join(','));
      formdata.append("data[access_right_attributes][contacts][]", this.state.contacts.join(','));
      formdata.append("data[access_right_attributes][sign][]", this.state.sign.join(','));
      
      
      
        const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.addUserApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'account_block/accounts/create_admin'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    checkEmail = () => {
      if(!(this.isValidEmail(this.state.email,'email1'))){
        return false;
      }
      
      this.showAlert(
          configJSON.errorTitle,
          configJSON.errorAllFieldsAreMandatory
      );
    }

    addUser = () => {
    if (
        this.isStringNullOrBlank(this.state.user_name) ||
        this.isStringNullOrBlank(this.state.email) ||
        this.isStringNullOrBlank(this.state.relatedPartner) 
        ) {
          this.checkEmail()
        }else {
        this.addUserData();
        }
    }

    handleSaveSettingResponse(responseJson:any){
      if(responseJson.data?.id){
        this.discardUserData();
      }
    }
    handleSettings = () => {
      this.setState({ ...this.state, showSettings: !this.state.showSettings });
    };

    

}