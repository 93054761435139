    // Customizable Area Start
    exports.httpGetType = "GET";
    exports.httpPostType = "POST";
    exports.httpDeleteType = "DELETE";
    
    exports.categoryApiContentType = "application/json";
    exports.categoryAPIEndPoint = "categories/categories";
    exports.subCategoryAPIEndPoint = "categories/sub_categories";
    exports.validationApiContentType = "application/json";
    exports.validationApiMethodType = "GET";
    
    exports.errorTitle = "Error"
    exports.errorAllFieldsAreMandatory = "Please enter a category first"
    exports.errorCategory = "Please select a category first"
    
    exports.success = "Success";
    exports.category_created_msg = "POS product category created successfully.";
    
    exports.errorTitle = "Error";
    exports.something_went_wrong = "Something went wrong !! Try again.."
    
    exports.uomOption1="Reference Unit of Measure for this category"
    exports.addUomHeading = "Create new: Unit of measure Category"
    exports.uomCategory = "Unit of measure Category"
    exports.en = "EN"
    exports.categoryMessage = "Please enter valid category name."
    exports.addUomTh1 = "Units of Measure"
    exports.addUomTh2 = "UNIT OF MEASURE"
    exports.addUomTh3 = "TYPE"
    exports.addUomTh4 = "RATIO"
    exports.addUomTh5 = "ROUNDING"
    exports.edit = "Edit"
    exports.uomUrl = "/bx_block_productquickview/uoms/uom_drop_down"
    exports.viewUrl = "/bx_block_productquickview/uom_categories/"
    exports.errorMsg = "Something went wrong"
    exports.successMsg = "Uom Created successfully"
    exports.discard = "Discard";
    exports.save = "Save";
    
    
    // Customizable Area End