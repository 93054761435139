import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { validationerror } from "./assets";
import { Box, Typography } from '@material-ui/core';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import { State, City, Country } from "country-state-city";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: token;
    responseData: any;
    shippingMethod: {
        value: number;
        methodName: string;
        provider: any;
        deliveryProduct: any;
        company: any;
        website: any;
        marginOnRate: string;
        isFree: boolean;
        amount: string;
        pricing: {
            fixedPrice: string;
        };
        destinationAvailable: {
            countries: any[];
            states: any[];
            zip_from: any[];
            zip_to: any[];
        };
        description: {
            descriptionText: string;
        };
    }
    saveClick: boolean;
    shippingMethodId: string,
    createClick: boolean,
    location: any,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface Option {
    value: string;
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default class ShippingMethodCreationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createShippingMethodAPI: string = "";
    getSingleShippingMethodAPI: string = "";
    postalCodeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            shippingMethod: {
                value: 0,
                methodName: "",
                provider: "",
                deliveryProduct: "",
                company: "",
                website: "",
                marginOnRate: "",
                isFree: false,
                amount: "",
                pricing: {
                    fixedPrice: "",
                },
                destinationAvailable: {
                    countries: [],
                    states: [],
                    zip_from: [],
                    zip_to: [],
                },
                description: {
                    descriptionText: ""
                },
            },
            saveClick: false,
            shippingMethodId: "",
            createClick: false,
            token: "",
            responseData: [],
            location: {
                countries: [],
                states: [],
                cities: [],
                selectedCountries:[],
                selectedStates: [],
                selectedZipFrom: [],
                selectedZipTo: []
            },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId && responseJson) { this.receiveApi(apiRequestCallId, responseJson); }


            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    providerOptions: Option[] = [
        { value: 'Provider 1', label: 'Provider 1' },
        { value: 'Provider 2', label: 'Provider 2' }
    ];

    productOptions: Option[] = [
        { value: 'Product 1', label: 'Product 1' },
        { value: 'Product 2', label: 'Product 2' }
    ];

    companyOptions: Option[] = [
        { value: 'Company 1', label: 'Company 1' },
        { value: 'Company 2', label: 'Company 2' }
    ];

    websiteOptions: Option[] = [
        { value: 'Website 1', label: 'Website 1' },
        { value: 'Website 2', label: 'Website 2' }
    ];

    countriesOption: Option[] = [
        { value: 'AUS', label: 'AUS' },
        { value: 'IND', label: 'IND' },
        { value: 'NZ', label: 'NZ' },
        { value: 'USA', label: 'USA' }
    ];

    statesOption: Option[] = [
        { value: 'AP', label: 'AP' },
        { value: 'UP', label: 'UP' },
        { value: 'TS', label: 'TS' },
        { value: 'MP', label: 'MP' }
    ];



    async componentDidMount() {
        const token = await getStorageData("TOKEN")
        this.setState({ token: token });
        if (!token) {
            this.props.navigation.navigate("EmailAccountLoginStyles")
        }
        else {
            const id = this.props.navigation.getParam("id")
            if (id) {
                this.fetchSingleShippingMethodData(id)
            }
            // this.getFilteredLocation();
        }
    }


    receiveApi = (
        apiRequestCallId: string,
        responseJson: {
            data: object[];
            meta: { total_pages: number; total_counts: number };
        }
    ) => {
        if (apiRequestCallId === this.createShippingMethodAPI) {
            this.handleresetShippingMethodResponse()

        }
        if (apiRequestCallId === this.getSingleShippingMethodAPI) {
            this.handleGetSingleShippingMethodResponse(
                responseJson.data
            );
        }
    };

    handleresetShippingMethodResponse = () => {
        this.setState({
            shippingMethod: {
                value: 0,
                methodName: "",
                provider: "",
                deliveryProduct: "",
                company: "",
                website: "",
                marginOnRate: "",
                isFree: false,
                amount: "",
                pricing: {
                    fixedPrice: "",
                },
                destinationAvailable: {
                    countries: [],
                    states: [],
                    zip_from: [],
                    zip_to: [],
                },
                description: {
                    descriptionText: ""
                },
            },
            saveClick: false,
            location: {
                countries: [],
                states: [],
                cities: [],
                selectedCountries: [],
                selectedStates: [],
                selectedZipFrom: [],
                selectedZipTo: []
            },
        })
        this.props.navigation.goBack();
    };
    handleGetSingleShippingMethodResponse = (
        data: any,
    ) => {
        const tempShippingData = data?.data?.attributes;
        let countryData=Country.getAllCountries()
        let country=countryData.filter((item :any)=>tempShippingData?.destinations_availabilities.countries.includes(item.name)).map((item:any) => {return {name: item.name,isoCode: item.isoCode,flag: item.flag,phonecode: item.phonecode,currency: item.currency,latitude: item.latitude,longitude: item.longitude};
        });
        this.handleCountryChange(country)
        if (data && data.data && data.data.attributes) {
            const shippingData = data.data.attributes;
            this.setState({

                shippingMethod: {
                    value: 0,
                    methodName: shippingData?.shipping_method,
                    provider: shippingData?.provider,
                    deliveryProduct: shippingData?.delivery_product,
                    company: shippingData?.company,
                    website: shippingData?.website,
                    marginOnRate: shippingData?.margin_on_rate,
                    isFree: shippingData?.free_margin,
                    amount: shippingData?.amount,
                    pricing: {
                        fixedPrice: shippingData?.pricings?.fixed_price,
                    },
                    destinationAvailable: {
                        countries: shippingData?.destinations_availabilities?.countries,
                        states: shippingData?.destinations_availabilities?.states,
                        zip_from: shippingData?.destinations_availabilities?.zip_from,
                        zip_to: shippingData?.destinations_availabilities?.zip_to,
                    },
                    description: {
                        descriptionText: shippingData?.descriptions?.description,
                    },
                },
                location: {
                    ...this.state.location,
                selectedCountries: shippingData?.destinations_availabilities?.countries?.map((data:string, idx:number)=>({name: data, id:idx})),
                selectedStates: shippingData?.destinations_availabilities?.states?.map((data:string, idx:number)=>({name: data, id:idx})),
                selectedZipFrom: shippingData?.destinations_availabilities?.zip_from?.map((data:string, idx:number)=>({name: data, id:idx})),
                selectedZipTo: shippingData?.destinations_availabilities?.zip_to?.map((data:string, idx:number)=>({name: data, id:idx}))
                }
            })
        }
    };


    fetchSingleShippingMethodData = async (id: string) => {
        const token = this.state.token;
        this.getSingleShippingMethodAPI = this.apiCallHandle(
            token,
            `bx_block_settings/shipping_methods/${id}`,
            "GET"
        );
    };

    handleCountryChange = (countries: any) => {
        const states = [] as any;
        const cities = [] as any;

        countries.forEach((country: any) => {
            const _states = State.getStatesOfCountry(country.isoCode).map(destStates => {
                destStates['countryCode'] = country.isoCode;
                return destStates;
            });
            states.push(..._states);

            _states.forEach((destStates: any) => {
                cities.push(...City.getCitiesOfState(destStates.countryCode, destStates.isoCode));
            });
        });

        this.setState(prevState => ({
            location: {
                ...prevState.location,
                countries,
                states: states,
                cities,
                selectedCountries: countries,
                selectedStates: prevState.location.selectedStates.filter((destState: any) => states.some((state: any) => state.countryCode === destState.countryCode)),
                selectedZipFrom: prevState.location.selectedZipFrom.filter((zip: any) => states.some((state: any) => state.countryCode === zip.countryCode)),
                selectedZipTo: prevState.location.selectedZipTo.filter((zip: any) => states.some((state: any) => state.countryCode === zip.countryCode))
            }
        }));
    };

    handleRegionChange = (destStates: any) => {
        const cities = [] as any;
        destStates.forEach((destStat: any) => {
            cities.push(...City.getCitiesOfState(destStat.countryCode, destStat.isoCode));
        });

        this.setState(prevState => ({
            location: {
                ...prevState.location,
                cities,
                selectedStates: destStates,
                selectedZipFrom: prevState.location.selectedZipFrom.filter((zip:any) => destStates.some((destStat: any) => destStat.countryCode === zip.countryCode)),
                selectedZipTo: prevState.location.selectedZipTo.filter((zip:any) => destStates.some((destStat: any) => destStat.countryCode === zip.countryCode))
            }
        }));
    };



    handleZipFromChange = (zipFrom: any) => {
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                selectedZipFrom: zipFrom,
            }
        }));
    };

    handleZipToChange = (zipTo: any) => {
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                selectedZipTo: zipTo
            }
        }));
    };


    TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
        return <div hidden={value !== index}>{children}</div>;
    };


    errorTextClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value) {
            return 'error-text1';
        } else {
            return 'main-color';
        }
    }


    errorFocusClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value) {
            return 'error-focus';
        } else {
            return '';
        }
    }
    errorAutcompleteFocusClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value.length) {
            return 'error-focus';
        } else {
            return '';
        }
    }


    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            ...this.state,
            shippingMethod: {
                ...this.state.shippingMethod,
                value: newValue
            }
        });
    };

    handleValueChange = (e: any, name: string) => {
        this.setState({
            ...this.state, shippingMethod:
            {
                ...this.state.shippingMethod,
                [name]: e.target.value
            }
        })
    }

    handlePricingValueChange = (e: any, name: string) => {
        this.setState({
            ...this.state,
            shippingMethod: {
                ...this.state.shippingMethod,
                pricing: {
                    ...this.state.shippingMethod.pricing,
                    [name]: e?.target.value
                }
            }
        });
    }


    handleMultiSelect = (value: any, name: string) => {
        this.setState({
            ...this.state,
            shippingMethod: {
                ...this.state.shippingMethod,
                destinationAvailable: {
                    ...this.state.shippingMethod.destinationAvailable,
                    [name]: value
                }
            }
        });
    }

    handleDescriptionValueChange = (e: any, name: string) => {
        this.setState({
            ...this.state,
            shippingMethod: {
                ...this.state.shippingMethod,
                description: {
                    ...this.state.shippingMethod.description,
                    [name]: e.target.value
                }
            }
        });
    }

    handleIsFreeCheckbox = () => {
        this.setState({
            ...this.state,
            shippingMethod: {
                ...this.state.shippingMethod,
                isFree: !this.state.shippingMethod.isFree,
            }
        });
    }
    
    isValueOrPlaceholder(shipState :any, placeholder : string) {
        return shipState === placeholder ? "" : shipState;
    }    
    
    autocompletePlaceholder(shipState :any, placeholder : string) {
        return shipState ? "" : placeholder;
    }  
    handleErrorLabel = (flabel: any) => {
        return <Box className='flex relative' >
            <img className='error-text-img' src={validationerror} />
            <Typography className='error-text'>{flabel}</Typography>
        </Box>
    }

    mainErrorBox = () => {
        return <Box className={`flex error-main-box ${!(this.state.saveClick && !this.checkAddCustomerValidation()) && 'display-none'}`}>
            <img src={validationerror} />
            <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
        </Box>
    }

    checkAddCustomerValidation = () => {
        if (
            this.isStringNullOrBlank(this.state.shippingMethod.methodName) ||
            this.isStringNullOrBlank(this.state.shippingMethod.provider) ||
            this.isStringNullOrBlank(this.state.shippingMethod.deliveryProduct) ||
            this.isStringNullOrBlank(this.state.shippingMethod.company) ||
            this.isStringNullOrBlank(this.state.shippingMethod.marginOnRate) ||
            this.isStringNullOrBlank(this.state.shippingMethod.amount) ||
            this.isStringNullOrBlank(this.state.shippingMethod.pricing.fixedPrice)
        ) {
            return false;
        }
        return true;
    }


    isStringNullOrBlank(str: string) {
        return str === null || str === '' || /^\s*$/.test(str);
    }

    isArrayEmpty(arr: any[]) {
        if (arr.length === 0) return false;
        return true;
    }


    handleLabel = (flabel: any) => {
        return <>
            {
                flabel ? this.handleErrorLabel(flabel) : flabel
            }
        </>
    }


    handleErrorMessage = (type: string, show: boolean = false) => {
        if (this.state.saveClick || show) {
            let flabel: any = '';
            switch (type) {
                case 'methodName': flabel = this.showErrorMessage(this.state.shippingMethod.methodName.trim(), 'Please enter valid method name.'); break;
                case 'provider': flabel = this.showErrorMessage(this.state.shippingMethod.provider, 'Please select valid provider.'); break;
                case 'deliveryProduct': flabel = this.showErrorMessage(this.state.shippingMethod.deliveryProduct, 'Please select valid delivery product.'); break;
                case 'company': flabel = this.showErrorMessage(this.state.shippingMethod.company, 'Please select valid company'); break;
                case 'marginOnRate': flabel = this.showErrorMessage(this.state.shippingMethod.marginOnRate, 'Please enter valid margin on rate.'); break;
                case 'amount': flabel = this.showErrorMessage(this.state.shippingMethod.amount, 'Please enter valid amount.'); break;
                case 'fixedPrice': flabel = this.showErrorMessage(this.state.shippingMethod.pricing.fixedPrice, 'Please enter valid pricing.'); break;
                case 'countries': flabel = this.showErrorMessage(this.state.location.selectedStates.length, 'Please select valid countries.'); break;
                case 'states': flabel = this.showErrorMessage(this.state.location.selectedStates.length, 'Please select valid state.'); break;
                case 'zip_from': flabel = this.showErrorMessage(this.state.location.selectedZipFrom.length, 'Please select valid zip from.'); break;
                case 'zip_to': flabel = this.showErrorMessage(this.state.location.selectedZipTo.length, 'Please select valid zip to.'); break;

                default:
                    break;

            }
            return this.handleLabel(flabel)
        } else {
            return ''
        }

    }

    showErrorMessage = (state: any, msg: string) => {
        return !state && msg;
    }

    handleDiscard = () => {
        this.props.navigation.goBack();
    }


    handleSave = () => {
        const formdata: any = new FormData();
        formdata.append("data[shipping_method]", this.state.shippingMethod.methodName)
        formdata.append("data[provider]", this.state.shippingMethod.provider)
        formdata.append("data[company]", this.state.shippingMethod.company)
        formdata.append("data[margin_on_rate]", +(this.state.shippingMethod.marginOnRate))
        formdata.append("data[free_margin]", this.state.shippingMethod.isFree)
        formdata.append("data[amount]", +(this.state.shippingMethod.amount))
        formdata.append("data[delivery_product]", this.state.shippingMethod.deliveryProduct)
        formdata.append("data[is_published]", false)
        formdata.append("data[archived]", false)
        formdata.append("data[pricings_attributes][fixed_price]", this.state.shippingMethod.pricing.fixedPrice)
        this.state.location.selectedCountries.forEach((country:any) => {
            formdata.append("data[destinations_availabilities_attributes][countries][]", country.name?.toString());
        });

        this.state.location.selectedStates.forEach((destState: any) => {
            formdata.append("data[destinations_availabilities_attributes][states][]", destState.name?.toString());
        });

        this.state.location.selectedZipFrom.forEach((destZipTo: any) => {
            formdata.append("data[destinations_availabilities_attributes][zip_to][]", destZipTo.name?.toString());
        });

        this.state.location.selectedZipTo.forEach((destZipFrom: any) => {
            formdata.append("data[destinations_availabilities_attributes][zip_from][]", destZipFrom.name?.toString());
        });

        formdata.append("data[descriptions_attributes][description]", this.state.shippingMethod.description.descriptionText?.toString())

        this.setState({
            saveClick: true
        })

        const token = this.state.token;
        if (this.checkAddCustomerValidation()) {

            const id = this.props.navigation.getParam("id")

            let endPoint = `/bx_block_settings/shipping_methods`
            let method = "POST"
            if (id) {
                endPoint += `/${id}`
                method = "PATCH"
            }
            this.createShippingMethodAPI = this.apiCallHandle(
                token,
                endPoint,
                method,
                formdata
            );
        }
    };



    apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
        const header = { token: token };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), header);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), body)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    // Customizable Area End
}
