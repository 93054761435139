import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Popper,
  ClickAwayListener,
  MenuList,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Dialog,
  DialogContent,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link }  from "react-router-dom";
import { createTheme, ThemeProvider, makeStyles, withStyles } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
const {activity ,addBtn,deleteBtn,graph,preview ,check } = require("./assets");
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done'; 
import clsx from "clsx";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A6395",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const tableHeading = [
  "Product Name",
  "Description of Product",
  "Qty.",
  "In Units",
  "Price(IN UNITS)",
  "Tax",
  "Discount",
  "Subtotal",
];

const steps = ['Quotation', 'Quotation Sent', 'Order Sent', 'Done'];

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 11,
  },
  active: {
    "& $line": {
      background: "#2A6395",
    },
  },
  completed: {
    "& $line": {
      background: "#2A6395",
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 25,
    height: 25,
    display: "flex",
    borderRadius: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#2A6395",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    background: "#2A6395",
  },
  icon:{
    color:"grey",
    padding:0,
  },
  iconActive :{
    background: "transparent",
    color: "#2A6395",
  },
  iconCompleted: {
    background: "none",
    color: "#2A6395",
  }
});

function ColorlibStepIcon(props:any) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div className={clsx(classes.root, {
      [classes.active]: active,
      [classes.completed]: completed,
    })}>1</div>,
    2: <div className={clsx(classes.root, {
      [classes.active]: active,
      [classes.completed]: completed,
    })}>2</div>,
    3: <div className={clsx(classes.root, {
      [classes.active]: active,
      [classes.completed]: completed,
    })}>3</div>,
    4: <DoneIcon
      className={clsx(classes.icon,{
      [classes.iconActive]: active,
      [classes.iconCompleted]: completed,
    })} />
  };

  return (
    <>
      {icons[String(props.icon)]}
    </>
  );
}

// Customizable Area End

import Ordercreation2Controller, {
  Props,
  configJSON,
} from "./Ordercreation2Controller";
import { displayName } from "cleave.js/react";

export default class Ordercreation2 extends Ordercreation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.gridMainLayout}>
          <Box style={webStyle.gridsideBar}>
            <Sidebar />
          </Box>
          <Box className="gridcontent" style={webStyle.gridcontent}>
            <AppHeader pageTitle={configJSON.ordermanagement} />

            <Box display={"flex"} flexDirection={"row-reverse"} style={{margin:"1px"}}>
              <Stepper activeStep={this.state.activeStep} connector={<ColorlibConnector />} style={{width:"50%",background:"transparent"}}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box className="parent-div" >
              <style>
                {`
                  .MuiInput-underline:after {
                    border-bottom: 0px !important;
                  }
                  .MuiSelect-selectMenu{
                    border: 1px solid #e3e3e3;
                  }
                  .MuiInputBase-root{
         
                    border-radius: 5px;
                  }
                  .MuiInputBase-input{
                    border: 0px !important;
                  }
                  .MuiInputAdornment-positionEnd{
                    padding : 10px;
                  }
                  .MuiFormControl-fullWidth{
                    width: 90%;
                  }
                  .MuiChip-root{
                    background-color:#e0e0e0 !important;
                    color:#000 !important;
                  }
                  .MuiChip-deleteIcon{
                    color:#000 !important;
                  }
                  .MuiIconButton-root:hover{
                    background-color: transparent !important;
                  }
                  .productnameCell {
                      & .MuiInputBase-root {
                        outline: none;
                      }
                  }
                  .MuiPaper-elevation1 {
                    box-shadow: none;
                  }
                  .MuiStepLabel-label.MuiStepLabel-active{
                    color:#2A6395;
                  }
                  .MuiStepLabel-label.MuiStepLabel-completed{
                    color:#2A6395;
                  }
                  .MuiGrid-spacing-xs-3{
                    width: calc(100% + 50px);
                  }
                  .MuiStepper-root{
                    padding: 24px 0;
                  }
                  .MuiSelect-select.MuiSelect-select{
                    padding-right : 40px;
                  }
                  .MuiTableCell-alignLeft{
                    padding:8px;
                  }
                  .tablecontainer::-webkit-scrollbar {
                      height:5px;
                      width:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb {
                      background: lightgrey;
                      border-radius:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb:hover {
                      background: grey;
                    }
                  }
                `}
              </style>
              <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Grid item sm={3} lg={12} md={12}>
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} style={{ paddingRight:"25px" }} >
                    <Typography>
                    <Link to = "/SalesOrder" 
                    className="customer bold-text" 
                    style={{ textDecoration: 'none' }} >
                      {configJSON.quotation} / 
                      </Link>
                      <span style={{ color: "grey" }}>New</span>
                    </Typography>
                    <Box sx={webStyle.activityIconWrapper}>
                      <img src={activity} alt={"icon"} />
                      <Typography>Activity</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Divider style={webStyle.divider} />
                <Box style={{margin:"10px"}}>
                {
                  this.mainErrorBox()
                }
                </Box>
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingX={"15px"} >
                  <Typography className='bold-text' style={{ color: "#000", fontSize: "20px" }}>
                      New
                    </Typography>
                    <Box display={"flex"} flexDirection={"row"}>
                    <Typography className='bold-text' style={{ color: "#2A6395", fontSize: "18px", whiteSpace: "nowrap",marginInlineEnd: "10px"}}>
                        Customer Preview
                      </Typography>
                      <img src={preview} alt="preview"/>
                    </Box>
                  </Box>
                <Box style={{padding: "0px !important", width: "100%" }}>
                  <Box className="form-div" style={{width: "95%"}}>
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6} >
                      <Typography className={`${this.errorTextClass('customer_code', this.state.customer_code)}`}>Customer Code<span className='required'>*</span></Typography>
                        <TextField
                          data-test-id="customer_code"
                          name="customer_code"
                          type="text"
                          style={{ width: "95%", border: "1px solid #e3e3e3", borderRadius: "5px" }}
                          value={this.state.customer_code}
                          onChange={(e: any) => this.handleValueChange(e, "customer_code")}
                          placeholder="Enter Customer Code"
                          className={`${this.errorFocusClass('customer_code', this.state.customer_code)}`}
                        />
                      {
                        this.handleErrorMessage('customer_code')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} >
                      <Typography className={`${this.errorTextClass('status_of_order', this.state.status_of_order)}`}>Status of Order<span className='required'>*</span></Typography>
                      <TextField
                        data-test-id="status_of_order"
                        name="status_of_order"
                        type="text"
                        style={{ width: "95%", border: "1px solid #e3e3e3", borderRadius: "5px" }}
                        value={this.state.status_of_order}
                        onChange={(e: any) => this.handleValueChange(e, "status_of_order")}
                        placeholder="Enter Status of Order"
                        className={`${this.errorFocusClass('status_of_order', this.state.status_of_order)}`}
                      />
                      {
                        this.handleErrorMessage('status_of_order')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('customer_name', this.state.customer_name)}`}>Customer Name<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          displayEmpty
                          disableUnderline
                          renderValue={() => this.state.customer_name || "Select Customer Name"}
                          data-test-id="customer_name"
                          className={`width-100 ${!this.state.customer_name && 'select-default-color'} ${this.errorFocusClass('customer_name', this.state.customer_name)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.customer_name}
                          onChange={(e: any) => this.handleValueChange(e, "customer_name")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none', color: "#E5E5E5" }}>
                            Select Customer Name
                          </MenuItem>
                          {this.state.customerNameList.map((item) => (
                            <MenuItem className='select-menu-item' value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('customer_name')
                      }
                      <Box width={"95%"}>
                        <Typography className='bold-text' style={{ color:"#2A6395" , float:"right"}}>Create New Customer</Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('total_items', this.state.total_items)}`}>Total no. of Items<span className='required'>*</span></Typography>
                      <TextField
                        data-test-id="total_items"
                        name="total_items"
                        type="text"
                        style={{ width: "95%", border: "1px solid #e3e3e3",borderRadius:"5px"}}
                        value={this.state.total_items}
                        onChange={(e: any) => this.handleValueChange(e, "total_items")}
                        placeholder="Enter Total no. of Items"
                        className={`${this.errorFocusClass('total_items', this.state.total_items)}`}
                      />
                      {
                        this.handleErrorMessage('total_items')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between" >
                    <Grid item sm={6} md={6} lg={6} >
                        <Typography className={`${this.errorTextClass('order_date', this.state.order_date)}`}>Order Date<span className='required'>*</span></Typography>
                        <Box style={webStyle.dateBox}>
                          <TextField
                            data-test-id="order_date"
                            name="order_date"
                            type="date"
                            style={{ width: "95%", border: "1px solid #e3e3e3" ,borderRadius:"5px"}}
                            value={this.state.order_date}
                            onChange={(e: any) => this.handleValueChange(e, "order_date")}
                            placeholder="Enter Order Date"
                            className={`${this.errorFocusClass('order_date', this.state.order_date)} `}
                            />
                        </Box>
                      {
                        this.handleErrorMessage('order_date')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} >
                      <Typography className={`${this.errorTextClass('branch_name', this.state.branch_name)}`}>Branch Name<span className='required'>*</span></Typography>
                      <TextField
                        data-test-id="branch_name"
                        name="branch_name"
                        type="text"
                        style={{ width: "95%", border: "1px solid #e3e3e3", borderRadius: "5px"}}
                        value={this.state.branch_name}
                        onChange={(e: any) => this.handleValueChange(e, "branch_name")}
                        placeholder="Enter Branch Name"
                        className={`${this.errorFocusClass('branch_name', this.state.branch_name)}`}
                      />
                      {
                        this.handleErrorMessage('branch_name')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('invoice_address', this.state.invoice_address)}`}>Invoice Address<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          disableUnderline
                          data-test-id="invoice_address"
                          displayEmpty
                          renderValue={() => this.state.invoice_address || "Select Invoice Address"}
                          className={`width-100 ${!this.state.invoice_address && 'select-default-color'} ${this.errorFocusClass('invoice_address', this.state.invoice_address)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.invoice_address || ""}
                          onChange={(e: any) => this.handleValueChange(e, "invoice_address")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                          
                        >
                          <MenuItem disabled value="" style={{ display: 'none' ,color: "#E5E5E5" }}>
                            Select Invoice Address
                          </MenuItem>
                          {this.invoiceAddressOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value} key={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('invoice_address')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('sales_person', this.state.sales_person)}`}>Sales Person<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          disableUnderline
                          data-test-id="sales_person"
                          className={`width-100 ${!this.state.sales_person && 'select-default-color'} ${this.errorFocusClass('sales_person', this.state.sales_person)}`}
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          renderValue={() => this.state.sales_person || "Select Sales Person"}
                          
                          value={this.state.sales_person} 
                          onChange={(e: any) => this.handleValueChange(e, "sales_person")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none',color: "#E5E5E5"}}>
                            Select Sales Person
                          </MenuItem>
                          {this.salesPersonOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value} key={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('sales_person')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('delivery_address', this.state.delivery_address)}`}>Delivery Address<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          disableUnderline
                          displayEmpty
                          data-test-id="delivery_address"
                          className={`width-100 ${!this.state.delivery_address && 'select-default-color'} ${this.errorFocusClass('delivery_address', this.state.delivery_address)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.delivery_address}
                          renderValue={() => this.state.delivery_address || "Select Delivery Address"}
                          onChange={(e: any) => this.handleValueChange(e, "delivery_address")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none' }}>
                            Select Delivery Address
                          </MenuItem>
                          {this.deliveryAddressOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value} key={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('delivery_address')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('pricelist', this.state.pricelist)}`}>Price List<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          displayEmpty
                          label="Select"
                          disableUnderline
                          data-test-id="pricelist"
                          renderValue={() => this.state.pricelist || "Select Price List"}
                          className={`width-100 ${!this.state.pricelist && 'select-default-color'} ${this.errorFocusClass('pricelist', this.state.pricelist)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.pricelist}
                          onChange={(e: any) => this.handleValueChange(e, "pricelist")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none'}}>
                            Select Price List
                          </MenuItem>
                          {this.pricelistOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('pricelist')
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('payment_terms', this.state.payment_terms)}`}>Payment Terms<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          displayEmpty
                          label="Select"
                          disableUnderline
                          data-test-id="payment_terms"
                          className={`width-100 ${!this.state.payment_terms && 'select-default-color'} ${this.errorFocusClass('payment_terms', this.state.payment_terms)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          renderValue={() => this.state.payment_terms || "Select Payment Terms"}
                          value={this.state.payment_terms}
                          onChange={(e: any) => this.handleValueChange(e, "payment_terms")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none' , color:"grey" }}>
                           Select Payment Terms
                          </MenuItem>
                          {this.paymentTerms.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('payment_terms')
                      }
                    </Grid>
                  </Grid>
                  </Box>
                  <Box>
                    <Typography style={{fontSize:"18px" , fontWeight:600 , marginTop:"2%" , marginBottom: "2%", marginLeft: "1%"}}>Order Lines</Typography>
                    <TableContainer component={Paper} className="tableContainer">
                      <Table aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            {tableHeading.map((item, index) => (
                              <TableCell key={index} style={{ textTransform: "uppercase" }}>
                                <b style={{ whiteSpace: "nowrap" }}>{item}</b>
                              </TableCell>
                            ))}
                            <TableCell align="left">
                              <Button data-test-id="addRowBtn" onClick={this.handleAddRow}>
                              <img src={addBtn} alt="addBtn"/>
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.tableData.map((rowData, rowIndex) => (
                            <TableRow key={rowIndex}>
                              <TableCell align="left" className="productnameCell">
                                <Select
                                  labelId="productname"
                                  displayEmpty
                                  data-test-id="productname"
                                  value={rowData.product_name}
                                  onChange={(e) => this.handleProductSelect(rowIndex, e.target.value)}
                                  fullWidth
                                  className={`width-100 ${!rowData.product_name && 'select-default-color'}`}
                                  IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                                >
                                  <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>
                                    Select Product Name
                                  </MenuItem>
                                  {this.state.productDataList.map((product, index) => (
                                    <MenuItem key={index} value={product.name}>
                                      {product.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell align="left">{rowData.product_description}</TableCell>
                              <TableCell align="left" >
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                  <Typography>{this.state.quantity}</Typography>
                                  <ClickAwayListener
                                    data-test-id="clickAway"
                                    onClickAway={this.handleAvailabilityClickAway}
                                  >
                                    <div>
                                      <Button
                                        onClick={this.handleClickAvailability}
                                        data-test-id="graphpopper"
                                        style={webStyle.graphpopper}
                                      >
                                        <img src={graph} alt="graph"/>
                                      </Button>
                                      <Popper
                                        anchorEl={this.state.anchorAvailability}
                                        transition
                                        open={Boolean(this.state.anchorAvailability)}
                                        placement="right-end"
                                        style={webStyle.availabilityPopper}
                                      >

                                        <MenuList style={{ padding: 0 }}>
                                          <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                            <MenuItem
                                              data-test-id="closedMenu-1"
                                              style={webStyle.popperItemBlue}
                                            >
                                              <Typography style={{ color: "#fff" }}>
                                                Availability
                                              </Typography>
                                            </MenuItem>
                                            <MenuItem
                                              data-test-id="forecastedQTY"
                                              style={webStyle.popperItem}
                                              onClick={() => this.handleQty(rowData.forecastedQty)}

                                            >
                                              <Grid container>
                                                <Grid item lg={8} md={8}>
                                                  <Typography style={{ color: "#2A6395", fontSize: "12px" }}>Forecasted Stock</Typography>
                                                  <Typography style={{ color: "grey", fontSize: "10px" }}>on 02/01/2023</Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4}>
                                                  <Typography style={{ color: "#2A6395", fontWeight: 600, fontSize: "12px", marginLeft: "60%" }}>{rowData.forecastedQty} Units</Typography>
                                                </Grid>
                                              </Grid>
                                            </MenuItem>
                                            <MenuItem
                                              data-test-id="qTYOnHand"
                                              style={webStyle.popperItem}
                                              onClick={()=>this.handleQty(rowData.onHandQty)}
                                            >
                                              <Grid container>
                                                <Grid item lg={8} md={8}>
                                                  <Typography style={{ color: "#2A6395", fontSize: "12px" }}>Available</Typography>
                                                  <Typography style={{ color: "grey", fontSize: "10px" }}>All planned operation included</Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4}>
                                                  <Typography style={{ color: "#2A6395", fontWeight: 600, fontSize: "12px", marginLeft: "60%" }}>{rowData.onHandQty} Units</Typography>
                                                </Grid>
                                              </Grid>
                                            </MenuItem>
                                          </div>
                                        </MenuList>
                                      </Popper>
                                    </div>
                                  </ClickAwayListener>
                                </Box>
                              </TableCell>
                              <TableCell>{rowData.units}</TableCell>
                              <TableCell>{rowData.price_in_units} OMR</TableCell>
                              <TableCell>{15} %</TableCell>
                              <TableCell>{15} %</TableCell>
                              <TableCell>{1000} OMR</TableCell>
                              <TableCell>
                                <Button disabled={this.state.tableData.length === 1} data-test-id="deleteRowBtn" onClick={this.state.tableData.length > 1 ? () => this.handleDeleteRow(rowIndex, rowData.quotation_id, rowData.id): ()=>{}}>
                                  <img src={deleteBtn} alt="deleteBtn"/>
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Divider style={{padding:"2px" , marginTop:'3%' , marginBottom:'1%'}}/>

                  <Box display={"flex"} flexDirection={"row"} style={{float:"right",marginTop:"10px" , marginBottom:"10px" ,marginRight:"10px"}} >
                    <Typography style={{marginRight:"10px" , color:"#2A6395" , fontWeight:600}}>
                      Add Shipping
                    </Typography>
                    <Typography style={{ marginRight: "10px", color: "#2A6395", fontWeight: 600 }}>
                      Coupon
                    </Typography>
                    <Typography style={{ marginRight: "10px", color: "#2A6395", fontWeight: 600 }}>
                      Promotion
                    </Typography>
                  </Box>

                  <Box style={{marginTop:"2%", marginLeft: "1%"}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item sm={2} md={2} lg={2} xl={2} >
                        <Typography style={{marginLeft:"5%", marginBottom:"2%"}}>Terms and Conditions</Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          data-test-id="terms_and_conditions"
                          name="terms_and_conditions"
                          type="text"
                          style={{ width: "100%", border: "1px solid #e3e3e3"}}
                          value={this.state.terms_and_conditions}
                          onChange={(e: any) => this.handleValueChange(e, "terms_and_conditions")}
                          placeholder="Enter Terms and Conditions"
                        />
                      </Grid>
                      <Grid item sm={2} md={2} lg={2} xl={2}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ marginLeft: "20%", marginBottom: "2%" }}>
                          <Typography>Margin : </Typography>
                          <Typography style={webStyle.boldFont}>{this.state.margin}</Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={2} md={2} lg={2} xl={2}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ marginLeft: "8%", marginBottom: "2%" }}>
                          <Typography>Total : </Typography>
                          <Typography style={webStyle.boldFont}>{this.state.total} OMR</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Box sx={webStyle.actionBtnStyle}>
              <Button variant="contained" style={webStyle.discardBtn} data-test-id="discardBtn" onClick={this.handleDiscard}>Discard</Button>
              <Button
                type="submit"
                data-test-id="saveBtn"
                style={webStyle.saveBtn}
                onClick={this.handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Dialog
            maxWidth={"sm"}
            data-test-id="deleteDialog"
            open={this.state.deleteDialog}
            onClose={this.handleDialogCancel}
          >
            <DialogContent>
              <Typography>
                Are you sure you want to delete ?
              </Typography> 
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleDialogCancel}
                data-test-id="dialogCancelBtn"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.onDeleteOrderLines}
                data-test-id="dialogDeleteBtn"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    flexDirection: "column",
    height: "100px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  mainWrapper: {
    paddingBottom: "30px",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    background: "#fff",
  },
  gridMainLayout: {
    display: "flex",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
  },
  gridsideBar: {
    transition: "all 0.3s",
    display: "flex",
    minHheight: "100%",
  },
  gridcontent: {
    width: "77%",
    flexGrow: 9,
    padding: "0 20px",
  },
  actionBtnStyle: {
    gap: 8,
    marginBlock: "40px",
    display: "flex",
    float: "right",
  },
  saveBtn: {
    backgroundColor: "rgba(9, 65, 115, 0.77)",
    color: "#ffffff"
  },
  discardBtn: {
    backgroundColor: "white"
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#E3E3E3"
  },
  buttonComponentSettings: {
    height: "100%",
    padding: "8px 8px",
    fontSize: "12px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#e1e1e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  availabilityPopper: {
    border: "1px solid #ccc",
    borderRadius: 4,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    marginTop: "4px",
    backgroundColor: "#ffffff",
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  popperItemBlue:{
    background:"#2A6395",
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "18px",
  },
  productCardBox: {
    display: "flex",
    gap: 40
  },
  activityIconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  graphpopper: {
    margin: "10",
    minWidth: "30px",
    height: "30px",
  },
  boldFont:{
    fontSize: "22px",
    fontWeight: 500,
    marginLeft:"5%",
  },
  dateBox: {
    width:"100%",
    justifyContent: "space-between",
    "& .MuiInputBase-input": {
      textAlign: "left",
      display:"block !important",
      width:"100%",
    },
    "& .MuiSvgIcon-root": {
      marginLeft: "auto",
      pointerEvents: "none",
    },
  },

};
// Customizable Area End
