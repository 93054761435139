import React from "react";
import StorageProvider from "../../../../framework/src/StorageProvider";
import { CircularProgress } from "@material-ui/core";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { toast } from 'react-toastify';
const configJSON1 = require('./config');
const config = require('../../../../framework/src/config.js');


export interface Props {
    navigation: any;
    id: string;  
    classes?: any;
  }
  export interface S {
    token: string;
    category: string;
    isEditing: boolean;
    loading: boolean;
  }
  export interface SS {
    // Customizable Area Start
    // Customizable Area End
  }
export class EditUnitOfMeasureController extends BlockComponent<
Props,
S,
SS
>{
// Customizable Area Start
  apigetUOMlistCallId!: string;
  apiDeleteCallId!: string;
  apiArchiveCallId!: string;
  apigetUOMGroupbylistCallId!: string;
  apigetUOMFilterlistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

    ];
    this.state = {
        token: "",
        category: "",
        isEditing: false,
        loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await this.fetchToken();
    this.fetchUoMData();
  }

  fetchToken = async () => {
    let token = await StorageProvider.get('token');
    if (!token) {
        token = localStorage.getItem('TOKEN');
    }
    this.setState({ token });
    return token;
};

fetchUoMData = async () => {
    const { token } = this.state;
    const id = this.props.navigation.getParam("id");
    this.setState({ loading: true });
    
    const header = {
        "Content-Type": "application/json",
        "token": token
    };
    
    try {
        const response = await fetch(`${config.baseURL}${configJSON1.viewUrl}${id}`, {
            method: 'GET',
            headers: header
        });
        const json = await response.json();
        const category = json?.data?.data?.attributes?.uom ?? '';
        this.setState({ category, loading: false });
    } catch (error) {
        console.error("Error fetching UoM data:", error);
        this.setState({ loading: false });
    }
    };

     checkUomValidation = () => {
        if (!this.state.category) {
            return false;
        }
        return true;
      }

    
     loaderBoxFnc = () => {
        if(this.state.loading){
          return 'loaderBox'
        }else{
          return 'display-none'
        }
      }
    
     loaderBoxContentFnc = () => {
        if(this.state.loading){
          return <CircularProgress />
        }else{
          return <></>
        }
      }

    saveUomCategories = async () => {
        const { category, token } = this.state;
        const id = this.props.navigation.getParam("id");
        if (!category) {
            return;
        }
        let formdata: any = new FormData();
        formdata.append("uom", category);

        try {
            const header = { "token": token };
            const response = await fetch(`${config.baseURL}${configJSON1.updateUrl}${id}`, {
                method: 'PATCH',
                headers: header,
                body: formdata
            });

            const json = await response.json();
            if (json.info) {
                this.props.navigation.navigate("UnitOfMeasurelistview");
                toast.success(json.message)
            }else{
                toast.error(json.errors[0])
            }
            this.setState({ loading: false });
        } catch (error) {
            console.error("Error saving UoM category:", error);
            this.setState({ loading: false });
        }
    };
}
