// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import StorageProvider from "../../../../framework/src/StorageProvider";
import Sidebar from '../../../../components/src/Sidebar.web';
import { addrow, validationerror, delrow } from '../../../cfcustomersalesreports3/src/assets';
const configJSON1 = require('./config');
const configJSONUtils =require('../config');
const config = require('../../../../framework/src/config.js');


// Customizable Area End


export const EditUoMCategories = (props: any) => {
    const [tableData, setTableData] = useState<any[]>([]);
    const [token, setToken] = useState("");
    const [category, setCategory] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [uomOptions, setUomOptions] = useState([]);
    const [loading, setLoading]=useState(false);
    const id = props.navigation.getParam("id");

    useEffect(() => {
        const fetchToken = async () => {
            const token = await StorageProvider.get('token');
            if (token)
                setToken(token);
            else {
                const token = localStorage.getItem('TOKEN');
                if (token)
                    setToken(token);
            }
            setLoading(false);
        }
        fetchToken();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const header = {
                    "Content-Type": "application/json",
                    "token": token
                };
                const response = await fetch(config.baseURL+`${configJSON1.viewUrl}${id}`, { method: 'GET', headers: header });
                const json = await response.json();
                if (json?.data?.data?.attributes) {
                    setTableData(json.data.data?.attributes?.unit_of_measures);
                    setCategory(json.data.data?.attributes?.category);
                    setLoading(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        const fetchUoMData = async () => {
            setLoading(true);
            try {
                const header = {
                    "Content-Type": "application/json",
                    "token": token
                };
                const response = await fetch(config.baseURL+`${configJSON1.uomUrl}`, { method: 'GET', headers: header });
                const json = await response.json();
                if (json?.data) {
                    setUomOptions(json?.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        if (token) {
            fetchData();
            fetchUoMData();
        }
    }, [token]);

    const expandMore = () => (
        <ExpandMore />
    )

    const getText = (data: any) => {
        return <Typography>{data}</Typography>
    }

    const getRatioText = (ratio: any) => {
        return <Typography>{ratio}</Typography>
    }

    const getInputRatio = (ratio: number, index: number, type: string) => {
        return <TextField data-test-id="uomCategoryInput"
            className={`form-input width-100 ${type == 'ratio' ? createuomwebStyle.ratioStyle : createuomwebStyle.roundingStyle}`}
            value={ratio}
            inputProps={{
                style: type == 'ratio' ? createuomwebStyle.ratioStyle : createuomwebStyle.roundingStyle
            }}
            onChange={(e: any) => handleMeasureUnitsDetails(e, index, type)}
            name="category" type="text" />
    }


    const uomOption = () => {
        return uomOptions?.map((ddl: any) => {
            return <MenuItem value={ddl?.attributes?.uom}>{ddl?.attributes?.uom}</MenuItem>
        })
    }

    const handleUomType = (newArr: any, val: any) => {
        if (newArr.filter((i: any) => i.uom_type == configJSONUtils.uomOption1).length == 1 && newArr.length > 1 && val == configJSONUtils.uomOption1) {
            window.alert("You can select this type only one time");
            return false;
        }
        return true;
    }

    const removeColumn = (index:any) => {
        if(tableData.length>1){
            const updatedTableData=tableData.filter((i:any,indx:number)=>indx != index)
            setTableData(updatedTableData);
        }else{
          window.alert("UoM category weight must have atleast one reference unit of measure.");
        }
        
      }

      const addAnotherColumn = () => {
        const updatedTableData: any[]=[...tableData];
        const obj={
            uom:"",
            uom_type:"",
            ratio:"",
            rounding_precision:"",
            active:true,
            archive:true
          };
        updatedTableData.push(obj);
        setTableData(updatedTableData);
      }

    const handleMeasureUnitsDetails = (e: any, index: number, field: string) => {
        const val = e.target?.value;
        let newArr = JSON.parse(JSON.stringify(tableData));
        const editArr: any = newArr.filter((i: any, indx: number) => indx == index)
        //console.log(editArr)
        if (!handleUomType(newArr, val)) {
            return;
        }
        if (editArr?.length > 0) {
            editArr[0][field] = val;
            if (val == configJSONUtils.uomOption1) {
                editArr[0]['ratio'] = 1.00000;
                editArr[0]['rounding_precision'] = 0.01000;
            } else if (editArr[0]['ratio'] == 1.00000 && editArr[0]['rounding_precision'] == 0.01000) {

                editArr[0]['ratio'] = "";
                editArr[0]['rounding_precision'] = "";

            }
        }
        setTableData(newArr);
    }

    const getRatioColumn = (uom_type: string, ratio: any, index: number, classes: any, p1: string, p2: string) => {
        let flabel: any = '';
        switch (uom_type) {
            case configJSONUtils.uomOption1: flabel = getRatioText(p1); break;
            case '': flabel = getRatioText(''); break;
            case 'Smaller than the reference Unit of Measure for this category':
            case 'Bigger than the reference Unit of Measure for this category':
                flabel = getInputRatio(ratio, index, p2);
                break;
            default:
                break;
        }
        return flabel;
    }

    const checkUomValidation = () => {
        if (!category) {
            return false;
        }
        else if(!tableData.filter((i)=>i.uom_type == configJSONUtils.uomOption1).length){
          window.alert("UoM category weight must have atleast one reference unit of measure.");
          return false;
        }
        return true;
      }

    const saveUomCategories = async () => {
        if (!checkUomValidation()) {
            return false;
        }
        setLoading(true);
        let formdata: any = new FormData();
        formdata.append("data[category]", category);
        formdata.append("data[unspsc_category]", null);
        tableData.length > 0 && tableData.forEach((item: any, index: number) => {
            // formdata.append("data[archive]", item?.archive);
            Object.keys(item).forEach((key) => {
                formdata.append(`data[unit_of_measures_attributes][${index}][${key}]`, item[key]);
            });
        })

        try {
            const header = {
                "token": token
            };
            const response = await fetch(config.baseURL+`/bx_block_productquickview/uom_categories/${id}`, { method: 'PATCH', headers: header, body: formdata });
            const json = await response.json();
            if (json?.data) {
                props.navigation.navigate("UomCategorieslistview")
            }
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    const loaderBoxFnc = () => {
        if(loading || tableData.length==0){
          return 'loaderBox'
        }else{
          return 'display-none'
        }
      }
    
    const loaderBoxContentFnc = () => {
        if(loading){
          return <CircularProgress />
        }else{
          return <></>
        }
      }



    return (
        // Customizable Area Start
        <>
            <Box data-test-id="token-display" style={createuomwebStyle.adduomMainLayout}>
                <Box style={createuomwebStyle.adduomsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box style={createuomwebStyle.adduomcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box style={createuomwebStyle.parentBox}>
                        <Box style={createuomwebStyle.adduomBlock}>
                            <Grid container spacing={0} >
                                <Grid item sm={12} className='left-center-align'>
                                    <Typography style={createuomwebStyle.adduomHeading}>{configJSON1.addUomHeading}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={`${loaderBoxFnc()}`}>
                                {loaderBoxContentFnc()}
                        </Box>
                        <Box style={{ padding: "15px 0" }} className={`${loaderBoxFnc()=='loaderBox' && 'display-none'}`}>
                            <Grid container spacing={0} >

                                <Grid item sm={12}>
                                    <Grid item sm={6}>
                                        <Box sx={{ padding: "0 15px" }}>
                                        {!isEditing ? <Typography >
                                                {configJSON1.uomCategory}
                                                <span className={'required'}>*</span>
                                                <span style={createuomwebStyle.separator}> | </span> {category}
                                            </Typography>
                                            : <><Typography className={`${!category && !loading?'error-text-pos':''}`}>
                                                {configJSON1.uomCategory}
                                                <span className={`${!category?'error-text-pos':'required'}`}>*</span>
                                            </Typography>
                                                    <Box className='relative'>
                                                    <TextField data-test-id="uomCategoryInput"
                                                        placeholder='Enter UoM Category'
                                                        className={`form-input width-100 ${!category && !loading ? 'error-focus': ''}`}
                                                        disabled={!isEditing}
                                                        value={category}
                                                        error={!category && !loading}
                                                        onChange={(e: any) => { setCategory(e.target.value); } }
                                                        name="category" type="text" />
                                                    <Typography className={`main-color bold-text`} style={createuomwebStyle.languageText}>{configJSON1.en}</Typography>
                                                    {!category && !loading && <Box className={`flex relative `}>
                                                        <img className='error-text-img' src={validationerror} />
                                                        <Typography className='error-text'>{configJSON1.categoryMessage}</Typography>
                                                    </Box>}
                                                </Box></> }

                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>

                                    </Grid>


                                </Grid>


                            </Grid>
                            <Grid container spacing={0} >
                                <Grid item sm={12} className='account_details' >
                                    <Typography className='main-color bold-text'>{configJSON1.addUomTh1}</Typography>
                                </Grid>
                                <Grid item sm={12} className='bank-grid' >
                                    <Grid item sm={3}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh2}</Typography>
                                    </Grid>
                                    <Grid item sm={4}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh3}</Typography>
                                    </Grid>
                                    <Grid item sm={2}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh4}</Typography>
                                    </Grid>
                                    <Grid item sm={2}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh5}</Typography>
                                    </Grid>
                                    <Grid item sm={1} className='end-center-align' data-test-id="add-row">
                                    {!isEditing ? <img aria-readonly src={addrow}/> :
                                        <img 
                                            onClick={addAnotherColumn} 
                                            src={addrow} /> }
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} style={{ padding: 0 }}  >
                                    <hr className='grid-hr' />
                                </Grid>
                                {
                                    tableData?.map((detail: any, index: number) => {
                                        return <>
                                            <Grid item sm={12} className='account-grid'  >
                                                <Grid item sm={3} className='pl-5 left-center-align'>
                                                    <FormControl id="select-form-control" className="width-100 account-tab-select">
                                                        {!isEditing ? getText(detail?.uom) :
                                                            <Select
                                                                IconComponent={expandMore}
                                                                data-test-id="uomInput"
                                                                inputProps={{
                                                                    'aria-label': 'custom select',
                                                                }}
                                                                value={detail.uom}
                                                                onChange={(e: any) => handleMeasureUnitsDetails(e, index, 'uom')}
                                                                label="Select"
                                                                disableUnderline
                                                                className="width-100"
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            marginTop: 52,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {
                                                                    uomOption()
                                                                }

                                                            </Select>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} className='left-center-align' >
                                                    <FormControl id="select-form-control" className="width-100 account-tab-select">
                                                        {!isEditing ? getText(detail?.uom_type) :
                                                            <Select
                                                                IconComponent={expandMore}
                                                                data-test-id="uomTypeInput"
                                                                inputProps={{
                                                                    'aria-label': 'custom select',
                                                                }}
                                                                value={detail.uom_type}
                                                                onChange={(e: any) => handleMeasureUnitsDetails(e, index, 'uom_type')}
                                                                label="Select"
                                                                disableUnderline
                                                                className="width-100 uom-type-select"
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            marginTop: 52,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="Reference Unit of Measure for this category">Reference Unit of Measure for this category</MenuItem>
                                                                <MenuItem value="Smaller than the reference Unit of Measure for this category">Smaller than the reference Unit of Measure for this category</MenuItem>
                                                                <MenuItem value="Bigger than the reference Unit of Measure for this category">Bigger than the reference Unit of Measure for this category</MenuItem>

                                                            </Select>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={2} className='left-center-align' >

                                                    {!isEditing ? getText(detail?.ratio) :

                                                        getRatioColumn(detail.uom_type, detail.ratio, index, null, '1.00000', 'ratio')
                                                    }
                                                </Grid>
                                                <Grid item sm={2} className='left-center-align' >
                                                    {!isEditing ? getText(detail?.rounding_precision) :
                                                        getRatioColumn(detail.uom_type, detail.rounding_precision, index, null, '0.01000', 'rounding_precision')
                                                    }
                                                </Grid>
                                                <Grid item sm={1} className='end-center-align delrow' data-test-id="del-row">
                                                {!isEditing ? <img aria-readonly src={delrow}/> :
                                                    <img
                                                        onClick={()=>removeColumn(index)} 
                                                        src={delrow} />}
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12} style={{ padding: 0 }}  >
                                                <hr className='grid-hr' />
                                            </Grid>
                                        </>
                                    })
                                }

                            </Grid>
                        </Box>
                    </Box>
                    <Box className='end-center-align' style={{ margin: "20px 0" }}>
                    { !isEditing ? 
                        <Link to="/AddUomCategories">
                        <Button style={createuomwebStyle.createBtn}  
                            className={`${loaderBoxFnc()=='loaderBox' && 'display-none'}`}
                            data-test-id="create-uom-category" variant="outlined">
                            {configJSONUtils.create}
                        </Button>
                    </Link> :
                        <Link to="/UomCategorieslistview">
                            <Button style={createuomwebStyle.discardBtn} data-test-id="discard-uom-category" variant="outlined">
                                {configJSONUtils.discard}
                            </Button>
                        </Link>
                    }
                        {!isEditing ? <Button onClick={() => setIsEditing(!isEditing)} data-test-id="edit-uom-category" variant="contained" style={{ backgroundColor: "#026494", color: "#ffffff" }}>{configJSON1.edit}</Button>
                            : <Button onClick={saveUomCategories} data-test-id="save-uom-category" variant="contained" style={{ backgroundColor: "#026494", color: "#ffffff" }}>{configJSONUtils.save}</Button>}

                    </Box>
                </Box>
            </Box>

        </>
        // Customizable Area End
    )

}


// Customizable Area Start
export const createuomwebStyle: any = {
    adduomMainLayout: {
        display: "flex",
    },
    adduomsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    adduomcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    adduomBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    parentBox: {
        border: "1px solid #e3e3e3",
        borderRadius: "5px",
        backgroundColor: "white"
    },
    adduomHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    pb10: {
        paddingBottom: "10px !important"
    },
    discardBtn: {
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    createBtn: {
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    languageText: {
        position: "absolute",
        right: 20,
        top: 20
    },
    ratioStyle: {
        paddingRight: "30px",
        marginLeft: "-5px",
    },

    roundingStyle: {
        paddingRight: "30px",
        marginLeft: "-10px",
    },
    separator: {
        marginLeft:  "10px",
        marginRight: "10px",
        color: "#BDBABA"
    }

}
export default withStyles(createuomwebStyle)(EditUoMCategories)
// Customizable Area End