// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import '../assets/style.css'
export const configJSON = require("./config");
import { add, canban, gridleftarrow, groupby, kanbanview, list, listview, printer, search, star } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
import { groupWhite } from '../../../components/src/assets';
import CompaniesController,{ Props } from './CompaniesController.web';
import { settingsGrey } from '../../itemavailability/src/assets';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class CompaniesListView extends CompaniesController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showGroup, showFav, showSettings, showCurrentSearch, showAddToDashboard, showPreview } = this.state;
        const { classes }: any = this.props;
        const searchPlaceholder = this.searchPlaceholderCmp() as string;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.companieslistviewMainLayout}>
                <Box className={`${classes.companieslistviewsideBar} ${this.state.defaultView && classes.sidebarMarginRight}`}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.companieslistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Settings" />
                    <Box style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.companieslistviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.companieslistviewHeading}>{configJSON.companies}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.companieslistviewSearchbox}>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${searchPlaceholder}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                        />
                                        <img src={search} className={`${classes.companieslistviewImg} ${this.searchIconDisplayCmp()}`} />
                                        
                                        
                                        <Box className={`${this.checkActiveGroup()}`} style={{position:"absolute",left:"15px",top:"12px"}}>
                                            <Box style={{position:"relative"}}>
                                            <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={configJSON.parentCompany} />
                                            <span className='handleGroupIcon' ><img src={groupWhite} /></span>
                                            <span data-testid="handleRemoves1" className='handleRemoves' onClick={()=>this.handleActiveGroupCmp('')} >×</span>
                                            </Box>
                                            
                                        </Box>
                                        
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.companieslistviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        
                                        <Box className={classes.companieslistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Groupby"
                                                onClick={this.handleGroup}
                                            ><img src={groupby} className={classes.companieslistviewFilterImg} />{configJSON.Groupby}</Button>
                                            
                                            {
                                                showGroup &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleGroup}
                                                    data-test-id="clickHandleGroup"
                                                >
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem className={`font-14 ${this.selectedGroupCmpListItem(configJSON.parentCompany)}`} button data-testid="list-salesPerson" onClick={() => { this.handleActiveGroupCmp(configJSON.parentCompany); }}>
                                                            <ListItemText  primary={configJSON.parentCompany} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Box className={classes.companieslistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleCmpFavorites} ><img src={star} className={classes.companieslistviewFilterImg} />{configJSON.Favorites}</Button>
                                            
                                            {
                                                showFav &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCmpFavorites}
                                                    data-test-id="clickHandleCmpFavorites"
                                                ><Box className="filterBox">
                                                    <List >
                                                        <ListItem button data-testid="list-currentSearch" className='listItem font-14 favorites-list' onClick={this.handleCmpCurrentSearch}>
                                                            <ListItemText primary={configJSON.saveCurrentSearch} />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14 favorites-list' data-testid="list-importRecords">
                                                            <ListItemText primary={configJSON.importRecords} />
                                                        </ListItem>
                                                        <ListItem button className='font-14 favorites-list'>
                                                            <ListItemText primary={configJSON.addToDashboard} data-testid="list-addDashboard" onClick={this.handleCmpAddToDashboard} />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                            {
                                                showCurrentSearch &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCmpCurrentSearch}
                                                    data-test-id="clickHandleCmpCurrentSearch"
                                                >
                                                <Box className="filterBoxCurrentSearch">
                                                <List className={classes.cmpgridviewlist} >
                                                    <ListItem button className='listItem font-14'>
                                                        <ListItemText primary={configJSON.companies} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.cmpgridviewButton} font-14`} style={{paddingBottom:0}}>
                                                        <ListItemIcon className={classes.cmpgridviewIcon} >
                                                            <Checkbox className={classes.cmpgridviewCheckbox} defaultChecked />
                                                        </ListItemIcon>
                                                        <ListItemText primary={configJSON.useByDefault} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.gridViewlistItem} font-14`} style={{paddingTop:0}}>
                                                        <ListItemIcon className={classes.cmpgridviewIcon}>
                                                            <Checkbox className={classes.cmpgridviewCheckbox} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={configJSON.shareWithAllUsers} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.cmpgridviewButtonlist} end-center-align font-14`} >
                                                        <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            {
                                                showAddToDashboard &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCmpAddToDashboard}
                                                    data-test-id="clickHandleCmpAddToDashboard"
                                                >
                                                <Box className="filterBoxAddToDashboard">
                                                <List className={`font-14 ${classes.cmpgridviewlist}`}>
                                                    <ListItem button style={{paddingBottom:0}} >
                                                        <TextField id="users-input" value={configJSON.companies}/>
                                                        
                                                    </ListItem>
                                                    <ListItem button className={`font-14 ${classes.cmpgridviewButtonlist} end-center-align`} style={{paddingTop:0}}  >
                                                    <Button className='saveBtn'>{configJSON.buttonSave}</Button>  
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.companieslistviewCreatebox} >
                                    <Box className={classes.companieslistviewColumnbox}>
                                            <Button className='filters-left button-icon' data-testid="list-printer" onClick={this.handleCmpPreview}><img src={printer} /></Button>
                                            
                                            {
                                                showPreview &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCmpPreview}
                                                    data-test-id="clickHandleCmpPreview"
                                                >
                                                <Box className="settingsBox settingsBoxCompany">
                                                    <List>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary={configJSON.previewInternalReport} />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary={configJSON.previewExternalReport} />
                                                        </ListItem>
                                                        <ListItem button className='font-14'>
                                                            <ListItemText primary={configJSON.reportLayoutPreview} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Box className={classes.companieslistviewColumnbox}>
                                            <Button style={this.displaySettingStyle()} className='filters-left button-icon' data-testid="list-settings" onClick={this.handleCmpSettings}><img src={settingsGrey} /></Button>
                                            
                                            {
                                                showSettings &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCmpSettings}
                                                    data-test-id="clickHandleCmpSettings"
                                                >
                                                <Box className="settingsBox settingsBoxCompany">
                                                    <List>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary={configJSON.export} />
                                                        </ListItem>
                                                        <ListItem button className='font-14'>
                                                            <ListItemText primary={configJSON.delete} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Button className={this.state.defaultView ? `filters-left-active button-icon` : `filters-left button-icon`} data-testid="navigatetoListview" onClick={this.navigatetoListview}><img src={!this.state.defaultView ? listview : list}/></Button>
                                        <Button className={!this.state.defaultView ? `filters-left-active button-icon` : `filters-left button-icon`} data-testid="navigatetoGridview" 
                                        onClick={this.navigatetoGridview}
                                        ><img src={!this.state.defaultView ? kanbanview : canban}  /></Button>
                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoCreateCompanies}>{configJSON.createCompanies}<img src={add} /></Button>
                                    </Grid>
                                </Grid>

                                <Box className={`${this.loaderBoxCmp()}`}>
                                {
                                this.loaderBoxContentCmp()
                                }
                                </Box>
                                
                                <Box className={`${this.listViewCmp()}`}>
                                <Box className={classes.companieslistviewColumnbox} >
                                    
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                    <Checkbox checked={this.state.globalCheck} 
                                                    onChange={()=>this.handleGlobalCheckCompany()} 
                                                    className={classes.companieslistviewCheck}
                                                    data-test-id="globalCheckbox" />
                                                    </TableCell>
                                                    <TableCell className={classes.companieslistviewTableheading}>{configJSON.companyName}</TableCell>
                                                    <TableCell className={classes.companieslistviewTableheading}>{configJSON.partner}</TableCell>
                                                    <TableCell className={classes.companieslistviewTableheading}>{configJSON.email}</TableCell>
                                                    <TableCell className={classes.companieslistviewTableheading}>{configJSON.phone}</TableCell>
                                                    
                                                    
                                                </TableRow>
                                            </TableHead>
                                            {
                                                this.state.activeGroup && this.state.groupbyResponse.map((item:any,index:number)=>{
                                                    return <>
                                                    {item.companies.data.map((user:any,index1:number) =>{
                                                    return <>
                                                    
                                                    <TableBody>
                                                    {
                                                        this.handleGroupIndexZeroCmp(item,index1,index)
                                                    }
                                                    { this.state.active.includes(index) &&
                                                    this.expandTableCmpRow(user,classes)
                                                    }
                                                    </TableBody>
                                                    
                                                  </>
                                                  })}
                                                    </>
                                                })
                                            }
                                            {(!this.state.activeGroup) && this.state.showCustomerdata?.map((user:any) => {
                                                return (
                                                    <TableBody>
                                                        {
                                                            this.expandTableCmpRow(user,classes)
                                                        }
                                                        </TableBody>
                                                        )
                                            })} 
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={`${classes.companieslistviewItems} ${this.searchIconDisplayCmp()}`}>
                                    <Grid container spacing={0} >
                                        <Grid item sm={8} className={classes.companieslistviewItemsgrid}>
                                            <Typography className={classes.companieslistviewItemsText}>{configJSON.itemsPage}</Typography>
                                            <FormControl id="select-form-control">

                                                <Select
                                                    IconComponent={this.expandMore}
                                                    inputProps={{
                                                        'aria-label': 'custom select',
                                                    }}
                                                    data-testid="select_page"
                                                    id="item-select"
                                                    value={this.state.per_page}
                                                    onChange={(e:any)=>this.handlePaginationCmp(e.target.value)}
                                                    label="Select"
                                                    disableUnderline
                                                    className='bold-text'
                                                >
                                                    
                                                    <MenuItem value={9}>09</MenuItem>
                                                    <MenuItem value={27}>27</MenuItem>
                                                    <MenuItem value={54}>54</MenuItem>
                                                    <MenuItem value={99}>99</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} className='end-center-align'>
                                            <Typography className={classes.companieslistviewText} >
                                                <span className='bold-text'>
                                                {(this.state.page - 1) * this.state.per_page + 1}
                                                &nbsp;-&nbsp;
                                                {(this.state.page - 1) * this.state.per_page +
                                                    this.state.showCustomerdata.length}
                                                </span>
                                                <span className={classes.bottompage}>
                                                    &nbsp;of &nbsp;
                                                {this.state.totalCounts}
                                                </span>
                                            </Typography>
                                            <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMoreRecordsCmp(0,'')} className='tb-footer-btn'><img src={this.movebackwardCmp()} /></Button>
                                            <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMoreRecordsCmp(-1,'')} className='tb-footer-btn'><img src={this.backarrowCmp()} /></Button>
                                            <Button data-testid="listviewForwardArrow" disabled={this.isPageLastCompany()} onClick={()=>this.loadMoreRecordsCmp(+1,'')} className='tb-footer-btn'><img src={this.forwardarrowCmp()} /></Button>
                                            <Button data-testid="listviewForwardIcon" disabled={this.isPageLastCompany()} onClick={()=>this.loadMoreRecordsCmp(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.moveforwardCmp()} /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                </Box>
                                
                                <Box className={`${this.kanbanViewCmp()}`}>
                                <Box className={classes.companieslistgridviewbox} >
                                    <Grid container spacing={3} >
                                        {
                                            this.state.showCustomerdata?.map((company:any) => {
                                                const {email,phone} = company?.attributes?.company_general_infos
                                                return <>
                                                    <Grid item lg={4}  >
                                                        
                                                        <Box className={classes.companieslistgridviewFilterbox}  >
                                                        <Box sx={{borderBottom:"1px solid #e3e3e3"}}>
                                                        <Typography className={`${classes.companieslistgridListsubtext3} ${classes.companieslistgridListsubtext}`} >{company?.attributes?.company_name}</Typography>
                                                        </Box>
                                                        
                                                            <Grid container spacing={0} className={classes.companieslistgridviewbox}  >
                                                            
                                                                <Grid item lg={12} className={classes.companieslistgridviewlistimg}  >
                                                                    
                                                                    <Typography className={`${classes.companieslistgridListsubtext1} ${classes.companieslistgridListsubtext}`} >Email:  {email}</Typography>
                                                                    <Typography className={`${classes.companieslistgridListsubtext2} ${classes.companieslistgridListsubtext}`} >Phone:  {phone}</Typography>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                            
                                                        </Box>
                                                        
                                                    </Grid>
                                                    
                                                </>
                                            })
                                        }
                                    </Grid>
                                </Box>
                                
                                    <Box className={`${classes.companieslistgridviewbox} ${this.hideLoadMore()}`} style={{textAlign:"center"}} >
                                    <Button style={{backgroundColor:"rgb(243, 243, 243)"}} data-testid="kanbanLoadMore" onClick={()=>this.loadMoreRecordsCmp(+1,'',true)}>Load More Records</Button>
                                    </Box>
                                
                                
                                </Box>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const companieslistwebStyle: any = (theme: any) => ({
    companieslistviewMainLayout: {
        display: "flex",
    },
    companieslistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
        
    },
    sidebarMarginRight:{
        marginRight:"-40px"
    },
    companieslistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    companieslistviewBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3"
    },
    companieslistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    companieslistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    companieslistviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    companieslistviewFilterImg: {
        paddingRight: "8px"
    },
    companieslistviewColumnbox: {
        position: "relative"
    },
    companieslistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    companieslistviewCheck: {
        
    },
    bottompage: {
        color:"#47464a"
    },
    companieslistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    companieslistviewItems: {
        padding: "15px"
    },
    companieslistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    companieslistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    companieslistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    companieslistviewItemsText: {
        paddingRight: "10px",
        marginTop: "18px",
        color:"#47464a"
    },
    companieslistviewText: {
        marginRight: "50px"
    },
    companieslistgridviewbox: {
        padding: "15px"
    },
    companieslistgridviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    companieslistgridviewlistimg: {
        padding: "10px 10px 10px 0"
    },
    companieslistgridListimg: {
        margin: "-5px 5px 0 0",
        float: "right"
    },
    companieslistgridListimg2: {
        margin: "15px -20px 0 0",
        float: "right"
    },
    companieslistgridListtext: {
        fontSize: "20px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    companieslistgridListsubtext: {
        fontSize: "16px",
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal"
    },
    companieslistgridListsubtext3: {
        marginLeft:"5px",
        padding:"20px 15px 20px 20px"
    },
    companieslistgridListsubtext1: {
        marginLeft:"5px",
        padding:"0 0 10px 0"
    },
    companieslistgridListsubtext2: {
        marginLeft:"5px",
        padding:"10px 0"
    },
})
export const CompaniesListViewStyles = withStyles(companieslistwebStyle)(CompaniesListView)
  // Customizable Area End
