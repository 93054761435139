import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

export type token = string | null;
const Images = require("./assets");


interface POSOrderResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_number: string;
    account_id: number;
    status: string;
    product_ids: number[];
    receipt_number: string;
    order_date: string;
    session: {
      id: number;
      opening_date: string;
      closing_date: string | null;
      point_of_sale_id: number;
      account_id: number;
      status: string;
      session_id: string;
      point_of_sale_name: string;
      opening_cash: string;
      notes: string;
      opened_by: string;
      created_at: string;
      updated_at: string;
      cash_in: string | null;
      cash_out: string;
      cash_in_out_notes: string;
    };
    total_amount: string;
    products: {
      id: string;
      type: string;
      attributes: {
        product_name: string;
        product_image: {
          id: number;
          url: string;
        } | null;
        can_be_sold: boolean;
        can_be_purchased: boolean;
        account_id: number;
        general_info: {
          id: number;
          product_type: string;
          invoice_policy: string;
          unit_of_measure: string | null;
          purchased_uom: string;
          product_code: string;
          sales_price: number;
          customer_tax: string[];
          product_category: string;
          internal_notes: string;
          cost: number;
          account_id: number;
          created_at: string;
          updated_at: string;
          product_id: number;
        };
        accounting: {
          id: number;
          income_account: string;
          expense_account: string;
          price_difference_account: string;
          commodity_code: string;
          country_of_origin: string;
          automatic_email_at_invoice: string;
          account_id: number;
          created_at: string;
          updated_at: string;
          product_id: number;
        };
        purchases: {
          id: number;
          vendor_tax: string;
          control_policy: boolean;
          purchase_description: string;
          product_purchases: {
            id: number;
            vendor: string;
            subcontracted: string;
            product_variant: string;
            currency: string;
            qty: string;
            uom: string;
            price: string;
            delivery_time_lead: string;
            forecasted_qty: string;
            purchase_id: number;
          }[];
        };
        inventory: {
          id: number;
          responsible: string;
          weight: number;
          volume: number;
          manuf_lead_time: string;
          customer_lead_time: string;
          hs_code: string;
          tracking: boolean;
          receipts_description: string;
          delivery_orders_description: string;
          internal_transfer_description: string;
          account_id: number;
          created_at: string;
          updated_at: string;
          product_id: number;
        };
        product_category: {
          id: number;
          category: string;
          parent_category: string;
          stock_valuation_account: string;
          stock_journal: string;
          stock_input_account: string;
          stock_output_account: string;
          costing_method: string;
          inventory_valuation: string;
          price_difference_account: string;
          expense_account: string;
          product_category: string;
          account_id: number;
          product_id: number;
          created_at: string;
          updated_at: string;
        };
        sale: {
          id: number;
          alternative_products: string[];
          optional_products: string[];
          accessory_products: string[];
          available_in_POS: boolean;
          to_weigh_with_scale: boolean;
        };
        extra_product_media: null;
      };
    }[];
    account: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        email: string;
        full_phone_number: string | null;
        phone_number: string | null;
        type: string | null;
        first_name: string;
        state: string | null;
        city: string | null;
        postal_code: string | null;
        country: string | null;
        title: string | null;
        job_position: string | null;
        tags: string[];
        website: string | null;
        language: string | null;
        company_name: string | null;
        customer_type: string | null;
        user_type: string;
        address: string | null;
        created_at: string;
        updated_at: string;
        device_id: string | null;
        unique_auth_id: string;
        platform: string | null;
      };
    };
    payment: {
      id: string;
      type: string;
      attributes: {
        total_amount: string;
        remaining_amount: string;
        change_amount: string;
        payment_method: string;
        account_id: number;
        payment_status: string;
        customer: string;
      };
    };
  };
  meta: {
    total_pages: number;
    total_counts: number;
  };
}

interface POSOrderGroup {
  key: string;
  orders: {
    data: POSOrderResponse[];
  };
}


export interface PosOrderInfo {
  ordId: string;
  type: string;
  checked: boolean;
  order_number:string;
  session_id: string;
  order_date: string;
  receipt_number: string;
  customer: string;
  total_amount: string;
  status: string;
  customer_first_name:string
}

export interface PayloadType {
  key: string;
  data: PosOrderInfo[]; 
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isViewTypeChanged: boolean;
  anchorElement: HTMLElement | null;
  selectedFilter: string;
  anchorEl: HTMLElement | null;
  isAllChecked: boolean;
  isChecked: boolean;
  token: token;
  responseData:PosOrderInfo[] | PayloadType[];
  currentPage: number;
  rowsPerPage: number;
  totalCounts: number;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  dataLength: number;
  query: string;
  anchorElFilter: HTMLElement | null;
  anchorElGroupBy: HTMLElement | null;
  selectedItems: {[name: string]: boolean;};
  params: { [name: string]: boolean | string };
  isGroupby: boolean;
  isLoading: boolean;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  isExpanded:boolean[];
  isLoadMore: boolean;
  initialLoadComplete: boolean;
  remainingItems: number;
  groupbyOrderDateAnchor: HTMLElement | null;
  groupbySelectedQuery:string,
  filterOrderDateAnchor: HTMLElement | null;
  filterSelectedYear:number,
  filterSelectedQuarter:string,
  filterSelectedMonth:string,
  isYearSelected:boolean,
  isQuarterSelected:boolean,
  filterMonthOption:string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PosOrderListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPOSOrderListing: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isViewTypeChanged: true,
      anchorElement: null,
      selectedFilter: "",
      anchorEl: null,
      isAllChecked: false,
      isChecked: false,
      token: "",
      responseData:[],
      currentPage: 1,
      rowsPerPage: 9,
      totalCounts: 0,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      dataLength: 0,
      query: "",
      anchorElFilter: null,
      anchorElGroupBy: null,
      selectedItems: {},
      params: {},
      isGroupby: false,
      isLoading: false,
      queryItems: [],
      isExpanded:[],
      isLoadMore: false,
      initialLoadComplete: false,
      remainingItems: 0,
      groupbyOrderDateAnchor: null,
      groupbySelectedQuery:"",
      filterOrderDateAnchor:null,
      filterSelectedYear:0,
      filterSelectedQuarter:"",
      filterSelectedMonth:"",
      isYearSelected:false,
      isQuarterSelected:false,
      filterMonthOption:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {this.props.navigation.navigate("EmailAccountLoginStyles")}
    else {this.posListingData();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchString = ""
      if (this.state.query.length >= 3) {
        searchString = `&search=${this.state.query}`
      }
      const { currentPage, rowsPerPage } = this.state;
      const params = `page=${currentPage}&per_page=${rowsPerPage}${searchString}`
      this.getPOSOrderListing = this.handleApiCall(token,
        `/bx_block_order_management/orders?${params}`, "GET"
      );
    }
    else if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.queryItems !== this.state.queryItems) {
      this.posListingData()
    }
  }

  groupByOptions = [
    { name: "Session", selKey: "session" },
    { name: "Cashier", selKey: "cashier" },
    { name: "Customer", selKey: "customer" },
    { name: "Status", selKey: "status" },
  ];

  filterOptions = [
    { name: "Invoice", selKey: "Invoiced" },
    { name: "Posted", selKey: "Posted" },
    { name: "Paid", selKey: "Paid" },
  ];

  groupbyFormatOptions = [
    { name: "Year", selKey: "year" },
    { name: "Quarter", selKey: "quater" },
    { name: "Month", selKey: "month" },
    { name: "Week", selKey: "week" },
    { name: "Day", selKey: "day" },
  ];

  quarterToMonths = [
    {name : "Q1" , selKey: ['January', 'February', 'March']},
    {name : "Q2" , selKey: ['April', 'May', 'June']},
    {name : "Q3" , selKey: ['July', 'August', 'September']},
    {name : "Q4" , selKey: ['October', 'November', 'December']},
  ];

  monthsWithKey = [
    { monthName: 'January', monthValue: '01' },
    { monthName: 'February', monthValue: '02' },
    { monthName: 'March', monthValue: '03' },
    { monthName: 'April', monthValue: '04' },
    { monthName: 'May', monthValue: '05' },
    { monthName: 'June', monthValue: '06' },
    { monthName: 'July', monthValue: '07' },
    { monthName: 'August', monthValue: '08' },
    { monthName: 'September', monthValue: '09' },
    { monthName: 'October', monthValue: '10' },
    { monthName: 'November', monthValue: '11' },
    { monthName: 'December', monthValue: '12' }
  ];
  

  filterQuarterOptions = [
    { name: "Q4", selKey: "04" },
    { name: "Q3", selKey: "03" },
    { name: "Q2", selKey: "02" },
    { name: "Q1", selKey: "01" },
  ];

  filterOrderYearOptions = [
    2024,
    2023,
    2022,
  ];

  SettingOptions = [
    { name: "Export" },
    { name: "Archive" },
    { name: "Unarchive" },
    { name: "Delete" },
  ];

  tableHeading = [
    "Order Ref.",
    "Session",
    "Date",
    "Receipt Number",
    "Customer",
    "Total Amount",
    "Status"
  ];

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {data: POSOrderResponse[] | POSOrderGroup[]
      meta: { total_pages: number; 
        total_counts: number 
      };
    }
  ) => {
    if (apiRequestCallId === this.getPOSOrderListing) {
      this.handleGetItemResponse(responseJson.data,
        responseJson.meta.total_pages,responseJson.meta.total_counts
      );
    }
  };


  handleGetItemResponse = (
    data: POSOrderResponse[] | POSOrderGroup[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby ?
      this.getCollapseTableKey(data as POSOrderGroup[]) : this.getTableKey(data as POSOrderResponse[]);
    this.setState({
      responseData: tableInfo,
      totalPage, totalCounts,
      isLoading: false,
      isLoadMore: false,
    })
  };


  getTableKey = (data: POSOrderResponse[]) => {
    const requiredData: PosOrderInfo[] = [];
    data.forEach((item: POSOrderResponse) => {
      const attributes = item.attributes;
      const type = item.type;
      const ordId = item.id;
      const posInfo: PosOrderInfo = {
        ordId,
        type,
        checked: false,
        customer_first_name:attributes.account.attributes.first_name || "",
        order_number: attributes.order_number || "",
        session_id: attributes?.session?.session_id || "",
        order_date: attributes.order_date || "",
        receipt_number: attributes.receipt_number || "",
        customer: attributes?.payment?.attributes.customer || "",
        total_amount:attributes.total_amount || "",
        status:attributes.status || "",
      };
      requiredData.push(posInfo);
    })
    return requiredData;
  }

  getCollapseTableKey = (data: POSOrderGroup[]) => {
    const requiredData = data?.map((item: POSOrderGroup) => {
      let payload : PayloadType = {
        "key": item.key,
        data: []
      }
      payload.data = this.getTableKey(item?.orders.data)
      return payload
    })
    return requiredData;
  }

  renderConditional(
    condition: boolean, 
    component1: React.ReactNode, 
    component2: React.ReactNode
  ): React.ReactNode {
    return condition ? component1 : component2;
  }

  renderCheck(
    condition: boolean, 
    component1: React.ReactNode, 
  ): React.ReactNode {
    return condition && component1;
  }

  getStyle(condition: boolean, selectedStyle: React.CSSProperties, unselectedStyle:  React.CSSProperties):  React.CSSProperties {
    return condition ? selectedStyle : unselectedStyle;
  }

  getClassName(condition: boolean, selectedClass: string, unselectedClass:  string):  string {
    return condition ? selectedClass : unselectedClass;
  }
  
  
  handleSettingsClickAway = () => {
    this.setState({ 
      anchorEl: null 
    });
  };

  handleFilterClickAway = () => {
    this.setState({ 
      anchorElFilter: null 
    });
  };
  
  handleGroupClickAway = () => {
    this.setState({ 
      anchorElGroupBy: null 
    });
  };


   handleDateAndTime = (givenDate:string) => {
    const originalDate = new Date(givenDate);
    if (isNaN(originalDate.getTime())) {
      return "";
    }
    const formattedDate = originalDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const formattedTime = originalDate.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    return `${formattedDate} ${formattedTime}`;
  };
  

  handleColor=(status:string)=>{
    if(status === 'Paid'){
      return "#2C6897"
    }
    else if(status === 'Posted'){
      return "#E1AB5F"
    }
    else if (status === 'Invoiced'){
      return "#4CAB8F"
    } 
  }

  isCurrentPageOne(): boolean {
    const {isGroupby} =this.state;
    if (!isGroupby) {
      return this.state.currentPage === 1;
    }
    return true;
  }

  isCurrentPageLast(): boolean {
    const {isGroupby ,responseData} =this.state;
    if (!responseData.length) {
      return true;
    }
    if (!isGroupby) {
      return this.state.currentPage === this.state.totalPage;
    }
    return true;
  }

  renderNoDataContainer = () => {
    return (
      <Box className={this.props.classes.emptyData}>
        <Typography>No Data Found</Typography>
      </Box>
    );
  };

  isViewActive = (
    isViewTypeChanged: boolean, responseData: PayloadType[] | PosOrderInfo[]
  ) => this.renderConditional(
    isViewTypeChanged,
    <Box sx={{ overflow: "auto" }}>
      <TableContainer >
        {this.renderConditional(
          responseData.length > 0,
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    edge="start"
                    checked={this.state.isAllChecked}
                    data-test-id="allCheckBox"
                    tabIndex={-1}
                    onChange={this.handleAllChecked}
                    disabled={responseData.length <= 0}
                  />
                </TableCell>
                {this.tableHeading.map((item, index) => (
                  <TableCell key={index} style={{ textTransform: "uppercase" }}>
                    <b style={{ whiteSpace: "nowrap" }}>{item}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {this.renderListAndGroupbyTable(responseData)}
          </Table>,
          this.renderNoDataContainer()
        )}
      </TableContainer>
      {
        !this.state.isGroupby && this.renderPagination()
      }
    </Box>,
    <Box>
      {this.renderKanbanView(responseData as PosOrderInfo[])}
      {
        this.renderCheck((((this.state.totalCounts - this.state.rowsPerPage) > 0) || !this.state.initialLoadComplete),this.renderLoadMore())
      }
    </Box>
  )

  renderKanbanView = (responseData: PosOrderInfo[]) => {
    return (
      this.renderConditional(
        responseData.length>0,
        (<Grid container
          spacing={2}
          style={{ padding: "0 15px 10px 15px" }}
        >
          {responseData.map((tablerow: PosOrderInfo) => (
            <Grid item
              xs={4}
              key={tablerow.ordId}
            >
              <Card className={this.props.classes.productCard}>
                <CardContent>
                  <Grid container xl={12} lg={12} md={6} sm={6}>
                    <Grid item lg={7}><Box>
                      <Typography component="h6" variant="body1" className={this.props.classes.productDescriptionHeading}>
                        {tablerow.order_number || ""}
                      </Typography>
                      <Typography variant="body2" className={this.props.classes.productDescription}>
                        {tablerow.session_id || ""}
                      </Typography>
                      <Typography variant="body2" className={this.props.classes.productDescription} style={{marginTop: "2px" }}>
                        {this.handleDateAndTime(tablerow.order_date)}
                      </Typography></Box>
                    </Grid>
                    <Grid item lg={5} >
                      <Box px={2}
                        className={this.props.classes.canbanRightBox}
                      >
                        <Typography component="h6" variant="body1" className={this.props.classes.productDescriptionHeading}>
                          {tablerow.total_amount} OMR </Typography>
                        <Typography component="h6" variant="body2" style={{ color: this.handleColor(tablerow.status), fontWeight: 600 }}>
                          {tablerow.status}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
          )}</Grid>
        ),
        (
          this.renderNoDataContainer
        )
      )
    )
  }

  renderPagination = () => {
    const {isLoading ,currentPage ,rowsPerPage,totalCounts,responseData} = this.state;
    return (
      <Box className={this.props.classes.paginationContainer} >
        <Box className={this.props.classes.perPageItem}>
          <Typography>Items per page : &nbsp;</Typography>
          <Select
            value={this.state.rowsPerPage}
            variant="outlined"
            data-test-id="dropdown"
            className={this.props.classes.navinationDropdown}
            onChange={this.handleChangeRowsPerPage}
            IconComponent={KeyboardArrowDownSharpIcon}
          >
            <MenuItem value={9}>
              09
            </MenuItem>
            <MenuItem
              value={27}
            >
              27
            </MenuItem>
            <MenuItem
              value={54}
            >
              54
            </MenuItem>
            <MenuItem
              value={99}
            >
              99
            </MenuItem>
          </Select>
        </Box>
        <Box className={this.props.classes.paginationText}>
        
          <Box className={isLoading ? this.props.classes.paginationCountBoxHidden : this.props.classes.paginationCountBoxShow}>
            <Typography className={this.props.classes.rowText}>
              {(currentPage - 1) * rowsPerPage + 1}
              &nbsp;-&nbsp;
              {(currentPage - 1) * rowsPerPage +
                responseData.length}
            </Typography>
            <Typography>
              &nbsp;of &nbsp;
              {totalCounts}
            </Typography>
          </Box>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-1"
            className={this.props.classes.paginationButton}
            onClick={() => this.handleChangePage(0)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.moveBackwardArrowDisable
                  : Images.moveBackwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-2"
            className={this.props.classes.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage - 2)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.backArrowDisable
                  : Images.backArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-1"
            className={this.props.classes.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.forwardArrowDisable
                  : Images.forwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-2"
            className={this.props.classes.paginationButton}
            onClick={() => this.handleChangePage(this.state.totalPage - 1)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.moveForwardArrowDisable
                  : Images.moveForwardArrowEnable
              }
            />
          </Button>
        </Box>
      </Box>
    );
  };

  renderLoadMore = () => {
    return (<>
      {this.renderCheck(this.state.isLoadMore ,
        <Box className={this.props.classes.loaderContainer} data-test-id="loadmore">
        <TableRow>
          <TableCell colSpan={10}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "20vh", width: "100%" }}>
              <CircularProgress style={{ color: "#2A6395" }} />
            </Box>
          </TableCell>
        </TableRow>
      </Box>)}
      {this.state.responseData.length>19 && (<Box className={this.props.classes.loadMoreContainer} data-test-id="loadmore2">
        <Button variant="contained" data-test-id="loadMore-btn" className={this.props.classes.gridviewLoadmoreButton} onClick={this.handleLoadMore}>
          Load More Records
        </Button>
      </Box>)}
    </>
    )
  }

  expandableTable = (data: PayloadType[]) => {
    return (
      <TableBody>
        {data?.map((rowItem: PayloadType, indice: number) => (
          <React.Fragment
            key={rowItem.key}
          >
            <TableRow
              style={{ backgroundColor: '#E5E5E5' }}
            >
              <TableCell
                padding="checkbox">
                <IconButton
                  data-test-id="iconButton"
                  onClick={() => this.toggleIsExpanded(indice)}>
                  {this.state.isExpanded[indice] ?
                    <KeyboardArrowUpIcon /> :
                    <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" style={{ fontWeight: 700 ,whiteSpace: "nowrap"}}>{rowItem.key} ({rowItem.data?.length})</TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
            {this.state.isExpanded[indice] &&
              rowItem.data?.map((tablerow: PosOrderInfo, index: number) => (
                <TableRow key={index} className={this.props.classes.tableRow} data-test-id="data-row"><TableCell>
              <Box className={this.props.classes.tableBox}>
                <Checkbox
                  checked={tablerow.checked}
                  tabIndex={-1}
                  edge="start"
                  size="medium"
                  data-test-id="rowCheckbox"
                  className="individual"
                  onClick={(event) => {event.stopPropagation();
                    this.handleCheckBoxSelect(tablerow, index);
                  }}
                /></Box>
            </TableCell>
            <TableCell>{tablerow.order_number}
              </TableCell>
              <TableCell>{tablerow.session_id}
              </TableCell>
              <TableCell>
              {this.handleDateAndTime(tablerow.order_date)}</TableCell>
              <TableCell>{tablerow.receipt_number}
              </TableCell>
              <TableCell>{tablerow.customer}
              </TableCell>
              <TableCell>{tablerow.total_amount}
              </TableCell>
              <TableCell style={{ color: this.handleColor(tablerow.status) , fontWeight:600}}>{tablerow.status}
              </TableCell>
            </TableRow>)
              )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  };

  renderListView = (responseData: PosOrderInfo[]) => {
    return (
      <TableBody>
        {this.renderConditional(
          this.state.isLoading,
          <TableRow><TableCell colSpan={10}><Box className={this.props.classes.loader} ><CircularProgress /></Box></TableCell></TableRow>,
          responseData.map((tablerow: PosOrderInfo, index: number) => (
            <TableRow key={index} className={this.props.classes.tableRow} data-test-id="data-row" 
            ><TableCell>
              <Box className={this.props.classes.tableBox}>
                <Checkbox
                  tabIndex={-1}
                  checked={tablerow.checked}
                  className="individual"
                  size="medium"
                  data-test-id="rowCheckbox"
                  edge="start"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCheckBoxSelect(tablerow, index);
                  }}
                />
              </Box>
            </TableCell>
              <TableCell>{tablerow.order_number}
              </TableCell>
              <TableCell>{tablerow.session_id}
              </TableCell>
              <TableCell>
              {this.handleDateAndTime(tablerow.order_date)}</TableCell>
              <TableCell>{tablerow.receipt_number}
              </TableCell>
              <TableCell>
                {tablerow.customer_first_name}
              </TableCell>
              <TableCell>{tablerow.total_amount}
              </TableCell>
              <TableCell style={{ color: this.handleColor(tablerow.status) , fontWeight:600}}>{tablerow.status}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    )
  }

  renderGroupedTable = (responseData: PayloadType[] | PosOrderInfo[]) => {
    return this.renderConditional(
      this.state.isGroupby,
      this.expandableTable(responseData as PayloadType[]),
      this.renderListView(responseData as PosOrderInfo[])
    );
  };

  renderListAndGroupbyTable = (responseData: PayloadType[] | PosOrderInfo[]) => {
    return (
      this.renderConditional(
        this.state.isLoading,
        <TableRow><TableCell colSpan={10}>
          <Box className={this.props.classes.loader}><CircularProgress /></Box>
        </TableCell></TableRow>,
        this.renderGroupedTable(responseData)
      )
    );
  };

  handleClickGroupBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      groupbyOrderDateAnchor:null,
      anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,
    });
  };

  handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      filterOrderDateAnchor:null,
      anchorElFilter: this.state.anchorElFilter ? null : event.currentTarget,
    });
  };

  handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget,});
  };

  handleClickGroupbyOrder = (event: React.MouseEvent<HTMLLIElement>) => {
    this.setState({groupbyOrderDateAnchor: this.state.groupbyOrderDateAnchor ? null : event.currentTarget,isViewTypeChanged: true,});
  };

  handleClickFilterOrder = (event: React.MouseEvent<HTMLLIElement>) => {
    this.setState({filterOrderDateAnchor: this.state.filterOrderDateAnchor ? null : event.currentTarget,});
  };

  handleGroupBySelect = (item: { name: string; selKey: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState((prevState) => { const updatedQueryItems = [...prevState.queryItems];
      const existingIndex = updatedQueryItems.findIndex((queryItem) => queryItem.type === "groupby");
      if (existingIndex !== -1) {updatedQueryItems[existingIndex] = {id: item.selKey,tag: item.name,key: item.selKey,type: "groupby", };} 
      else {updatedQueryItems.push({id: item.selKey,tag: item.name,key: item.selKey,type: "groupby",});}
      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isAllChecked: false,
      anchorElGroupBy: null,
      isGroupby: true,
      selectedItems: {}
    })
  };

  handleChangePage = (pageNo: number) => {
    this.setState({currentPage: pageNo + 1,});
    if (this.state.currentPage > pageNo) {this.setState((prev) => ({row: {from: prev.row.from - this.state.rowsPerPage,end: prev.row.end - this.state.rowsPerPage,},}));} 
    else {this.setState((prev) => ({row: {from: prev.row.from + this.state.rowsPerPage,end: prev.row.end + this.state.rowsPerPage,},}));}
    this.setState({isAllChecked: false,})
  };

  handleLoadMore = () => {
    const { totalCounts, rowsPerPage, remainingItems, initialLoadComplete } = this.state;
    const leftOver = totalCounts - rowsPerPage;
    const newRowsPerPage = rowsPerPage + 18;
    this.setState({ remainingItems: leftOver, isLoadMore: true, rowsPerPage: newRowsPerPage })

    if (!initialLoadComplete || (leftOver > 0 && remainingItems > 0)) {
      this.setState({
        remainingItems: leftOver,
        isLoadMore: true,
        initialLoadComplete: true,
        rowsPerPage: newRowsPerPage,
      }, ()=> this.posListingData());
    }
  }

  handleFilterAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = 
    this.state.responseData as PosOrderInfo[]
    const checkBoxData = filterData.map((item: PosOrderInfo) => {
      item.checked = !this.state.isAllChecked;
      if (item.checked) {
        updatedSelectedItem[item.ordId] = item.checked;
      } else {if(updatedSelectedItem.hasOwnProperty(item.ordId)) {delete updatedSelectedItem[item.ordId];}}
      return item;
    });
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,
        isAllChecked: !prev.isAllChecked,
        responseData: checkBoxData,
      };
    });
    this.setState({isLoading: false,})
  }

  handleGroupbyAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = 
    this.state.responseData as PayloadType[]
    const checkBoxData = groupbyData.map((element: PayloadType) => {
      let updatedElement = element
      updatedElement.data = element.data?.map((item: PosOrderInfo) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {
          updatedSelectedItem[item.ordId] = item.checked;
        } 
        else {if (updatedSelectedItem.hasOwnProperty(item.ordId)) { delete updatedSelectedItem[item.ordId];}}
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,isLoading: false,isAllChecked: !prev.isAllChecked,responseData: checkBoxData,
      };
    });
  }

  handleAllChecked = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {this.handleFilterAllChecked(updatedSelectedItem)} 
    else {this.handleGroupbyAllChecked(updatedSelectedItem)}
  };

  handleFilterSelect = (item: { name: string; selKey: string }) => {
    const alreadySelected = this.state.queryItems.some((ele)=>(
      ele.tag === item.name
    ))
    if(!alreadySelected){
    this.setState(
      (prevState) => ({queryItems: [...prevState.queryItems, 
        { id: item.selKey, tag: item.name, key: item.selKey, type: "filter" }]
    }));
  }
    this.setState({ 
      anchorElFilter: null,
      filterOrderDateAnchor:null,
      isAllChecked: false,
      selectedItems: {}
    });
  };

  handleGroupbyOrderDate = (item: { name: string; selKey: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState(
      (prevState) => ({queryItems: [ 
        { id: item.selKey, tag: `Order Date : ${item.name}`, key: item.selKey, type: "groupby" }]
    }));
    this.setState({ 
      anchorElGroupBy: null,
      groupbyOrderDateAnchor:null,
      groupbySelectedQuery:item.selKey,
      isAllChecked: false,
      isGroupby: true,
      selectedItems: {}
    });
  };

  handleSelectedYear(year:number) {
    this.setState({
      filterSelectedYear:year,
      isYearSelected:true,
    })
  }

  handleSelectedQuarter(item : { name: string; selKey: string }) {
    const quarter = this.quarterToMonths.find(quart => quart.name === item.name);
    const months = quarter ? quarter.selKey : [];
    this.setState({
      filterSelectedQuarter: item.selKey,
      isQuarterSelected: true,
      filterMonthOption: months
    });
  }

  handleSelectedMonth(item : string) {
    const month = this.monthsWithKey.find(element => element.monthName.toLowerCase() === item.toLowerCase());
    if(month){
      this.setState({
        filterSelectedMonth: month.monthValue.toString(),
      });
      this.setState(
        (prevState) => ({queryItems: [...prevState.queryItems, 
          { id: month.monthName, tag: `Order Date : ${item} ${this.state.filterSelectedYear}`, key: month.monthValue, type: "filterbydate" }]
      }));
      this.setState({
        filterOrderDateAnchor: null,
        anchorElFilter:null,
      });
    }
  }

  monthCheck(filterSelectedMonth: string, item: string): boolean {
    const month = this.monthsWithKey.find(element => element.monthValue === filterSelectedMonth);

    if (month && month.monthName === item) {
        return true;
    } else {
        return false;
    }
  }

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    this.setState({
      currentPage: 1,
      rowsPerPage: +(event.target.value as string),
    }, ()=> this.posListingData());
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  handleListViewClick=()=>{
    this.setState({ 
      isViewTypeChanged: true, rowsPerPage: 9 
    })
  }

  handleCanbanViewClick=()=>{
    this.setState({ 
      isViewTypeChanged: false,
      currentPage:1,
      rowsPerPage: 9, 
      isLoading: true,
      queryItems: [] ,
      isGroupby:false
    });
  }

  toggleIsExpanded = (rowKey: number) => {
    const expandableRows=this.state.isExpanded
    expandableRows[rowKey] = !expandableRows[rowKey]
    this.setState({isExpanded:expandableRows})
  };

  onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({query: value});
    return {};
  }

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    if (item.type === "groupby") {
      this.setState({
        isGroupby: false ,
        queryItems: updatedQueryItems , 
        query: "",
        currentPage: 1,
        isAllChecked:false,
        selectedItems:{},
        groupbyOrderDateAnchor:  null,
        groupbySelectedQuery:"",
      });
    }
    else {
      this.setState({
        isAllChecked: false,
        selectedItems: {},
        queryItems: updatedQueryItems,
        query: "",
        currentPage: 1,
        filterSelectedYear:0,
        filterSelectedQuarter:"",
        filterSelectedMonth:"",
        filterOrderDateAnchor:  null,
        isYearSelected:false,
        isQuarterSelected:false,
      });
    }
  }

  posListingData = async () => {
    const token = this.state.token;
    const { currentPage, rowsPerPage } = this.state;
    let params = {} as { [name: string]: boolean | string }
    let filters:string[]=[]
    this.state.queryItems.forEach((item: {
      id: string;
      tag: string;
      key: string;
      type: string;
    }) => {
      if (item.hasOwnProperty("key") && item.type=="filter") { 
        filters.push(item.key)
      }
      if (item.hasOwnProperty("key") && item.type=="filterbydate") { 
        params["month"] =  item.key
        params["quarter"] =  this.state.filterSelectedQuarter
        params["year"] =  this.state.filterSelectedYear.toString()
      }
      else if (item.hasOwnProperty("key") && item.tag.includes("Order Date :")) { 
        params["group_by_order_date"] =  "group_by_order_date"
        params["format"]=item.key
      }
      else if (item.hasOwnProperty("key") && item.type=="groupby") { 
        params["group_by"] =  item.key
      }
    })
    if(filters.length>0){
      params["filter"] =  filters.join(",")
    }

    const pstr = Object.keys(params).map(function (paramKey) {return paramKey + "=" + params[paramKey];}).join("&");
    this.getPOSOrderListing = this.handleApiCall(token,
      this.state.isGroupby ? `/bx_block_order_management/orders?${pstr}` : 
      `/bx_block_order_management/orders?page=${currentPage}&per_page=${rowsPerPage}&${pstr}`,"GET"
    );
  };


  handleCheckBoxSelectGroupby = (item: PosOrderInfo, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: PayloadType[]) => {
    if (item.checked) {updatedSelectedItem[item.ordId] = true;} 
    else if (updatedSelectedItem.hasOwnProperty(item.ordId)) {delete updatedSelectedItem[item.ordId];}

    checkBoxData.forEach((element: PayloadType, index: number) => {
      element.data.forEach((rowEle: PosOrderInfo, rowind: number) => {
        if (rowEle.ordId === item.ordId) {
          element.data[rowind] = item;
        }
      });
    });
    
    return checkBoxData.every((element: PayloadType) => {
      return element.data.every((item: PosOrderInfo) => item.checked) === true;
    });
    
  }

  handleCheckBoxSelectFilter = (item: PosOrderInfo, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: PosOrderInfo[]) => {
    if (item.checked) {
      updatedSelectedItem[item.ordId] = true;
    } 
    else if (updatedSelectedItem.hasOwnProperty(item.ordId)) { 
      delete updatedSelectedItem[item.ordId];
    }
    checkBoxData[index] = item;
    return checkBoxData.every((item: PosOrderInfo) => item.checked);
  }
  
  handleCheckBoxSelect = (item: PosOrderInfo, index: number) => {
    const updatedSelectedItem = { ...this.state.selectedItems };
    const checkBoxData = [...this.state.responseData] as PayloadType[] | PosOrderInfo[];
    let isAllChecked = false
    item.checked = !item.checked;
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData as PayloadType[] );
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData as PosOrderInfo[]);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false, isLoadMore: false,
    })
  };


  handleApiCall = (
    token: token, 
    endPoint: string, 
    method: string, 
    body?: object) => {
    
    const header = {
      "Content-Type": "application/json",
      token: token, 
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),method);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));


    runEngine.sendMessage(
      requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
