import React, { Component } from "react";
import  { Link } from "react-router-dom";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    TextField,
    FormHelperText,
    Checkbox,
    Chip,
    Paper,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {Form } from "formik";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
const { alert, addMedia } = require("./assets");
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#2A6396",
            contrastText: "#fff"
        }
    }
});

const optionsList = [
    {
        id: "1",
        title: "10%",
    },
    {
        id: "2",
        title: "20%",
    },
    {
        id: "3",
        title: "30%",
    },
];



interface ChipDropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    errors: any,
    touched: any,
    isRequired: boolean,
    values: any,
    handleBlur: any,
    handleChange: any,
    setFieldValue: any,
    placeholderValue:string
}


interface TextFieldProps {
    name: string,
    label: string,
    errors: any,
    touched: any,
    placeholder: string,
    isRequired: boolean
    values: any
    handleBlur: any
    handleChange: any
}

interface SalesTabProps {
    getInitialValues: () => {
        canbesold: boolean,
        canbepurchased: boolean,
        optional_products: string[],
        accessory_products: string[],
        alternative_products: string[],
        available_in_POS: boolean,
        to_weigh_with_scale: boolean,
        sales_description: string,
        account_id: number,
        extra_product_media: any,
    };
    validationSchema: any;
    extractUomValues: {
        id: string;
        title: string;
    }[];
    extractProductCategoryValues: {
        id: string;
        title: string;
    }[];
    handleSave: () => Promise<void>;
    validateInput: any;
    productName: string;
    errors:any;
    touched:any;
    handleChange :() => {};
    values:any;
    handleBlur: () => {};
    navigateToProductCreationFromEdit: () => Promise<void>;
}


// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class SalesTab extends React.Component<any> {
    constructor(props: SalesTabProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    autocompleteDropDrop = (
        { name, options, label, errors, touched, isRequired, handleBlur, setFieldValue , placeholderValue , values }
            : ChipDropDownProps) => {
        const isEmpty = values?.[name] && values?.[name].length > 0;
        return (
            <div className="form-group form-check" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}{isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <Autocomplete
                        data-test-id={"autocompleteChip"}
                        id="tags-outlined"
                        disableClearable
                        style={{ width: "90%" }}
                        options={options}
                        renderOption={(option) => (
                            <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option.title}</Typography>
                        )}
                        multiple
                        value={values?.[name] }
                        filterSelectedOptions
                        getOptionLabel={(option) => option.title}
                        PaperComponent={({ children }) => (
                            <Paper style={{
                                boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2,
                            }}>{children}</Paper>
                        )}
                        popupIcon={<ExpandMoreIcon />}
                        onChange={(_, newValue) => {
                            setFieldValue(name, newValue);
                        }}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.title}
                                    deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                name={name}
                                placeholder={isEmpty ? "" : placeholderValue}
                                className={
                                    'form-check-input' + 
                                    (errors[name] && touched[name] ? ' is-invalid' : '') 
                                }
                                onBlur={handleBlur}
                                error={touched[name] && Boolean(errors[name])}
                            />
                        )}
                    />

                    <div style={webStyle.errorBox}>
                        {(touched[name] && errors[name]) && 
                        <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                        <FormHelperText 
                        style={webStyle.errorTextMsg}>
                        {touched[name] && errors[name]}</FormHelperText>
                    </div>
                </div>
            </div>
        );
    }

    renderSalesViewSection = () =>{
        return(
            this.props.completeProductData.map((item: any) => {
                if (item.id === this.props.selectedProductId) {
                console.log("Sales Info:", item.attributes.sale);
        return(
        <ProductDetailsContainer key={item.id}>
                    <SalesDetailContainer>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Upsell & Cross-Sell
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Optional Products
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                        {item.attributes.sale?.optional_products
                                            ? JSON.parse(item.attributes.sale.optional_products[0]).join(', ')
                                            : '-'}
                                      
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Accessory Products 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    {item.attributes.sale?.optional_products
                                        ? JSON.parse(item.attributes.sale.accessory_products[0]).join(', ')
                                        : '-'}
                                      
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Alternative Products
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box>
                                        {item.attributes.sale.alternative_products[0][0]?.length > 0 ? (
                                            JSON.parse(item.attributes.sale.alternative_products[0]).map(
                                                (product: string, index: number) => (
                                                    <GeneralTax key={index} variant="body1">{product}</GeneralTax>
                                                )
                                            )
                                        ) : (
                                            <GeneralTax variant="body1">None</GeneralTax>
                                        )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Sales Description 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{item.attributes.sale.sales_description || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Upsell & Cross-Sell
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Point of sale 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{display:"flex", alignItems:"center"}}>
                                        <Box component="span">
                                            <Checkbox
                                            checked={item.attributes.sale.available_in_POS} 
                                            />
                                        </Box>
                                        <Typography variant="body1">Available in POS</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{display:"flex", alignItems:"center"}}>
                                        </Grid>
                                    <Grid item xs={6} style={{display:"flex", alignItems:"center"}}>
                                        <Box component="span">
                                            <Checkbox
                                            checked={item.attributes.sale.to_weigh_with_scale} 
                                            />
                                        </Box>
                                        <Typography variant="body1">To Weigh with Scale</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body1" color="textSecondary">
                                            Extra Product Media
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{item.attributes.sale.extra_product_media || "Add a media"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ButtonContainer sx={webStyle.actionBtnStyle}>
                            <CreateBtn
                                data-test-id="createBtn"
                                variant="contained"
                                onClick={this.props.navigateToCreateNewProduct}
                            >Create</CreateBtn>
                            <EditBtn
                                variant="contained"
                                style={webStyle.saveBtn}
                                data-test-id="editBtn"
                                onClick={this.props.setAllFormOpen}
                            >Edit</EditBtn>
                        </ButtonContainer>
                    </SalesDetailContainer>
                </ProductDetailsContainer>);
                      }
                    })
        )
    }
    renderViewSection = () =>{
        return(
            (
                this.props.completeProductData.length > 0 ? (
                  this.renderSalesViewSection()
                    ) : (
                        <LoaderContainer>
            <CircularProgress style={{color: "#2A6395"}}/>
        </LoaderContainer>
                      )
                    )
        )
    }
    // Customizable Area End


    render() {
        // Customizable Area Start
        const sharedProps = {
            options: this.props.optionalProductOption,
            errors: this.props.errors,
            touched: this.props.touched,
            isRequired: false,
            handleChange: this.props.handleChange,
            values: this.props.values,
            handleBlur: this.props.handleBlur,
            setFieldValue: this.props.setFieldValue,
        };
        return (
            <>
            {this.props.isSalesEditing?(<ThemeProvider theme={theme}>
                                <Box sx={webStyle.salesFormContainer}>
                                    <Form translate={undefined}>
                                        <style>
                                            {`
                                                & .MuiIconButton-root-562:hover {
                                                    background-color: transparent !important;
                                                }
                                                .MuiAutocomplete-listbox {
                                                    padding:0;
                                                }
                                                #tags-outlined-popup {
                                                    padding:0 !important;
                                                }
                                                li[role="option"] {
                                                    border-bottom: 1px solid #E3E3E3;
                                                }
                                                ul[role="listbox"] {
                                                    padding-top:0 !important;
                                                    padding-bottom:0 !important;
                                                }
                                                .MuiList-padding {
                                                    padding-top:0 !important;
                                                    padding-bottom:0 !important;
                                                }
                                            `}
                                        </style>
                                        <Typography style={webStyle.upsellHeading as React.CSSProperties}>Upsell & Cross-Sell</Typography>
                                        <Grid container spacing={4} justifyContent="space-between" >
                                            <Grid item sm={6} md={6} lg={6}>
                                                {this.autocompleteDropDrop({
                                                    label: "Optional Products",
                                                    name: "optional_products",
                                                    placeholderValue: "Select Optional Products",
                                                    ...sharedProps
                                                })}
                                            </Grid>
                                            <Grid item sm={6} md={6} lg={6} style={{marginTop:"50px"}}>
                                                <Box sx={webStyle.extraMediaBox}>
                                                    <Typography style={webStyle.pageHeading as React.CSSProperties}>
                                                        {"Extra Product Media"}
                                                    </Typography>
                                                    <Box sx={webStyle.imageField}>
                                                        <label htmlFor="contained-button-files">
                                                            <Button variant="text" component="span"
                                                                endIcon={<img src={addMedia} alt="Add a Media" />}
                                                                style={webStyle.imageButton}>
                                                                    <Typography style={{fontWeight:"bold"}}>Add a Media</Typography> 
                                                                    <input
                                                                        data-test-id="imageFiles"
                                                                        accept=".jpg, .jpeg, .png"
                                                                        type="file"
                                                                        className="display-none"
                                                                        ref={this.props.extraMediaRef}
                                                                        name="extra_product_media"
                                                                        onChange={(event) => {
                                                                            console.log(event.target.files,"event123")
                                                                            this.props.setFieldValue("extra_product_media", event.target.files);
                                                                        }}
                                                                        id="contained-button-files"
                                                                        multiple
                                                                    />
                                                            </Button>
                                                        </label>
                                                    </Box>
                                                </Box>       
                                                <div style={webStyle.imageContainer as React.CSSProperties}>
                                                    {this.props.values["extra_product_media"] && Array.from(this.props.values["extra_product_media"]).map((image: any, index: number) => (
                                                        <div key={index} style={webStyle.imageBox as React.CSSProperties}>
                                                            <img
                                                                data-test-id="extraMediaImageRef"
                                                                className='br-5'
                                                                onClick={() => this.props.extraMediaChange(index)}
                                                                src={URL.createObjectURL(image)}
                                                                height={100}
                                                                width={100}
                                                                style={{ marginRight: "10px" }}
                                                                alt={`Extra Media ${index}`}
                                                            />
                                                            <IconButton
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                data-test-id="cancelBtn"
                                                                style={webStyle.cancelButton as React.CSSProperties}
                                                                onClick={() => {
                                                                    const { extra_product_media } = this.props.values;
                                                                    const updatedMedia = Array.from(extra_product_media);
                                                                    updatedMedia.splice(index, 1);

                                                                    this.props.setFieldValue("extra_product_media", updatedMedia);
                                                                }}
                                                            >
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                </div>               
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} justifyContent="space-between" >
                                            <Grid item sm={6} md={6} lg={6}>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {this.autocompleteDropDrop({
                                                        label: "Accessory Products",
                                                        name: "accessory_products",
                                                        placeholderValue:"Select Accessory Products",
                                                        ...sharedProps
                                                    })}
                                                </div>
                                                <div data-test-id="navigationButton">
                                                        <Typography style={webStyle.fieldBottomTextBlue as React.CSSProperties} onClick={this.props.navigateToProductCreationFromEdit}>
                                                            Create New Product
                                                        </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} justifyContent="space-between" >
                                            <Grid item sm={6} md={6} lg={6}>
                                                {this.autocompleteDropDrop({
                                                    label: "Alternative Products",
                                                    name: "alternative_products",
                                                    placeholderValue: "Select Alternative Products",
                                                    ...sharedProps
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} justifyContent="space-between" >
                                            <Grid item sm={6} md={6} lg={6} style={webStyle.posBox as React.CSSProperties}>
                                                <Typography style={webStyle.saleHeading as React.CSSProperties}>
                                                    {"Point of Sale"}
                                                </Typography>
                                                <Box display="flex" flexDirection="row">
                                                    <Box style={webStyle.salesTypographyBox as React.CSSProperties}>
                                                        <Typography style={webStyle.pageHeading as React.CSSProperties}>
                                                            {"Available in POS"}
                                                        </Typography>
                                                        <Typography style={webStyle.pageHeading as React.CSSProperties}>
                                                            {"To Weigh With Scale"}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={webStyle.salesCheckboxes as React.CSSProperties}>
                                                        <Checkbox
                                                            data-test-id="available_in_POS"
                                                            size="medium"
                                                            disableFocusRipple
                                                            disableRipple
                                                            checked={this.props.values["available_in_POS"]}
                                                            value={this.props.values["available_in_POS"]}
                                                            disableTouchRipple
                                                            name={"available_in_POS"}
                                                            className={
                                                                'form-check-input' +
                                                                (this.props.errors["available_in_POS"] && this.props.touched["available_in_POS"]
                                                                    ? ' is-invalid'
                                                                    : '')
                                                            }
                                                            color="primary"
                                                            onChange={this.props.handleChange}
                                                            style={{ padding: 0 }}
                                                        />
                                                        <Checkbox
                                                            data-test-id="to_weigh_with_scale"
                                                            size="medium"
                                                            disableFocusRipple
                                                            disableRipple
                                                            disableTouchRipple
                                                            checked={this.props.values["to_weigh_with_scale"]}
                                                            value={this.props.values["to_weigh_with_scale"]}
                                                            name={"to_weigh_with_scale"}
                                                            className={
                                                                'form-check-input' +
                                                                (this.props.errors["to_weigh_with_scale"] && this.props.touched["to_weigh_with_scale"]
                                                                    ? ' is-invalid'
                                                                    : '')
                                                            }
                                                            color="primary"
                                                            onChange={this.props.handleChange}
                                                            style={{ padding: 0 }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item sm={12} md={12} lg={12}>
                                                <div className="form-group" style={webStyle.formGroupContainer}>
                                                    <Typography style={webStyle.pageHeading as React.CSSProperties}>{"Sales Description"}</Typography>
                                                    <div style={webStyle.errorContainer as React.CSSProperties}>
                                                        <TextField
                                                            name={"sales_description"}
                                                            type="text"
                                                            variant="outlined"
                                                            className={
                                                                'form-control' + (this.props.errors["sales_description"] && this.props.touched["sales_description"] ? ' is-invalid' : '')
                                                            }
                                                            inputProps={{
                                                                maxLength: 265,
                                                            }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <Typography style={{ color: "black" }}>EN</Typography>
                                                                </InputAdornment>,
                                                            }}
                                                            value={this.props.values?.["sales_description"]}
                                                            style={{ width: "100%" }}
                                                            placeholder={"This note added to sales notes & invoices..."}
                                                            onBlur={this.props.handleBlur}
                                                            onChange={this.props.handleChange}
                                                        /></div>
                                    </div>
                                    <Typography style={webStyle.NotesBottomGrey as React.CSSProperties}>max 265 characters*</Typography>
                                            </Grid>
                                        </Grid>
                                        <Box sx={webStyle.actionBtnStyle}>
                                        <Button variant="contained" style={{ backgroundColor: "#fff" }} 
                                            data-test-id="discardBtn"
                                            onClick={() => {
                                                this.props.handleDiscardImage();
                                                this.props.resetForm({ values: this.props.getInitialValues() });
                                            }}
                                            >Discard</Button>
                                            <Button
                                                data-test-id="saveBtn"
                                                style={webStyle.saveBtn}
                                                onClick={() => { 
                                                    this.props.validateInput(this.props.productName);
                                                    this.props.handleSave(this.props.values);
                                                }}>Save</Button>
                                        </Box>
                                    </Form>
                                </Box>
            </ThemeProvider>):
            this.renderViewSection()
            }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    formGroupContainer: {
        width: "100%",
        alignItems: "baseline",
    },
    salesFormContainer: {
        paddingBlock: 8,
        display: "block",
        paddingInline: 16,
    },
    pageHeading: {
        textWrap: "nowrap !important"
    },
    inputField: {
        padding: "10px",
        outline: "none",
        width: "60%",
        borderRadius: '4px',
        border: '2px solid lightgrey',
    },
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 0",
        flex: 1
    },
    imageStyle: {
        paddingTop: "15px",
        width: "25px",
    },
    errorBox: {
        display: 'flex',
        alignItems: 'center'
    },
    alertIcon: {
        position: "absolute",
        width: "10px",
    },
    actionBtnStyle: {
        display: "flex",
        position: "relative",
        left: "25px",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    errorTextMsg: {
        color: 'red',
        marginLeft: "15px"
    },
    saveBtn: {
        color: "#ffffff",
        backgroundColor: "rgba(9, 65, 115, 0.77)",
    },
    fieldBottomTextBlue: {
        color: "#2A6395",
        textAlign: "right",
        paddingRight: "10%",
        fontWeight: "bold",
        cursor:"pointer"
    },
    actionButtonBox: {
        position: "absolute",
        right: "25px",
        paddingTop: "30px",
    },
    fieldBottomTextGrey: {
        color: "grey",
        textAlign: "right",
        paddingRight: "10%",
        fontSize: "12px",
    },
    NotesBottomTextGrey: {
        paddingRight: "5%",
        textAlign: "right",
        fontSize: "12px",
        color: "grey",
    },
    editImg: {
        top: "10px",
        position: "absolute",
        right: "20px",
    },
    menuItem: {
        borderBottom: "1px solid grey",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    salesTypographyBox: {
        display: "flex",
        flexDirection: "column",
        paddingRight: "30px",
        borderRight: "1px solid grey",
        gap: "20px"
    },
    salesCheckboxes: {
        flexDirection: "column",
        display: "flex",
        paddingLeft: "20px",
        gap: "20px",
    },
    upsellHeading:{
        margin : "10px 0px",
        color: 'gray',
    },
    saleHeading:{
        color:'gray'
    },
    posBox:{
        flexDirection: "column", 
        display: "grid", 
        marginBottom: "20px",
        gap: "20px", 
    },
    extraMediaBox:{
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center",
    },
    imageField:{
        marginLeft: "20px" ,
        borderBottom: "1px solid lightgrey", 
        width: "50%", 
    },
    imageButton:{
        justifyContent: "space-between",
        width: "100%", 
    },
    cancelButton:{
        bottom:"62px",
        position:"absolute",
        right:0,
    },
    imageBox: { 
        flexDirection: 'column', 
        display: 'flex', 
        position: "relative" ,
        alignItems: 'center', 
    },
    imageContainer: { 
        flexWrap: "wrap", 
        marginTop: "10px", 
        display: "flex", 
    },
    NotesBottomGrey: {
        textAlign: "right",
        color: "grey",
        fontSize: "12px",
    },
};
const ProductDetailsContainer = styled(Box)({
    marginTop: '10px',
});
const SalesDetailContainer = styled(Box)({
    padding:'28px'
})
const CreateBtn = styled(Button)({
    display: "flex",
    position: "relative",
    right: "12px",
    gap: 8,
    float: "right",
    backgroundColor: "#ffffff"
})
const EditBtn = styled(Button)({
    display: "flex",
    position: "relative",
    gap: 8,
    float: "right",
    color: "#ffffff",
    backgroundColor: "rgba(9, 65, 115, 0.77)",
})
const GeneralTax = styled(Typography)({
    fontSize:"15px"
})
const ButtonContainer = styled(Box)({
    marginTop:'50px',
})
const LoaderContainer = styled(Box)({
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '50vh',
})
const CrossButton = styled('span')({
    marginLeft: '8px',
    cursor: 'pointer',
    color: '#333',
    fontFamily:"Roboto",
  });
// Customizable Area End
