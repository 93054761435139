import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { backArrowEnable, backarrow, forwardArrowDisable, forwardarrow, griddownarrow, gridleftarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, paginationarrow } from './assets';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Checkbox, CircularProgress, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  defaultView:boolean;
  loadMoreRecords:boolean;
  page: number;
  per_page: number;
  name:string;
  model:string;
  group:string;
  open: boolean;
  active:any[];
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  showPreview: boolean;
  token:string|null;
  showCustomerdata:any[];
  groupbyResponse:any[];
  searchValue: string;
  groupValue: string;
  groupByvalue: string;
  activeGroup: string; 
  activeFilter: number;
  activeFilterArr: any[];
  filterLabel: string;
  loading: boolean;
  searchKeyword: string;
  totalCounts:number;
  totalPage:number;
  globalCheck:boolean;
  checkedArr:any[];
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CompaniesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCompanieslistCallId!: string;
  apigetGroupbyCompanylistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      defaultView:true,
      loadMoreRecords:false,
      page:1,
      per_page:9,
      active:[],
      activeGroup: '',
      activeFilter: -1,
      activeFilterArr: [],
      showFilter: false,
      showColumn: false,
      showGroup: false,
      name:'',
      model:'',
      group:'',
      open: false,
      showCustomerdata: [],
      groupbyResponse: [],
      searchValue: "",
      groupValue: "",
      groupByvalue: "",
      showFav: false,
      showSettings: false,
      showPreview: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      token:"",
      totalCounts: 0,
      totalPage:0,
      globalCheck:false,
      checkedArr:[],
      filterLabel:"",
      loading:false,
      searchKeyword: '',
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, msg: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === msg.id) {
      const apiRequestCallId = msg.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = msg.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.errors){
        window.alert("Invalid Token")
      }else if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apigetGroupbyCompanylistCallId) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.apigetCompanieslistCallId) {
          this.apiResponse(responseJson)
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleCompaniesCheckbox = (id:any) => {
    console.log('xpp',id,this.state.checkedArr);
    if(this.state.checkedArr.includes(id)){
      this.setState({...this.state,globalCheck:false,checkedArr:this.state.checkedArr.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,globalCheck:(this.state.showCustomerdata.length==(this.state.checkedArr.length+1))?true:false,checkedArr:[...this.state.checkedArr,id]})
    }
  }

  handleGlobalCheckCompany = () => {
    let arr:any = [];
    this.state.showCustomerdata?.forEach((user:any) => {
      arr.push(user.id)
    })
    this.setState({...this.state,checkedArr:!this.state.globalCheck?arr:[],globalCheck:!this.state.globalCheck})
  }

  isPageLastCompany(): boolean {
    if (!this.state.showCustomerdata.length) {
      return true;
    }
    if (!this.state.activeGroup) {
      return this.state.page === this.state.totalPage;
    }
    return true;
  }

  handleCompaniesActive = (id:any) => {
    if(this.state.active.includes(id)){
      this.setState({...this.state,active:this.state.active.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,active:[...this.state.active,id]})
    }
  }

  async componentDidMount() {
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken
    })
   await this.getCompanieslistApi(this.state.per_page,this.state.page)
  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
      
    if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
      this.state.token && await this.getCompanieslistApi(this.state.per_page,this.state.page)
    }
    
    if (prevState.activeGroup !== this.state.activeGroup) {
      this.state.activeGroup == 'Parent Company' ? await this.getGroupbyCompanieslistApi('parent_company') : await this.getCompanieslistApi(this.state.per_page,this.state.page)
    }
    if ((prevState.searchKeyword !== this.state.searchKeyword)) {
      if(this.state.searchKeyword.length>=3){
        await this.getCompanieslistApi(this.state.per_page,1);
      }
      if(this.state.searchKeyword.length==0){
        await this.getCompanieslistApi(this.state.per_page,1);
      }
    }
    
  }

  searchPlaceholderCmp = () => {
    return !(this.state.activeGroup) ? 'Search here...' : '';
  }

  searchIconDisplayCmp = () => {
    return this.state.activeGroup ? 'display-none' : ''
  }

  checkActiveGroup = () => {
    return !this.state.activeGroup ? 'display-none' : ''
  }

  selectedGroupCmpListItem = (value:any) => {
    if(this.state.activeGroup == value){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  loaderBoxCmp = () => {
    if(this.state.loading || this.state.showCustomerdata.length==0){
      return 'loaderBox'
    }else{
      return 'display-none'
    }
  }


  loaderBoxContentCmp = () => {
    if(this.state.loading){
      return <CircularProgress />
    }else if(this.state.showCustomerdata.length==0){
      return <Typography className='bold-text'>No Records Found!</Typography>
    }else{
      return <></>
    }
  }


  listViewCmp = () => {
    if(!(this.state.defaultView && this.state.showCustomerdata.length>0 && (!this.state.loading))){ 
      return 'display-none'
    }else{
      return ''
    }
  }

  handleActiveGroupCmp = (value:any) => {
    this.setState({...this.state,activeGroup:value,showGroup:false});
  }

  loadMoreRecordsCmp = async(action:number,type:string='',lmore:boolean=false) => {
    this.setState({
      page:action==0?1:this.state.page+action,
      loadMoreRecords:lmore
    })
    
  }

  handlePaginationCmp = async(action:number) => {
    this.setState({
      per_page:action,page:1
    })
    
  }
 
  navigatetoCreateCompanies = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddCompany'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

  }
  navigatetoGridview = () => {
    this.setState({...this.state,activeGroup:"",defaultView:false,per_page:18,page:1})

  }
  navigatetoListview = () => {
    this.setState({...this.state,activeGroup:"",defaultView:true,per_page:9,page:1})

  }
  apiResponse=(responseJson:any)=>{
    if(responseJson.data[0]?.companies?.data?.length>0){
      let res:any = [];
      responseJson.data.forEach((data:any)=>{
        data.companies.data.length>0 && res.push(...data.companies.data)
      })
      this.setState({loadMoreRecords:false,groupbyResponse:responseJson.data,showCustomerdata:res,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }else if(responseJson.data){
      (this.state.loadMoreRecords && this.state.showCustomerdata?.length>0) ? this.setState({loadMoreRecords:false,showCustomerdata:[...this.state.showCustomerdata,...responseJson.data],totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages}) : this.setState({loadMoreRecords:false,showCustomerdata:responseJson.data,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }
  }

  handleCmpCurrentSearch = () => {
    this.setState({ ...this.state, showCurrentSearch: !this.state.showCurrentSearch });
  };
  handleCmpAddToDashboard = () => {
    this.setState({ ...this.state, showAddToDashboard: !this.state.showAddToDashboard });
  };

  handleGroup = () => {
    this.setState({ ...this.state, showGroup: !this.state.showGroup });
  };
  handleCmpFavorites = () => {
    this.setState({ ...this.state, showFav: !this.state.showFav });
  };

  handleCmpPreview = () => {
    this.setState({ ...this.state, showPreview: !this.state.showPreview });
  };
  
  displaySettingStyle = ()=>{
    if(this.state.checkedArr.length){
      return {display:'inline'}
    }else{
      return {display:'none'}
    }
  }
  
  handleCmpSettings = () => {
    this.setState({ ...this.state, showSettings: !this.state.showSettings });
  };
  
  columns: string[] = ["Customer Name", "Phone", "Email", "Salesperson", "Activities", "City", "State", "Country", "VAT/TAX ID", "Next Partner Review", "Partner Level", "Activation", "Tags", "Current Membership Status"];

  getCompanieslistApi = async (per_page:number,page1:number) => {
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:page1});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetCompanieslistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/companies?per_page=${per_page}&page=${page1}&search=${this.state.searchKeyword}${this.state.activeGroup ? '&parent_company=yes':''}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  getGroupbyCompanieslistApi = async (value:string) => {
    this.setState({...this.state,defaultView:true,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const url_params = `?${value}=yes`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetGroupbyCompanylistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/companies${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  expandMore = () => {
    return <img className='expandMoreImg' height={7} src={paginationarrow}/>;
  }

  movebackwardCmp = () => {
    return this.state.page==1 ? movebackward : moveBackwardArrowEnable;
  }

  backarrowCmp = () => {
    return this.state.page==1 ? backarrow : backArrowEnable;
  }

  forwardarrowCmp = () => {
    return this.isPageLastCompany()? forwardArrowDisable:forwardarrow;
  }

  moveforwardCmp = () => {
    return this.isPageLastCompany()? moveForwardArrowDisable:moveforward;
  }

  kanbanViewCmp = () => {
    if(!(!this.state.defaultView && this.state.showCustomerdata.length>0 && (!this.state.loading))){
      return 'display-none'
    }else{
      return ''
    }
  }

  hideLoadMore = () => {
    if((this.state.showCustomerdata.length == this.state.totalCounts)){
      return 'display-none';
    }else{
      return '';
    }
  }

  handleGroupIndexZeroCmp = (item:any,index1:number,index:number) => {
    if(index1==0){
      return <TableRow className='table-custom-row' data-test-id="table-custom-row" onClick={() => this.handleCompaniesActive(index)} >
                <TableCell >
                <IconButton
                    className='arrow-icon-button'
                    aria-label="expand row"
                    size="small"
                >
                    <img src={this.state.active.includes(index) ? griddownarrow : gridleftarrow} />
                </IconButton>
                </TableCell>
                <TableCell className='table-column-bold' component="th" scope="row" colSpan={5}>
                  {
                      item.key
                  }
                  {
                      ` (${item.companies.data.length})`
                  }
                </TableCell>
                
            </TableRow>
    }else{
      return ''
    }
  }

  expandTableCmpRow = (company:any,classes:any) => {
    return <TableRow>
                <TableCell>
                    <Checkbox 
                    checked={this.state.checkedArr.includes(company?.id) ? true : false} 
                    className={classes.companieslistviewCheck} 
                    onChange={()=>this.handleCompaniesCheckbox(company?.id)}
                    data-test-id="usercheckbox"
                    /></TableCell>
                <TableCell >{company?.attributes?.company_name}</TableCell>
                <TableCell >{company?.attributes?.company_general_infos?.parent_company}</TableCell>
                <TableCell >{company?.attributes?.company_general_infos?.email}</TableCell>
                <TableCell >{company?.attributes?.company_general_infos?.phone}</TableCell>
                

            </TableRow>
  }

  // Customizable Area End
}
