import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { SwitchClassKey } from '@material-ui/core/Switch';
export type token = string | null;
import { TableData } from "../../../components/src/Utils/types";
import { getStorageData } from "../../../framework/src/Utilities";

type groupbyBodyContentType = {
  data: tableBodyContentType[];
  key: string;
};

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

type tableBodyContentType = {
  id: string;
  type: string;
  checked: boolean;
  customer_name: string;
  customer_code: string;
  invoice_address: string;
  order_date: Date;
  payment_terms: string;
  delivery_address: string;
  total_items: 2;
  status_of_order: string;
  sales_name: string;
  branch_name: string;
  quotation_no: string;
  pricelist: string;
  margin: string;
  terms_and_conditions: string;
  tax_amount: [];
  total: number;
  archived: boolean;
  invoice_status: string;
  order_lines: [];
  account_id: number;
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:Styles;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedFilter: string;
  anchorElement: HTMLElement | null;
  anchorEl: HTMLElement | null;
  isChecked: boolean;
  isAllChecked: boolean;
  token: token;
  filteredData: TableData[];
  responseData:any;
  rowsPerPage: number;
  currentPage: number;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  totalCounts: number;
  query: string;
  dataLength: number;
  anchorElPrint: HTMLElement | null;
  anchorElFilter: HTMLElement | null;
  selectedItems: {[name: string]: boolean;};
  anchorElGroupBy: HTMLElement | null;
  params: { [name: string]: boolean | string };
  isLoading: boolean;
  isGroupby: boolean;
  tableData:any;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  isExpanded:boolean[];
  isLoadMore: boolean;
  remainingItems: number;
  initialLoadComplete: boolean;
  toggleSwitch:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}


export default class BankAccountListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBankAccountListing: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),getName(MessageEnum.RestAPIRequestMessage),getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedFilter: "",
      anchorElement: null,
      anchorEl: null,
      isChecked: false,
      isAllChecked: false,
      token: "",
      filteredData: [],
      responseData:[],
      rowsPerPage: 9,
      currentPage: 1,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      totalCounts: 0,
      query: "",
      dataLength: 0,
      anchorElPrint: null,
      anchorElFilter: null,
      selectedItems: {},
      anchorElGroupBy: null,
      params: {},
      isLoading: false,
      isGroupby: false,
      tableData:[],
      queryItems: [],
      isExpanded:[],
      isLoadMore: false,
      remainingItems: 0,
      initialLoadComplete: false,
      toggleSwitch:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(
      this as IBlock, this.subScribedMessages
    );

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog(
      "Message Recived", 
      message
    );

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert("Change Value","From: " + this.state.txtSavedValue + " To: " + value);

      this.setState({ 
        txtSavedValue: value 
      });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id
      ) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {this.receiveApi(apiRequestCallId, responseJson);}
      runEngine.debugLog("API Message Recived", message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = 
      await getStorageData("TOKEN")
    this.setState({ 
      token: token 
    });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      this.BankAccountListingData();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query || (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchString = ""
      if (this.state.query.length >= 3) { searchString = `&search=${this.state.query}`}
      const { 
        currentPage, rowsPerPage 
      } = this.state;
      const params = 
      `page=${currentPage}&per_page=${rowsPerPage}${searchString}`
      this.getBankAccountListing = this.handleApiCall(token,`/bx_block_cfbankcashrecitevoucher4/bank_accounts?${params}`, "GET");
    }
    else if (
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.currentPage !== this.state.currentPage || 
      prevState.queryItems.length !== this.state.queryItems.length) { this.BankAccountListingData()}
      
      if(prevState.queryItems !== this.state.queryItems){
        this.BankAccountListingData()
      }
  }

  SettingOptions = [
    { name: "Export" },
    { name: "Delete" },
    { name: "Create Invoices" },
  ];

  filterOptions = [
    { name: "Trusted", key: "saler_name" },
    { name: "Untrusted", key: "customer_name" },
    { name: "To Validate", key: "group_by_order_date" },
    { name: "Customer", key: "group_by_order_date" },
    { name: "Vendors", key: "group_by_order_date" },
    { name: "Phishing Risk : High", key: "group_by_order_date" },
    { name: "Phishing Risk : Medium", key: "group_by_order_date" },
  ];

  groupByOptions = [
    { name: "Created On", key: "created_on" },
    { name: "Created By", key: "created_by" },
    { name: "Bank", key: "bank" },
  ];

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {data: object[];
      meta: { total_pages: number; 
        total_counts: number 
      };
    }
  ) => {
    if (apiRequestCallId === this.getBankAccountListing) { this.handleGetBankAccountResponse(responseJson.data,responseJson.meta.total_pages,responseJson.meta.total_counts);}
  };


  handleGetBankAccountResponse = (data: object[],totalPage: number,totalCounts: number) => {
    const tableInfo = !this.state.isGroupby ? this.getTableKey(data)  : this.getCollapseTableKey(data);
    this.setState({
      responseData: tableInfo,
      totalPage, totalCounts,
      isLoadMore: false,
      isLoading: false,
    })
  };


  getTableKey = (data: any) => {
    const bankAccountData: any = [];
    data.map((item: any) => {
      const rowId = item.id;
      const type = item.type;
      const attributes = item.attributes;
      const bankAccountInfo: any = {
        rowId,
        type,
        checked: false,
        account_holder: attributes.account_holder || "",
        account_number: attributes.account_number || "",
        bank: attributes.bank || "",
        account_holder_name: attributes.account_holder_name || "",
        clabe: attributes.clabe || "",
        company: attributes.company || "",
        aba_routing: attributes.aba_routing || 0,
        currency: attributes.currency || "",
        created_by: attributes.created_by || "",
        send_money: attributes.send_money || "",
        created_at: attributes.created_at || "",
        archive: attributes.archive || "",
      };
      bankAccountData.push(bankAccountInfo);
    })
    return bankAccountData;
  }

  getCollapseTableKey = (data: any) => {
    const bankAccountData = data?.map((item: any) => {
      let payload = {
        "key": item.key,
        data: []
      }
      payload.data = this.getTableKey(item.bank_accounts.data)
      return payload
    })
    return bankAccountData;
  }

  handleRowClick = (orderId: string) => {
    this.props.navigation.navigate("BankAccountCreationView", { id: orderId });
  }

  handleSwitchToggle = (tablerow: any, index: number) => {
    const updatedRows = [...this.state.responseData];
    updatedRows[index].send_money = !updatedRows[index].send_money;
    this.setState({ responseData: updatedRows });
  };
  

  handleSettingsClickAway = () => {
    this.setState({ 
      anchorEl: null 
    });
  };

  handleFilterClickAway = () => {
    this.setState({ 
      anchorElFilter: null 
    });
  };
  
  handleGroupClickAway = () => {
    this.setState({ 
      anchorElGroupBy: null 
    });
  };

  isCurrentPageOne(): boolean {
    if (!this.state.isGroupby) {
      return this.state.currentPage === 1;
    }
    return true;
  }

  isCurrentPageLast(): boolean {
    if (!this.state.responseData.length) {
      return true;
    }
    if (!this.state.isGroupby) {
      return this.state.currentPage === this.state.totalPage;
    }
    return true;
  }

  handleClickGroupBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,
    });
  };

  handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };

  showSetting = () => {
    if (this.state.isAllChecked || Object.keys(this.state.selectedItems).length)
      return true
    else
    return false
  }

  handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorElFilter: this.state.anchorElFilter ? null : event.currentTarget,
    });
  };

  handleChangePage = (pageNo: number) => {
    this.setState({
      currentPage: pageNo + 1,
    });
    if (this.state.currentPage > pageNo) {
      this.setState((prev) => ({
        row: {from: prev.row.from - this.state.rowsPerPage,end: prev.row.end - this.state.rowsPerPage,},
      }));
    } 
    else {this.setState((prev) => ({
      row: {from: prev.row.from + this.state.rowsPerPage,end: prev.row.end + this.state.rowsPerPage,},
    }));}
    this.setState({
      isAllChecked: false,
    })
  };

  handleGroupBySelect = (item: { name: string; key: string }) => {
    this.setState((prevState) => { 
      const updatedQueryItems = [...prevState.queryItems];
      const existingIndex = 
      updatedQueryItems.findIndex(
        (queryItem) => queryItem.type === "groupby"
      );
      if (existingIndex !== -1) {
        updatedQueryItems[existingIndex] = {id: item.key,tag: item.name,key: item.key,type: "groupby", };
      } 
      else {
        updatedQueryItems.push({id: item.key,tag: item.name,key: item.key,type: "groupby",});
      }
      return { 
        queryItems: updatedQueryItems 
      };
    });
    this.setState({
      selectedItems: {},
      isAllChecked: false,
      anchorElGroupBy: null,
      isGroupby: true,
    })
  };

  handleGroupbyAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = this.state.responseData as any[]
    const checkBoxData = 
    groupbyData.map((element: any) => {
      let updatedElement = element
      updatedElement.data = element.data?.map((item: any) => {item.checked = !this.state.isAllChecked;
        if (item.checked) {updatedSelectedItem[item.id] = item.checked;
        } 
        else {
          if (updatedSelectedItem.hasOwnProperty(item.id)) { delete updatedSelectedItem[item.id];}
        }
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,
        isLoading: false,isAllChecked: !prev.isAllChecked,responseData: checkBoxData,
      };
    });
  }

  handleFilterAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = this.state.responseData as any[]
    const checkBoxData = 
    filterData.map((item: any) => { item.checked = !this.state.isAllChecked;
      if (item.checked) { updatedSelectedItem[item.id] = item.checked;} 
      else {
        if(updatedSelectedItem.hasOwnProperty(item.id)) {
          delete updatedSelectedItem[item.id];
        }
      }
      return item;
    });
    this.setState((prev) => {return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
    })
  }

  handleAllChecked = () => {
    let updatedSelectedItem = 
      Object.assign({}, this.state.selectedItems);
    if (this.state.isGroupby) {
      this.handleGroupbyAllChecked(updatedSelectedItem)
    } 
    else {
      this.handleFilterAllChecked(updatedSelectedItem)
    }
  };

  handleFilterSelect = (item: { name: string; key: string }) => {
    this.setState(
      (prevState) => ({queryItems: [...prevState.queryItems, 
        { id: item.key, tag: item.name, key: item.key, type: "filter" }]
    }));
    this.setState({ 
      anchorElFilter: null,
      isAllChecked: false,
      selectedItems: {}
    });
  };

  handleCreationNavigation=()=>{
    this.props.navigation.navigate("BankAccountCreation")
  }

  handleChangeRowsPerPage = (event:any) => {
    this.setState({currentPage: 1,rowsPerPage: parseInt(event.target.value as string),});
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  toggleIsExpanded = (rowKey: number) => {
    const expandableRows=this.state.isExpanded
    expandableRows[rowKey] = !expandableRows[rowKey]
    this.setState({
      isExpanded:expandableRows
    })
  };

  onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = 
    event.target;
    this.setState({
      query: value
    });
    return {};
  }

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    if (item.type === "groupby") {
      this.setState({
        isGroupby: false ,
        selectedItems:{},
        queryItems: updatedQueryItems , 
        isAllChecked:false,
        query: "",
        currentPage: 1,
      });
    }
    else {
      this.setState({
        queryItems: updatedQueryItems,
        selectedItems: {},
        query: "",
        currentPage: 1,
        isAllChecked: false,
      });
    }
  }

  BankAccountListingData = async () => {
    let params = {} as any
    const token = this.state.token;
    const { currentPage, rowsPerPage } = this.state;
    this.state.queryItems.forEach((
      item: any) => {
      if (item.hasOwnProperty("key")) {
        params[item.key] = true}
    })

    const pstr = Object.keys(params).map(function (pkey) {return pkey + "=" + params[pkey];}).join("&");
    this.getBankAccountListing = this.handleApiCall(token,
      this.state.isGroupby ? `/bx_block_cfbankcashrecitevoucher4/bank_accounts?${pstr}` : 
      `/bx_block_cfbankcashrecitevoucher4/bank_accounts?page=${currentPage}&per_page=${rowsPerPage}&${pstr}`,"GET"
    );
  };


  handleCheckBoxSelectGroupby = (item: any, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;} 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) {
      delete updatedSelectedItem[item.id];
    }

    checkBoxData.map((
      element:any,index:number)=>{
        element.data.map(
          (elem:any,indx:any)=>{
            if(elem.id==item.id){element=item}
          })})
    return checkBoxData.every(
      (element: any) => {
        return element.data.every((item: any) => item.checked) === true
      })
  }

  handleCheckBoxSelectFilter = (
    item: any, 
    index: number, 
    updatedSelectedItem: { [x: string]: boolean }, 
    checkBoxData: any
    ) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;
    } 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) { 
      delete updatedSelectedItem[item.id];
    }
    checkBoxData[index] = item;
    return checkBoxData.every((item: any) => item.checked);
  }

  handleCheckBoxSelect = (item: any, index: number) => {
    let isAllChecked = false
    const updatedSelectedItem = { ...this.state.selectedItems };
    const checkBoxData = [...this.state.responseData] as any
    item.checked = !item.checked;
    if (!this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false, isLoadMore: false,
    })
  };


  handleApiCall = (
    token: token, 
    endPoint: string, 
    method: string, 
    body?: object) => {
    
    const header = {
      "Content-Type": "application/json",
      token: token, 
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method);

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), 
      JSON.stringify(body));


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
  // Customizable Area End
}
