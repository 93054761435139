// Customizable Area Start
import React from 'react';
import { Box, Button, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { validationerror } from '../../cfcustomersalesreports3/src/assets';
import AddUnitOfMeasureController, {Props} from './AddUnitOfMeasureController.web';
export const configJSON = require("../../settings2/src/config");
export const configJSON1 = require("./config.js");


// Customizable Area End


export class AddUomCategories extends AddUnitOfMeasureController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }:any = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box className={classes.adduomMainLayout}>
                <Box className={classes.adduomsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.adduomcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.parentBox} style={{backgroundColor:"#ffffff"}}>
                    <Box className={classes.adduomBlock}>
                        <Grid container spacing={0} >
                        <Grid item sm={12} className='left-center-align'>
                            <Typography className={classes.adduomHeading}>{configJSON1.addUnitOfMeasureHeading}</Typography>
                        </Grid>
                        </Grid>
                    </Box>
                <Box style={{padding:"15px 0"}}>
                    <Grid container spacing={0} >
                        <Grid item sm={12}>
                        <Grid item sm={6}>
                        <Box sx={{padding:"0 15px"}}>
                            <Typography className={`${this.handleUomCategoryError(1)}`}>{configJSON1.unitOfMeasureCategory}<span className='required'>*</span></Typography>
                            <Box className='relative'>
                            <TextField data-test-id="uomCategoryInput" 
                            placeholder='Enter Unit of measure'
                            className={`form-input width-100 ${this.handleUomCategoryError(2)}`} 
                            value={this.state.uomCategory}
                            onChange={(e:any) => this.setState({...this.state,uomCategory:e.target.value})} 
                            name="category" type="text" />
                            <Typography className={`main-color bold-text ${classes.languageText}`}>{configJSON1.en}</Typography>
                            <Box className={`flex relative ${this.hideUomCategoryError()}`} >
                            <img className='error-text-img' src={validationerror} />
                            <Typography className='error-text'>{configJSON1.categoryMessage1}</Typography>
                            </Box>
                            </Box>
                        </Box>
                        </Grid>
                        <Grid item sm={6}>
                        </Grid>                        
                        </Grid>                        
                    </Grid>
                  
                    </Box>
                </Box>
                <Box className='end-center-align' style={{margin:"20px 0"}}>
                <Button className={classes.discardBtn} data-test-id="discard-uom-category" variant="outlined" onClick={this.discardUomCategory}>{configJSON.discard}</Button>
                <Button data-test-id="save-uom-category" variant="contained" onClick={this.addUomCategories} style={{backgroundColor:"#026494",color:"#ffffff"}}>{configJSON.save}</Button>
                </Box>
                </Box>
            </Box>
            </>
            // Customizable Area End
        )

    }
}

// Customizable Area Start
export const createuomwebStyle: any = {
    adduomMainLayout: {
        display: "flex",
    },
    adduomsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    adduomcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    adduomBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    parentBox: {
        border: "1px solid #e3e3e3",
        borderRadius:"5px"
    },
    adduomHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    pb10: {
        paddingBottom:"10px !important"
    },
    discardBtn:{
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    languageText:{
        position:"absolute",
        right:20,
        top:20
    },
    ratioStyle:{
    paddingRight: "30px",
    marginLeft: "-5px",
    },
    
    roundingStyle:{
    paddingRight: "30px",
    marginLeft: "-10px",
    }
    
}
export default withStyles(createuomwebStyle)(AddUomCategories)
  // Customizable Area End