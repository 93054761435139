// Customizable Area Start
import React from 'react';
import { Avatar, Box, Button, Checkbox, Chip, Grid, List, ListItem, ListItemIcon, ListItemText,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import CfcustomersaleskanbanviewController, { Props } from './CfcustomersaleskanbanviewController.web'
import '../assets/style.css'
import { configJSON } from './Cfcustomersalesreports3Controller';
import ClearIcon from "@material-ui/icons/Clear";
import { activity, add, downarrow, filter, groupby, invoice, meetings, sales, star, listBlack,whiteGridicon, whitefiltericon, whitegroupbyicon, dropdown, img, more, forwardArrowEnable } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';

export const Dropdown = () => {
    return <><img style={{ width: "100%" }} src={downarrow} /></>
}
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End



export default class Cfcustomersaleskanbanview extends CfcustomersaleskanbanviewController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showFav, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.gridMainLayout}>
                <Box className={classes.gridsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.gridcontent} >
                    {" "}
                    <AppHeader pageTitle="Customer" />
                    <Box className={classes.gridviewMain}>
                        <Box >
                            <Box className={classes.gridviewMainblock}>
                                <Grid container spacing={0}>
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.gridviewHeading}>{configJSON.mainHeading}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.gridviewSearchgrid}>
                                        <TextField
                                            className={classes.listviewSearchtextfield}
                                            id='search-input'
                                            variant="outlined"
                                            placeholder='Search here....'
                                            data-testid="handleSearchlist"
                                            InputProps={{
                                                startAdornment: (
                                                    <div className={classes.chipBox} >
                                                        {this.state.selectedProducts?.map((product) => (
                                                            <div key={product} >
                                                                <Chip avatar={<img className={classes.chipImg} src={whitefiltericon} />}
                                                                    label={product}
                                                                    deleteIcon={<ClearIcon className={classes.icon} />}
                                                                    className={classes.chipText}
                                                                    onDelete={() => { this.handleProductRemove(product) }} />
                                                            </div>
                                                        ))}
                                                        {this.state.groupbyProducts?.map((product) => (
                                                            <div key={product} >
                                                                <Chip avatar={<img className={classes.chipImg} src={whitegroupbyicon} />}
                                                                    label={product}
                                                                    deleteIcon={<ClearIcon className={classes.icon} />}
                                                                    className={classes.chipText}
                                                                    onDelete={() => { this.handlegroupbyProductRemove(product) }} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ),
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getSearchlistApi()}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.gridviewFilterbox}>
                                <Grid container spacing={0}  >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.gridviewFilterblock}>
                                            <Button className='filters-left' data-testid="filters" onClick={() => this.handleFilter()}>
                                                <img src={filter} className={classes.gridviewFiltertext} />{configJSON.Filters}
                                            </Button>
                                            {
                                                showFilter &&
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem button className={this.state.filterindiviualState ? classes.optionsTextactive : classes.optionsText} data-testid="individual" onClick={() => { this.handleProductSelect("individual", "yes") }}>
                                                            <ListItemText primary="Individual" />
                                                        </ListItem>
                                                        <ListItem button className={this.state.filtercompanyState ? classes.optionsTextactive : classes.optionsText} data-testid="companies" onClick={() => { this.handleProductSelect("companies", "yes") }}>
                                                            <ListItemText primary="Companies" />
                                                        </ListItem>
                                                        <ListItem button className={this.state.filterarchivedState?classes.optionsTextactive:classes.optionsText} data-testid="archived" onClick={() => { this.handleProductSelect("archived", "yes") }}>
                                                            <ListItemText primary="Archived" />
                                                        </ListItem>
                                                        <ListItem button className={classes.optionsText} data-testid="customerInvoices" >
                                                            <ListItemText primary="Customer Invoices" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.gridviewGroupbox}>
                                            <Button className='filters-left' data-testid="group" onClick={this.handleGroup}><img className={classes.gridviewGroupimg} src={groupby} />{configJSON.Groupby}</Button>
                                            {
                                                showGroup &&
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem button className={this.state.groupByState ? classes.optionsTextactive : classes.optionsText} data-testid="salesPerson" onClick={() => { this.handlegroupByProductSelect("saler_name", "yes") }}>
                                                            <ListItemText primary="Sales Person" />
                                                        </ListItem>
                                                        <ListItem button className={this.state.groupBycompanyState ? classes.optionsTextactive : classes.optionsText} data-testid="companyName" onClick={() => { this.handlegroupByProductSelect("company_name", "yes") }}>
                                                            <ListItemText primary="Company Name" />
                                                        </ListItem>
                                                        <ListItem button className={this.state.groupByactivestatusState ? classes.optionsTextactive : classes.optionsText} data-testid="activeStatus" onClick={() => { this.handlegroupByProductSelect("activated", "yes") }}>
                                                            <ListItemText primary="Active Status" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.gridviewFilterblock}>
                                            <Button className='filters-left' data-testid="favourites" onClick={this.handleFavorites}><img src={star} className={classes.gridviewFavoritesblock} />{configJSON.Favorites}</Button>
                                            {
                                                showFav &&
                                                <Box className="filterBox">
                                                    <List className={classes.gridviewSearchlist}>
                                                        <ListItem button data-testid="currentSearch" className='listItem' onClick={this.handleCurrentSearch}>
                                                            <ListItemText primary="Save Current Search" />
                                                            &gt;
                                                        </ListItem>
                                                        <ListItem button className='listItem' data-testid="importRecords">
                                                            <ListItemText primary="Import Records" />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary="Add to my Dashboard" data-testid="addDashboard" onClick={this.handleAddToDashboard} />
                                                            &gt;
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showCurrentSearch &&
                                                <Box className="filterBoxCurrentSearch">
                                                    <List className={classes.gridviewlist} >
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="List of Customers" />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridviewButton} >
                                                            <ListItemIcon className={classes.gridviewIcon} >
                                                                <Checkbox className={classes.gridviewCheckbox} defaultChecked />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Use by default" />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridViewlistItem}>
                                                            <ListItemIcon className={classes.gridviewIcon}>
                                                                <Checkbox className={classes.gridviewCheckbox} />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Share with all users" />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridviewButtonlist} >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showAddToDashboard &&
                                                <Box className="filterBoxAddToDashboard">
                                                    <List className={classes.gridviewlist}>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="List of Customers" />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridviewButtonlist}   >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.gridviewSetting}>
                                        <Button className='filters-left' data-testid="navigatetoListview" onClick={this.navigatetoListview} >< img src={listBlack} /></Button>
                                        <Button className='filters-left-active'><img className={classes.whiteGridicon} src={whiteGridicon} /></Button>
                                        <Button className='filters-right' data-testid="navigatetoCreateCustomer" onClick={this.navigatetoCreateCustomer}>{configJSON.Createcustomer}<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={classes.gridviewbox} >
                                    <Grid container spacing={3} >
                                        {this.state.listviewState &&
                                            <>
                                                {
                                                    this.state.showCustomerdata?.map((value: {
                                                        attributes: {
                                                            job_position: string; first_name: string, company_name: string, city: string, email: string, country: string
                                                        }
                                                    }) => {
                                                        return <>
                                                            <Grid item lg={4} className={classes.gridViewBoxstyle} >
                                                                <Box className={classes.gridviewFilterbox}  >
                                                                    <Grid container spacing={0} className={classes.gridviewbox}  >

                                                                        <Grid item lg={4} className={classes.gridviewlistimg} >
                                                                            <Avatar className={classes.gridAvatar} alt="img" variant="square" />
                                                                        </Grid>
                                                                        <Grid item lg={8} className={classes.gridviewlistimg}  >
                                                                            <img src={star} className={classes.gridListimg} />
                                                                            <Typography className={classes.gridListtext} >{value.attributes.first_name}</Typography>
                                                                            <Typography className={classes.gridListsubtext} >{value.attributes.company_name}</Typography>
                                                                            <Typography className={classes.gridListsubtext} >{value.attributes.job_position}</Typography>
                                                                            <Typography className={classes.gridListinputtext} >{value.attributes.country}</Typography>
                                                                            <Typography className={classes.gridListinputtext} >{value.attributes.email}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <ul className='grid-item-ul'>
                                                                        <li>{configJSON.services}</li>
                                                                        <li>{configJSON.appliances}</li>
                                                                    </ul>
                                                                </Box>
                                                                <Box className={classes.gridviewMainblock} >
                                                                    <Grid container className={classes.gridviewContainer} >
                                                                        <Grid item lg={3}  >
                                                                            <Box className={classes.gridViewtext} >
                                                                                <img src={sales} /> <Typography className={classes.gridviewSalestext} >{configJSON.pageFour}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={3}  >
                                                                            <Box className={classes.gridViewtext} >
                                                                                <img src={invoice} /> <Typography className={classes.gridviewSalestext} >{configJSON.pageFour}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={3}  >
                                                                            <Box className={classes.gridViewtext} >
                                                                                <img src={activity} /> <Typography className={classes.gridviewSalestext} >{configJSON.pageFour}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={3}  >
                                                                            <Box className='center-align' >
                                                                                <img src={meetings} /> <Typography className={classes.gridviewSalestext} >{configJSON.pageFour}</Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                    })
                                                }
                                            </>}
                                    </Grid>
                                    {
                                        this.state.groupByState &&
                                        <>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.gridviewTablecellGroup}><Checkbox
                                                                checked={this.state.selectAll}
                                                                data-testid="gridviewSelectall"
                                                                onClick={() => { this.toggleSelectAll() }} className={classes.listviewCheck} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.phone}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.email}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.sales}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.city}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.country}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}> {configJSON.company}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography className={classes.gridviewTableheadingGroup}><img data-testid="listviewColumn" onClick={this.handleColumn} className={classes.gridviewColumn} src={more} /></Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {this.state.showCustomerdata?.map((data,index:any) => {
                                                        return (
                                                            <>
                                                                <TableBody>
                                                                    <TableRow className={classes.gridviewaccordion} data-testid="accordionDetailskanban" onClick={()=>{this.handletoggle(index)}}>
                                                                        <TableCell>
                                                                            {this.state.activeIndex === index? <img className={classes.gridviewaccordionimg} src={dropdown} alt="dropdown" /> : <img className={classes.gridviewaccordionImg} src={forwardArrowEnable} alt="dropdown" />}
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                            <Typography >{data.key}</Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                        <TableCell className={classes.gridviewTablecellGroup}>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {this.state.activeIndex === index ? <>
                                                                        {data?.customers?.data?.map((value: {
                                                                            attributes: any; id: React.Key | undefined; first_name: string; phone_number: string; email: string; city: string; country: string; company_name: string;
                                                                        }) => {
                                                                            return (
                                                                                <TableRow key={value.id}>
                                                                                    <TableCell className={classes.gridviewTablecellGroup}><Checkbox className={classes.gridviewCheck}
                                                                                    />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}> {value.attributes.first_name}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}> {value.attributes.phone_number}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}>{value.attributes.email}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Box className="left-center-align">
                                                                                            <img src={img} height="30" width="30" className={classes.gridviewTableavatar} />
                                                                                            {value.attributes.account_sale_purchases?.saler_name}
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}> {value.attributes.city}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}>{value.attributes.country}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}> {value.attributes.company_name}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography className={classes.gridviewTabletextGroup}> </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </>
                                                                        : ""}
                                                                </TableBody>
                                                            </>
                                                        )
                                                    })}
                                                </Table>
                                            </TableContainer>
                                        </>
                                    }
                                </Box>
                                <Grid className={classes.gridviewPaginationcontainer}>
                                    <Box>
                                    <Button  data-testid="loadMore" className={classes.gridviewLoadmoreButton} onClick={this.handleLoadMore}>{configJSON.loadMore}</Button>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box >
            </Box >
            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const gridViewWebstyle: any = (theme: any) => ({

    gridMainLayout: {
        display: "flex",
    },
    gridsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    gridcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },
    gridviewaccordionDetailsbox: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center"
    },
    gridviewaccordionDetails: {
        display: "block",
    },
    accordiontext: {
        fontSize: "16px",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    activeAccordiontext: {
        fontSize: "16px",
        fontFamily: "Inter",
        fontStretch: "normal",
    },
    listaccordiontext: {
        fontSize: "16px",
        fontFamily: "Inter",
        fontStretch: "normal",
    },

    gridviewMainblock: {
        padding: "15px",
        border: "1px solid #e3e3e3"
    },
    gridviewMain: {
        backgroundColor: "white",
        height:"100%"
    },
    gridviewHeading: {
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    gridAvatar: {
        height: "100px",
        width: "100px",
        borderRadius:"3px"
    },
    gridListtext: {
        fontSize: "20px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    gridListinputtext: {
        fontSize: "14px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
    },
    gridListsubtext: {
        fontSize: "16px",
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    gridviewImg: {
        position: "absolute",
        left: "15px",
        top: "20px",
        height: "20px"
    },
    gridviewFilterbox: {
        border: "1px solid #e3e3e3",
        height: "85%"
    },
    gridviewFilterblock: {
        position: "relative"
    },
    gridviewFiltertext: {
        paddingRight: "8px"
    },
    gridviewGroupbox: {
        position: "relative"
    },
    gridviewGroupimg: {
        paddingRight: "8px"
    },
    gridviewFavoritesblock: {
        paddingRight: "8px"
    },
    gridviewSearchlist: {
        width: "250px"
    },
    gridviewlist: {
        width: "250px"
    },
    gridviewButton: {
        paddingBottom: 0
    },
    gridviewIcon: {
        minWidth: "35px"
    },
    gridviewCheckbox: {
        paddingLeft: 0
    },
    gridviewButtonlist: {
        justifyContent: "end"
    },
    gridviewbox: {
        padding: "15px"
    },
    gridviewlistimg: {
        padding: "10px"
    },
    gridviewlisttext: {
        padding: "10px",
        fontSize: "20px"
    },
    gridviewlistgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    gridListimg: {
        margin: "-5px 5px 0 0",
        float: "right"
    },
    gridviewContainer: {
        padding: "5px 0"
    },
    gridviewSalestext: {
        paddingLeft: "5px"
    },
    gridviewFooter: {
        marginRight: "50px"
    },
    gridviewSetting: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px",
    },
    gridviewSearchgrid: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px",
        position: "relative",
        width: "100%"
    },
    listviewSearchtextfield: {
        width: "100%"
    },
    chipBox: {
        display: "flex",

    },
    chipText: {
        color: "white",
        backgroundColor: "#006395",
        fontSize: "18px",
        fontFamily: "Inter",
        fontStretch: "normal",
    },
    icon: {
        color: "#fff",
        width: "22px",
        "& :hover": {
            color: "#fff",
        },
    },
    chipImg: {
        width: "20px !important"
    },
    gridViewtext: {
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid #e3e3e3"
    },
    gridViewlistItem: {
        borderBottom: "1px solid #e3e3e3 !important",
        paddingTop: 0
    },
    optionsText: {
        borderBottom: "1px solid #e3e3e3 !important",
        color: "black",
        fontSize: "30px",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    whiteGridicon: {
        height: "20px"
    },
    gridviewPaginationcontainer: {
        marginTop: "2%",
        marginBottom: "2%",
        display:"flex",
        justifyContent:"center"
    },
    optionsTextactive: {
        borderBottom: "1px solid #e3e3e3 !important",
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    gridviewaccordion: {
        marginBottom: "1%",
        backgroundColor: "lightgrey"

    },
    gridviewaccordionImg: {
        width: "8px",
        marginRight: "3%"
    },
    gridviewaccordionimg: {
        width: "30px",
        marginRight: "2%"
    },
    gridviewTablecellGroup: {
        display: "flex",
        alignItems: "center"
    },
    gridviewTableheadingGroup: {
        padding: "0% 4%",
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Inter",
        textTransform: "uppercase"
    },
    gridviewTabletextGroup: {
        padding: "0% 4%",
        fontSize: "14px",
        fontFamily: "Inter",
    },
    gridviewTableavatar: {
        borderRadius: "50%",
        paddingRight: "10px"
    },
    gridViewBoxstyle: {
        padding: "2% !important"
    },
    gridviewLoadmoreButton:{
        backgroundColor:"#f3f3f3",
        color:"#3d3d3d",
        margin: "15px 10px",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: "500"  
        },
    listviewCheck: {
        color: "#185d85 !important"
    },
  
})
export const CfcustomersalesGridviewStyles = withStyles(gridViewWebstyle)(Cfcustomersaleskanbanview)
// Customizable Area End
